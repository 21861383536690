import { FC } from 'react'
import { View } from 'react-native'
import Card from './Card'
import { IPlanCard, TPlans } from '../../../settings.interface'

interface IProps {
	currentPlan: TPlans
	setCurrentPlan: (plan: TPlans) => void
}

const Cards: FC<IProps> = ({ currentPlan, setCurrentPlan }) => {
	const planCards: IPlanCard[] = [
		{
			type: 'Standart',
			price: 'Free',
			points: [
				'For people who rarely use emails',
				'2 Executive Summaries/day',
				'3 Detailed Summaries/day',
				'2 Reply Generations/day'
			]
		},
		{
			type: 'Plus',
			tooltip: 'Popular',
			price: '$5/mo',
			points: [
				'For average users',
				'5 Executive Summaries/day',
				'10 Detailed Summaries/day',
				'10 Reply Generations/day'
			]
		},
		{
			type: 'Pro',
			price: '$20/mo',
			points: ['For ultimate users', 'Full unlimited']
		}
	]

	return (
		<View className='flex-row justify-center' style={{ gap: 28 }}>
			{planCards.map((card: IPlanCard, index) => (
				<Card
					key={card.type}
					{...card}
					disabled={
						(currentPlan !== 'Standart' && card.type === 'Standart') ||
						(currentPlan === 'Pro' && card.type !== 'Pro')
					}
					currentPlan={currentPlan}
					setCurrentPlan={setCurrentPlan}
				/>
			))}
		</View>
	)
}

export default Cards
