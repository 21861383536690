import { FC, useEffect, useState } from 'react'
import { Dimensions, View } from 'react-native'
import { Button} from '@/components/ui'
import cn from 'clsx'
import { useSizeSelector } from '@/context/SizeSelectorContext'
import { useColors } from '@/hooks/useColors'
import TooltipEl from './tooltip/Tooltip'


const SizeSelector: FC = () => {
	const { size, setSize } = useSizeSelector()
	const { bg, text } = useColors()
	const [deviceWidth, setDeviceWidth] = useState<number>(
		Dimensions.get('window').width
	)

	useEffect(() => {
		// @ts-ignore
		const handleDimensionsChange = ({ window }) => {
			setDeviceWidth(window.width)
		}

		Dimensions.addEventListener('change', handleDimensionsChange)
	}, [])
	return (
        <>
            {deviceWidth >= 1440 && (
                <View className='flex-row '>
                    <TooltipEl placement='top' title='Small'>
                        <View>
                            <Button
                                variant='body'
                                onPress={() => setSize('small')}
                                className={cn(
                                    'w-[36px] h-[36px] items-center flex justify-center rounded-base-x2',
                                    size === 'small' && bg.hoverSurface
                                )}
                                classNameText={cn('text-[13px]', text.black)}>
                                Aa
                            </Button>
                        </View>
                    </TooltipEl>
                    <TooltipEl placement='top' title='Large'>
                        <View>
                            <Button
                                variant='h4'
                                onPress={() => setSize('large')}
                                classNameText={text.black}
                                className={cn(
                                    'w-[36px] h-[36px] items-center flex justify-center rounded-base-x2',
                                    size === 'large' && bg.hoverSurface
                                )}>
                                Aa
                            </Button>
                        </View>
                    </TooltipEl>
                </View>
            )}
        </>
    )
}

export default SizeSelector
