import { Dashboard, Inbox, Settings, ToDo } from '@/components/screens'

import { IRoute } from './navigation.types'

export const routes: IRoute[] = [
	{
		name: 'Dashboard',
		component: Dashboard
	},
	{
		name: 'Inbox',
		component: Inbox
	},
	{
		name: 'Todo',
		component: ToDo
	},
	{
		name: 'Settings',
		component: Settings
	}
]
