import { useQuery } from '@tanstack/react-query'
import { ThreadsService } from '@/services/inbox/threads.api'
import { useStore } from '@/shared/store'
import { useEffect, useState } from 'react'
import { IThread } from '@/shared/types/threads.interface'

export const useApiGetThread = () => {
    const [enabled, setEnabled] = useState<boolean>(false)
    const {
        digests,
        thread,
        threads,
        setThread,
        selectedThreadId,
        setSelectedThreadId,
        setLoadingChangeThread,
        setDigestThread,
    } = useStore()

    const [threadId, setThreadId] = useState<string | null>(null)

    const queryResult = useQuery(
        ['get_thread'],
        () => ThreadsService.getThread(threadId ? threadId : (selectedThreadId as string | number)),
        {
            enabled,
            onSuccess(res) {
                // console.log(res.data)
                setDigestThread(res.data as IThread)
                // setThread((res.data as IThread) || {})
            }
        }
    )

    // useEffect(() => {
    //     if (selectedThreadId === thread?.id) {
    //         const foundThread = threads.filter((item) => item.id === selectedThreadId)[0]
    //         thread.messages.length !== foundThread.messages.length &&
    //             setInterval(() => {
    //                 setEnabled(true)
    //             }, 20000)
    //     }
    // }, [thread, selectedThreadId, threads])

    useEffect(() => {
        enabled && setEnabled(false)
    }, [enabled])

    const fetchThread = (id: string | number) => {
        if (id) {
            setLoadingChangeThread(true)
            setSelectedThreadId(id)
            setThread(id as string)
        }
        // if (id) {
        //     setEnabled(true)
        // }
    }

    const fetchFirstDigestThread = (digestItemId: number) => {
        if (digestItemId) {
            const currentDigest = digests.find((digest) => digest.id === digestItemId)
            const threadsIds = [
                ...new Set(currentDigest?.messages.flatMap((message) => message.thread_id))
            ]
            threadsIds.length && setThreadId(threadsIds[0])

            setEnabled(true)
        }
    }

    return {
        thread,
        selectedThreadId,
        fetchThread,
        fetchFirstDigestThread,
        ...queryResult
    }
}
