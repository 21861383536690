import { FC, PropsWithChildren } from 'react'
import { Text } from 'react-native'
import { ITypography } from './typography.interface'
import cn from 'clsx'
import { useSizeSelector } from '@/context/SizeSelectorContext'

const Typography: FC<PropsWithChildren<ITypography>> = ({
    variant,
    className,
    nowrap,
    children,
    style,
    numberOfLines,
    ellipsizeMode,
    type,
    ...props
}) => {
    const { size } = useSizeSelector()

    const textStyle = (() => {
        switch (variant) {
            case 'h1':
                return 'text-base-h1 font-medium tracking-[-0.32px]'
            case 'h2':
                return 'text-base-h2 font-medium'
            case 'h3':
                return 'text-base-h3 font-medium'
            case 'h4':
                return 'text-base-h4 font-medium tracking-[-0.32px]'
            case 'h4-bold':
                return 'text-base-h4 font-bold tracking-[-0.32px]'
            case 'body':
                return `${
                    size === 'large' ? 'text-base-body' : 'text-base-body2'
                } tracking-[-0.32px] leading-[24px]`

            case 'body-bold':
                return `${
                    size === 'large' ? 'text-base-body' : 'text-base-body2'
                } font-bold tracking-[-0.32px] leading-[24px]`
            case 'body-semibold':
                return `${
                    size === 'large' ? 'text-base-body' : 'text-base-body2'
                } font-semibold tracking-[-0.32px] leading-[24px]`
            case 'body-link':
                return `${
                    size === 'large' ? 'text-base-body' : 'text-base-body2'
                } underline tracking-[-0.32px] leading-[24px]`
            case 'body-list':
                return `${size === 'large' ? 'text-base-body' : 'text-base-body2'} tracking-[-0.32px]`
            case 'body-strike':
                return `${
                    size === 'large' ? 'text-base-body' : 'text-base-body2'
                } line-through leading-[24px] tracking-[-0.32px]`
            case 'calout':
                return 'text-base-body1'
            case 'body-small':
                return 'text-base-body3 leading-[24px]'
            case 'subheadline':
                return 'text-base-body2'
            case 'caption':
                return `${size === 'large' ? 'text-base-body2' : 'text-base-body4'}`
            case 'button-big':
                return 'text-base-h2 font-semibold tracking-[-0.32px]'
            case 'button-default':
                return 'text-base-h4 tracking-[-0.32px]'
            case 'button-plain':
                return `${size === 'large' ? 'text-base-body' : 'text-base-body2'} font-semibold`
            case 'menu-active':
                return 'text-base-body2 font-semibold'
            case 'label-date':
                return `${size === 'large' ? 'text-base-body4' : 'text-base-body5'} tracking-[-0.32px]`
            case 'label-date-bold':
                return 'text-base-body5 font-bold'
        }
    })()

    return (
        <Text
            className={cn(textStyle, className)}
            style={{
                //@ts-ignore
                whiteSpace: nowrap ? 'nowrap' : 'normal',
                transition: 'all 0.3s ease-out',
                style
            }}
            numberOfLines={numberOfLines}
            ellipsizeMode={ellipsizeMode}
            {...props}>
            {children}
        </Text>
    )
}

export default Typography
