import { FC, useEffect } from 'react'
import { Image, Modal, StyleProp, View, ViewStyle } from 'react-native'
import { useModal } from '@/context/ModalContext'
import { Typography } from '@/components/ui'
import { StyledProps } from 'nativewind'
import cn from 'clsx'

interface TProps {
	text: string
	step: string
	visible: boolean
	directionArrow: 'left' | 'right'
	style?: StyledProps<string>
	styleModal?: StyleProp<ViewStyle>
}

const ModalStep: FC<TProps> = ({
	text,
	step,
	style,
	directionArrow,
	styleModal,
	visible
}) => {
	const { setVisible } = useModal()

	useEffect(() => {
		setVisible(visible)
	}, [visible])

	return (
		<Modal animationType='fade' transparent={true} visible={visible}>
			<View
				className={cn(
					'w-[322px] absolute z-[1000] flex-col items-center gap-y-base-x2',
					style
				)}
				style={styleModal}
			>
				{directionArrow === 'left' && (
					<Image
						source={require('@/assets/arrow-left.svg')}
						className='w-[180px] h-[14px] absolute top-[50%] left-[-105px]'
					/>
				)}
				{directionArrow === 'right' && (
					<Image
						source={require('@/assets/arrow-right.svg')}
						className='w-[180px] h-[14px] absolute top-[50%] right-[-70px]'
					/>
				)}
				<Typography
					variant='h4-bold'
					className='text-white w-base-x8 h-[28px] flex items-center justify-center bg-gray-500 rounded-base-x16'
				>
					{step}
				</Typography>
				<View className='flex-1 pt-base-x4 pb-base-x4 pr-base-x6 pl-base-x6 justify-center items-center bg-white rounded-base-x4'>
					<Typography variant='body'>{text}</Typography>
				</View>
			</View>
		</Modal>
	)
}

export default ModalStep
