import { useQuery } from "@tanstack/react-query"
import { TodosService } from "@/services/togos";
import { useStore } from "@/shared/store/store";
import { useEffect, useState } from "react";
import { ITodo } from "@/shared/types/todo.interface";

export const useApiGetTodos = () => {
    const [enabled, setEnabled] = useState<boolean>(false)
    const { setTodos, todos, todosTab, refetchTodos, setRefetchTodos } = useStore((state) => state)

    // !OLD
    const queryResult = useQuery(
        ['get_todos'],
        () => TodosService.getTodos(),
        {
            enabled,
            onSuccess(res) {
                setRefetchTodos(false)
                setTodos(res.data as ITodo[])
            }
        }
    )

    useEffect(() => {
        refetchTodos && fetchTodos()
    }, [todos])

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])

    const fetchTodos = () => {
        setEnabled(true)
    }

    return {
        todos,
        todosTab,
        fetchTodos,
        ...queryResult
    }
}