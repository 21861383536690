import { FC, useState } from 'react'
import { Image, Text, View } from 'react-native'
import { Button, Dropdown, SizeSelector, Typography } from '@/components/ui'
import { useColorScheme } from 'nativewind'

const Selectors: FC = () => {
	const [dropdownVisible, setDropdownVisible] = useState(false)
	const { colorScheme, setColorScheme } = useColorScheme()

	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible)
	}
	const closeDropdown = () => {
		setDropdownVisible(false)
	}
	return (
		<View className='flex-col w-[420px]' style={{ gap: 32 }}>
			<View className='flex-row items-center justify-between w-full'>
				<View>
					<Typography variant='body'>Font size</Typography>
					<Typography variant='caption' className='text-gray-400'>
						Available only for screens with a width of 1440 and more
					</Typography>
				</View>
				<SizeSelector />
			</View>
			<View className='flex-row items-center justify-between w-full'>
				<Typography variant='body'>
					Theme: <Text className='font-bold'>{colorScheme}</Text>
				</Typography>
				<Dropdown
					isVisible={dropdownVisible}
					setValue={setColorScheme}
					onClose={closeDropdown}
					invertOptions
					variant='body'
					classNameText='text-black'
					options={[
						{
							icon: (
								<Image
									source={require('@/assets/icons/theme-light.svg')}
									className='w-base-x6 h-base-x6'
								/>
							),
							text: 'light'
						},
						{
							icon: (
								<Image
									source={require('@/assets/icons/theme-dark.svg')}
									className='w-base-x6 h-base-x6'
								/>
							),
							text: 'dark'
						}
					]}
					button={
						<View className='flex-row items-center' style={{ gap: 8 }}>
							<Image
								source={require(`@/assets/icons/theme-${colorScheme}.svg`)}
								className='w-base-x6 h-base-x6'
							/>
							<Button
								onPress={toggleDropdown}
								icon='ChevronDown'
								className='pt-base-x2 pb-base-x2'
							/>
						</View>
					}
				/>
			</View>
		</View>
	)
}

export default Selectors
