import * as SplashScreen from 'expo-splash-screen'
import {
	createContext,
	FC,
	PropsWithChildren,
	useEffect,
	useState
} from 'react'

import { IContext, TypeUserState } from './auth-provider.interface'
import { getToken, getUserFromStorage } from '@/services/auth/auth.helper'


export const AuthContext = createContext({} as IContext)

let ignore = SplashScreen.preventAutoHideAsync()

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const [user, setUser] = useState<TypeUserState>(null)
	
	useEffect(() => {
		let isMounted = true

		const checkToken = async () => {
			try {
				const token = await getToken()
				if (token && typeof token === 'string') {
					const user = await getUserFromStorage()
					if (isMounted) setUser(user)
				}
			} finally {
				await SplashScreen.hideAsync()
			}
		}

		let ignore = checkToken()

		return () => {
			isMounted = false
		}
	}, [])

	return (
		<AuthContext.Provider value={{ user, setUser }}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider
