import { FC } from 'react'
import { View } from 'react-native'
import { Button} from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import TooltipEl from '../../tooltip/Tooltip'

interface TProps {
    onSubmit: () => void
}

const ButtonDouble: FC<TProps> = ({ onSubmit }) => {
    const { text, color } = useColors()
    return (
        <View className='flex-row items-center bg-sky rounded-base-x3'>
            <TooltipEl placement='top' title='Send'>
                <View>
                    <Button
                        variant='body'
                        classNameText={text.white}
                        styleOnHover='bg-sky-500 rounded-lg'
                        onPress={onSubmit}
                        className='pl-base-x8 pr-base-x12 pt-base-x2 pb-base-x2'>
                        Send
                    </Button>
                </View>
            </TooltipEl>
            <TooltipEl placement='top' title='Schedule send'>
                <View>
                    <Button
                        className='pl-base-x2 pt-base-x2 pb-base-x2 pr-base-x4 border-l border-[#0284C7]'
                        icon='Clock4'
                        styleOnHover='bg-sky-500 rounded-lg border-l'
                        sizeIcon={24}
                        colorIcon={color.white}
                    />
                </View>
            </TooltipEl>
        </View>
    )
}

export default ButtonDouble
