import { FC, useState } from 'react'
import { Text, View } from 'react-native'
import { Header } from '../index'
import { Typography } from '@/components/ui'
import Cards from './components/Cards'
import { TPlans } from '../../settings.interface'
import { useColorScheme } from 'nativewind'
import cn from 'clsx'

const Plan: FC = () => {
	const [currentPlan, setCurrentPlan] = useState<TPlans>('Standart')
	const { colorScheme } = useColorScheme()
	const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

	return (
		<View
			className={cn(
				'flex-1 p-base-x2 flex-col border-l-[1px]',
				deviderColor
			)}
			style={{ gap: 8 }}
		>
			<Header title='Plan' />
			<View className='pt-base-x5 flex-col' style={{ gap: 52 }}>
				<Typography variant='body' className='pl-base-x9'>
					Current plan: <Text className='font-bold'>{currentPlan}</Text>, active
					till <Text className='text-sky font-bold'>09.27.2023</Text>
				</Typography>
				<Cards currentPlan={currentPlan} setCurrentPlan={setCurrentPlan} />
			</View>
		</View>
	)
}

export default Plan
