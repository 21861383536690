import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { Dimensions } from 'react-native'

type TSize = 'large' | 'small'
interface SizeSelectorContextProps {
	size: TSize
	setSize: (theme: TSize) => void
}

const SizeSelectorContext = createContext<SizeSelectorContextProps | undefined>(
	undefined
)

const SizeSelectorProvider: FC<{ children: ReactNode }> = ({
	children
}) => {
	const [size, setSize] = useState<TSize>('large')

	useEffect(() => {
		// @ts-ignore
		const handleDimensionsChange = ({ window }) => {
			const deviceWidth = window.width
			if (deviceWidth >= 1440 && size !== 'small') {
				setSize('large')
			}
			if (deviceWidth < 1440) {
				setSize('small')
			}
		}

		Dimensions.addEventListener('change', handleDimensionsChange)
	}, [])

	return (
		<SizeSelectorContext.Provider value={{ size, setSize }}>
			{children}
		</SizeSelectorContext.Provider>
	)
}

const useSizeSelector = () => {
	const context = useContext(SizeSelectorContext)
	if (!context) {
		throw new Error(
			'useSizeSelector must be used within a SizeSelectorProvider'
		)
	}
	return context
}

export { SizeSelectorProvider, useSizeSelector }
