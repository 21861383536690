import { StatusBar } from 'expo-status-bar'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import Navigation from '@/navigation/Navigation'
import { NativeWindStyleSheet } from 'nativewind'
import AuthProvider from '@/providers/auth/AuthProvider'
import { Toast } from '@/components/ui'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SizeSelectorProvider } from '@/context/SizeSelectorContext'
import { MailPositionProvider } from '@/context/MailPositionContext'
import { ModalProvider } from '@/context/ModalContext'
import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { useEffect } from 'react'
import {
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black
} from '@expo-google-fonts/inter'

import './globals.css'

NativeWindStyleSheet.setOutput({
    default: 'native'
})

SplashScreen.preventAutoHideAsync()

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
})

export default function App() {
    const [fontsLoaded, error] = useFonts({
        'Inter-Black': Inter_900Black,
        'Inter-Bold': Inter_700Bold,
        'Inter-ExtraBold': Inter_800ExtraBold,
        'Inter-ExtraLight': Inter_200ExtraLight,
        'Inter-Light': Inter_300Light,
        'Inter-Medium': Inter_500Medium,
        'Inter-Regular': Inter_400Regular,
        'Inter-SemiBold': Inter_600SemiBold,
        'Inter-Thin': Inter_100Thin
    })

    useEffect(() => {
        if (fontsLoaded || error) {
            SplashScreen.hideAsync()
        }
    }, [fontsLoaded, error])

    if (!fontsLoaded) {
        return null
    }

    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <ModalProvider>
                    <MailPositionProvider>
                        <SizeSelectorProvider>
                            <SafeAreaProvider>
                                <Navigation />
                            </SafeAreaProvider>
                        </SizeSelectorProvider>
                    </MailPositionProvider>
                </ModalProvider>
            </AuthProvider>
            <Toast />
            <StatusBar style='light' />
        </QueryClientProvider>
    )
}
