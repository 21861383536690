import { FC, useState } from 'react'
import { Image, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import MenuItem from './MenuItem'
import { TypeNavigate } from './menu.interface'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'
import { useStore } from '@/shared/store'
import TooltipEl from '../../tooltip/Tooltip'

interface ISidebar {
    nav: TypeNavigate
    currentRoute?: string
}

const Sidebar: FC<ISidebar> = (props) => {
    const { bottom } = useSafeAreaInsets()
    const { colorScheme } = useColorScheme()
    const { bg } = useColors()
    const [isHover, setIsHover] = useState(false)
    const [isInActive, setIsAnactive] = useState(true)

    const { setRefetchThreads, digestThreads, setChatType, setCurrentPath, setThreadsTabCategory } =
        useStore()

    return (
        <View
            className={cn(
                'pt-base-x8 z-50 mb-base-x8 pr-base-x2 pl-base-x2 justify-between items-center w-[56px]',
                bg.dashboardSurface
            )}
            style={{
                paddingBottom: bottom + 5,
                gap: 8
            }}>
            <View className='flex-col' style={{ gap: 8 }}>
                <MenuItem
                    onHoverIn={() => setIsHover(true)}
                    onHoverOut={() => setIsHover(false)}
                    path='Dashboard'
                    onClick={() => (
                        !!digestThreads.length && setChatType('chat_dashboard'), setCurrentPath('Dashboard')
                    )}
                    {...props}>
                    <TooltipEl placement='right' title='Dashboard'>
                        <View>
                            <Image
                                source={require(
                                    `@/assets/icons/logo-sidebar${
                                        props.currentRoute !== 'Dashboard'
                                            ? colorScheme === 'dark'
                                                ? '-dark'
                                                : isHover
                                                ? '-hover'
                                                : isInActive
                                                ? '-inactive'
                                                : ''
                                            : ''
                                    }.svg`
                                )}
                                className='w-base-x6 h-base-x6'
                            />
                        </View>
                    </TooltipEl>
                </MenuItem>
                <TooltipEl placement='right' title='Mailbox'>
                    <View>
                        <MenuItem
                            iconName='Mails'
                            path='Inbox'
                            onClick={() => (setRefetchThreads(true), setCurrentPath('Inbox'), setThreadsTabCategory('new'))}
                            {...props}
                        />
                    </View>
                </TooltipEl>
                <TooltipEl placement='right' title='To-Do list'>
                    <View>
                        <MenuItem
                            iconName='ListTodo'
                            path='Todo'
                            onClick={() => setCurrentPath(null)}
                            {...props}
                        />
                    </View>
                </TooltipEl>
            </View>

            <MenuItem path='Settings' noHover onClick={() => setCurrentPath(null)} {...props}>
                <TooltipEl placement='right' title='User settings'>
                    <Image source={require('@/assets/avatar.png')} className='w-base-x10 h-base-x10' />
                </TooltipEl>
            </MenuItem>
        </View>
    )
}

export default Sidebar
