import { View } from 'react-native'
import { FC } from 'react'
import { Button } from '..'
import { useTypedRoute } from '@/hooks/useTypedRoute'
import { useTypedNavigation } from '@/hooks/useTypedNavigation'
import { TPath } from '@/components/screens/inbox/inbox.interface'
import { useStore } from '@/shared/store'

interface IProps {}

const Footer: FC<IProps> = ({}) => {
    const { selectedThreadId } = useStore()
    const { params } = useTypedRoute<'Inbox'>()
    const { navigate } = useTypedNavigation()

    return (
        <View className='flex flex-row justify-end px-base-x4'>
            <Button
                className={'text-primary p-base-x2 text-base-body font-semibold'}
                onPress={() => {
                    if (selectedThreadId === null) return null
                    return navigate('Inbox', {
                        path: params?.path as TPath,
                        id: selectedThreadId as string
                    })
                }}
            >Read Source</Button>
        </View>
    )
}

export default Footer