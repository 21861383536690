import { FC } from 'react'
import { View } from 'react-native'
import { Loader, Typography } from '@/components/ui'
import Header from './components/Header'
import Content from './components/Content'
import { TPath } from '../../inbox.interface'
import cn from 'clsx'
import { useStore } from '@/shared/store'
import { IThread } from '@/shared/types/threads.interface'
import { useApiGetThread } from '../../api'
import { useColors } from '@/hooks/useColors'

interface IProps {
    path: TPath
}

const MailChat: FC<IProps> = ({ path }) => {
    const { text } = useColors()
    const { thread, loadingChangeThread, selectedThreadId } = useStore()
    return (
        <View className={cn('flex-1')}>
            {!loadingChangeThread ? (
                !!thread && selectedThreadId ? (
                    <View className={cn('pb-base-x2 relative rounded-base-x4 flex-1 justify-between')}>
                        <Header thread={thread as IThread} />
                        <Content path={path} />
                    </View>
                ) : (
                    <Typography variant='body' className='text-center text-gray-400 pt-base-x9'>
                        No conversations selected
                    </Typography>
                )
            ) : (
                <View className={cn('pt-base-x9 pl-base-x9', text.boldText)}>
                    <Loader text='Loading selected thread' />
                </View>
            )}
        </View>
    )
}

export default MailChat
