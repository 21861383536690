import { useMutation } from "@tanstack/react-query"
import { TodosService } from "@/services/togos";
import { useState } from "react";
import { ITodo, IUpdateTodo } from "@/shared/types/todo.interface";
import { useStore } from "@/shared/store";



export const useUpdateTodo = () => {
	const [todo, setTodo] = useState<IUpdateTodo | null>(null);
	const [todoId, setTodoId] = useState<number | null>(null);

	const { updateTodo: updateTodoStore } = useStore(state => state)

    const mutationResult = useMutation(
		['update_todos'],
		() => TodosService.updateTodo(todoId, todo),
		{
			onSuccess(res) {
				console.log('UPDATE TODO:', res)
				updateTodoStore(todoId as number, res?.data)
			}
		}
	);

	const updateTodo = async (id: number | null, todoData: IUpdateTodo) => {
        await setTodo(todoData)
        await setTodoId(id)
        await mutationResult.mutate()
    }


    return {
		updateTodo,
		todoId,
		setTodoId,
        ...mutationResult,
    }
}