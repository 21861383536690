import { useMutation } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { AuthService } from '@/services/auth/auth.service'
import { useStore } from '@/shared/store'

export const useAuthMutations = () => {
	const { setRefreshCount } = useStore((state) => state)

	const { mutate: loginSync, isLoading } = useMutation(
		['login'],
		() => AuthService.login(),
		{
			onSuccess(data) {
				window.open(data, '_self')
			}
		}
	)

	useEffect(() => {
		const queryParameters = new URLSearchParams(window.location.search)
		const token = queryParameters.get('access_token')

		if (token) {
			let ignored = AuthService.getUser(token)
		}
	}, [])

	return useMemo(
		() => ({
			loginSync
		}),
		[isLoading]
	)
}
