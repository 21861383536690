import { FC } from 'react'
import { View } from 'react-native'
import { Body, Header, Loader, Typography } from '@/components/ui'
import { StyledProps } from 'nativewind'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'
import { TCurrentPath } from '@/shared/store/types'

interface IProps {
    className?: StyledProps<string>
    currentPath: TCurrentPath | null
}

const Highlights: FC<IProps> = ({ className, currentPath }) => {
    const { highlights, currentThreadId, loadingAsyncTask, threadHighlights } = useStore()

    const { text } = useColors()
    
    const existHighlights =
        currentPath === 'Dashboard' ? highlights : currentPath === 'Inbox' ? threadHighlights : null

        const highlightsList =
        currentPath === 'Dashboard'
            // @ts-ignore
            ? existHighlights && currentThreadId && existHighlights[currentThreadId]
            : currentPath === 'Inbox'
            ? existHighlights
            : null
    const isHighlightsExist = highlightsList && highlightsList.content.length
    return (
        <View className={cn('py-5 box-border', className)} style={{ gap: 24 }}>
            <Header title='Highlights' />
            <Body
                placeholder='Here you will see details of selected email chain'
                className='max-h-[260px] pl-4 pr-2 mr-2'
                style={{ gap: 8 }}
                marginTop={false}>
                {!loadingAsyncTask ? (
                    isHighlightsExist ? (
                        highlightsList && highlightsList.content.map((highlight: string, idx: number) => (
                            <View key={idx}>
                                <Typography
                                    variant='body'
                                    className={cn('leading-5 py-1 pl-4 pr-2', text.boldText)}>
                                    {idx + 1}. {highlight}
                                </Typography>
                            </View>
                        ))
                    ) : (
                        <Typography variant='body' className={cn(text.inactiveText)}>
                            Here you will see details of selected email chain
                        </Typography>
                    )
                ) : (
                    <Loader text='Generate highlights' />
                )}
            </Body>
        </View>
    )
}

export default Highlights
