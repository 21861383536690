import { FC, useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { Button, Typography } from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'
import { IThread } from '@/shared/types/threads.interface'
import { useStore } from '@/shared/store'
import { formattedDate } from '@/shared/libs/hooks'
import TooltipEl from '@/components/ui/tooltip/Tooltip'
import { DataTable } from 'react-native-paper'
import { useTextLayoutHandlers } from '@/utils/replaceText'

interface IProps {
    thread: IThread[]
}

const Header: FC<IProps> = ({ thread }) => {
    const [activeArrow, setActiveArrow] = useState<'prew' | 'next' | 'all' | ''>('')
    const [isImportant, setIsImportant] = useState(false)

    const [_, setIsTextOverflowed] = useState(false)
    const { handleContainerLayout, handleTextLayout } = useTextLayoutHandlers(setIsTextOverflowed)

    const { currentThread, digestThreads, setChangeCurrentThread } = useStore()

    const { colorScheme } = useColorScheme()
    const { text, color, bg } = useColors()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    const tooltipContent = isImportant
        ? 'Click to teach Scive this conversation is not important'
        : 'Click to teach Scive this conversation is important'

    const checkCurrentConversation = (current: number, count: number) => {
        if (current === 0 && count === 0) return setActiveArrow('')
        if (current === 1 && count === 1) return setActiveArrow('')
        if (current === 1) return setActiveArrow('next')
        if (current === count) return setActiveArrow('prew')

        return setActiveArrow('all')
    }

    useEffect(() => {
        checkCurrentConversation(currentThread, digestThreads.length)
    }, [currentThread, digestThreads])

    const threadMessages = thread[currentThread !== 0 ? currentThread - 1 : 0].messages[0]
    const threadDate =
        thread[currentThread !== 0 ? currentThread - 1 : 0].messages[
            thread[currentThread !== 0 ? currentThread - 1 : 0].messages.length - 1
        ].metadata.created_at
    const titleLong = threadMessages.metadata.from_
    const titleName = titleLong.replace(/^"(.*?)".*$/, '$1')
    const titleEmail = titleLong.replace(/^.*<([^>]+)>$/, '<$1>')

    return (
        <View className={cn('pt-base-x1 justify-between flex-col border-b overflow-hidden', deviderColor)}>
            <View
                className={cn('flex-1 flex-row items-center px-base-x4 border-b', deviderColor)}
                style={{ gap: 8 }}>
                <Typography variant='h3' className={cn('flex-grow', text.inactiveText)}>
                    Related Conversations
                </Typography>
                <View className='flex flex-row items-center' style={{ gap: 8 }}>
                    <Typography variant='body' className={cn('px-4 text-xs', text.inactiveText)}>
                        {currentThread} of {digestThreads.length}
                    </Typography>
                    <View className='flex flex-row' style={{ gap: 8 }}>
                        <TooltipEl placement='bottom-start' title='Previous'>
                            <View>
                                <Button
                                    className='p-1.5 rounded-md'
                                    icon='ChevronLeft'
                                    styleOnHover={
                                        activeArrow.length && currentThread !== 1 ? bg.hoverIconSurface : ''
                                    }
                                    colorIcon={
                                        activeArrow !== 'prew'
                                            ? activeArrow === 'all'
                                                ? color.black
                                                : color.inactiveIcons
                                            : color.black
                                    }
                                    sizeIcon={24}
                                    onPress={
                                        activeArrow.length ? () => setChangeCurrentThread('prew') : undefined
                                    }
                                />
                            </View>
                        </TooltipEl>
                        <TooltipEl placement='bottom-start' title='Next'>
                            <View>
                                <Button
                                    className='p-1.5 rounded-md'
                                    icon='ChevronRight'
                                    styleOnHover={
                                        activeArrow.length && currentThread !== 1 ? bg.hoverIconSurface : ''
                                    }
                                    colorIcon={
                                        activeArrow !== 'next'
                                            ? activeArrow === 'all'
                                                ? color.black
                                                : color.inactiveIcons
                                            : color.black
                                    }
                                    sizeIcon={24}
                                    onPress={
                                        activeArrow.length ? () => setChangeCurrentThread('next') : undefined
                                    }
                                />
                            </View>
                        </TooltipEl>
                    </View>
                </View>
            </View>
            {/* <View
                className={cn(
                    'px-base-x4 pt-base-x3 pb-base-x2 flex-row justify-between items-center border-b',
                    deviderColor
                )}
                style={{ gap: 16 }}>
                <View className='flex-row items-center' style={{ gap: 16 }}>
                    <TooltipEl placement='top' title={titleName}>
                        <View>
                            <Typography
                                variant='button-default'
                                className={cn(
                                    text.boldText,
                                    'bg-important, flex-1 max-w-[250px]  desktop:max-w-[330px] font-bold'
                                )}
                                nowrap
                                numberOfLines={1}
                                ellipsizeMode='tail'>
                                {titleName}
                            </Typography>
                        </View>
                    </TooltipEl>
                    <TooltipEl placement='top' title={titleEmail}>
                        <View>
                            <Typography
                                variant='calout'
                                className='text-light-text max-w-[166px]'
                                nowrap
                                numberOfLines={1}
                                ellipsizeMode='tail'>
                                {titleEmail}
                            </Typography>
                        </View>
                    </TooltipEl>
                </View>

                <Typography variant='label-date' className={cn(text.black, 'min-w-fit')}>
                    {formattedDate(threadDate as string)}
                    {/* {formattedDate(threadMessages.metadata.created_at as string)} */}
            {/* </Typography>
            </View>
            <View
                className={cn('px-base-x4 py-0.5 flex-row items-center  border-b bg-error', deviderColor)}
                style={{ gap: 8 }}>
                <Typography
                    variant='h4'
                    nowrap
                    numberOfLines={1}
                    ellipsizeMode='tail'
                    className={cn('font-bold leading-7', text.boldText)}>
                    {threadMessages.metadata.subject}
                </Typography>
                <TooltipEl placement='top' title={tooltipContent}>
                    <View className='flex-1 flex-row mr-0 '>
                        <Button onPress={() => setIsImportant(!isImportant)} >
                            <Image
                                source={require('@/assets/icons/important.svg')}
                                tintColor={isImportant ? '#FDBA74' : colorScheme === 'dark' ? '#374151' : ''}
                                className='w-[15px] h-[14px] m-base-x2'
                            />
                        </Button>
                    </View>
                </TooltipEl>
            </View> */}

            <DataTable>
                <DataTable.Row className={cn('flex-row')}>
                    <DataTable.Cell
                        onLayout={handleContainerLayout}
                        style={{
                            overflow: 'hidden',
                            marginRight: 8,
                            padding: 0,
                            width: '100%',
                            position: 'relative'
                        }}>
                        <TooltipEl title={titleName} placement={'top'}>
                            <View className='absolute flex-1 w-full h-full z-10'></View>
                        </TooltipEl>
                        <Typography
                            numberOfLines={1}
                            ellipsizeMode='tail'
                            onLayout={handleTextLayout}
                            variant='button-default'
                            className={cn(text.boldText, 'font-bold relative')}
                            nowrap>
                            {titleName}
                        </Typography>
                    </DataTable.Cell>
                    <DataTable.Cell
                        onLayout={handleContainerLayout}
                        style={{
                            overflow: 'hidden',
                            marginRight: 8,
                            padding: 0,
                            width: '100%',
                            position: 'relative'
                        }}>
                        <TooltipEl title={titleEmail} placement={'top'}>
                            <View className='absolute flex-1 w-full h-full z-10'></View>
                        </TooltipEl>
                        <Typography
                            onLayout={handleTextLayout}
                            numberOfLines={1}
                            nowrap
                            ellipsizeMode='tail'
                            variant='calout'
                            className='text-light-text'>
                            {titleEmail}
                        </Typography>
                    </DataTable.Cell>
                    <DataTable.Cell style={{ maxWidth: 16, overflow: 'hidden' }}>
                        <View></View>
                    </DataTable.Cell>
                    <DataTable.Cell style={{ maxWidth: 50 }}>
                        <Typography variant='label-date' className={cn(text.black, 'min-w-fit')}>
                            {formattedDate(threadMessages.metadata.created_at as string)}
                        </Typography>
                    </DataTable.Cell>
                </DataTable.Row>
            </DataTable>
            <DataTable>
                <DataTable.Row>
                    <DataTable.Cell>
                        <Typography
                            variant='h4'
                            nowrap
                            numberOfLines={1}
                            ellipsizeMode='tail'
                            className={cn('font-bold leading-7', text.boldText)}>
                            {threadMessages.metadata.subject}
                        </Typography>
                    </DataTable.Cell>
                    <DataTable.Cell style={{ maxWidth: 36 }}>
                        <TooltipEl placement='top' title={tooltipContent}>
                            <View className='flex-1 flex-row mr-0 '>
                                <Button onPress={() => setIsImportant(!isImportant)}>
                                    <Image
                                        source={require('@/assets/icons/important.svg')}
                                        tintColor={
                                            isImportant ? '#FDBA74' : colorScheme === 'dark' ? '#374151' : ''
                                        }
                                        className='w-[15px] h-[14px] m-base-x2'
                                    />
                                </Button>
                            </View>
                        </TooltipEl>
                    </DataTable.Cell>
                </DataTable.Row>
            </DataTable>
        </View>
    )
}

export default Header
