import cn from 'clsx'
import { FC } from 'react'
import { View } from 'react-native'
import { IDigestTodoSuggestions, IThreadTodoContent } from '@/shared/types/dashboard.interface'
import { StyledProps } from 'nativewind'
import TodoItem from './TodoItem'
import { IUpdateTodo } from '@/shared/types/todo.interface'

interface ITodoList {
    todos: IDigestTodoSuggestions | IThreadTodoContent | null
    className?: StyledProps<string>
    addTask?: (todo: IUpdateTodo) => void
}

const TodoList: FC<ITodoList> = ({ todos, className, addTask }) => {
	return (
        <View className={cn('flex-col', className)}>
            {todos && todos.content.map((content, index) => (
                <TodoItem key={index} content={content} addTask={addTask} />
            ))}
        </View>
    )
}

export default TodoList
