import { DashboardService } from '@/services/dashboard'
import { useStore } from '@/shared/store'
import { IDigestData } from '@/shared/types/dashboard.interface'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { readAllGetInStorage } from '@/services/app.helper'
import { useApiGetThreads } from '../../inbox/api'

export const useApiGetDigestList = () => {
    const [enabled, setEnabled] = useState<boolean>(false)

    const {
        digests,
        filteredDigests,
        setDigests,
        setFetchDigests,
        fetchDigests,
        allDigestThreadRead,
        setAllDigestThreadRead,
    } = useStore()

    const { fetchThreads } = useApiGetThreads()

    const queryResult = useQuery(['get_llm_digest_list'], () => DashboardService.getDigestList(), {
        enabled,
        async onSuccess(res) {
            console.log('get_llm_digest_list', res)
            await fetchThreads()
            if (res && res.data && !allDigestThreadRead) {
                setDigests(res.data as IDigestData[])
                setFetchDigests(false)
            }
        }
    })

    useEffect(() => {
        const fetchData = async () => {
            const readAll = await readAllGetInStorage()
            setAllDigestThreadRead(readAll)

            if (!digests.length && fetchDigests && !allDigestThreadRead) {
                fetchApiDigestList()
            }
        }

        fetchData()
    }, [])


    useEffect(() => {
        enabled && setEnabled(false)
    }, [enabled])

    const fetchApiDigestList = () => {
        setEnabled(true)
    }

    return {
        digests: filteredDigests,
        setEnabled,
        fetchApiDigestList,
        ...queryResult
    }
}
