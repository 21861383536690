import { FC, useEffect, useState } from 'react'
import { Pressable, ScrollView } from 'react-native'
import { TableRow } from '../index'
import cn from 'clsx'
import { Loader, Typography } from '@/components/ui'
import { TThreadsCategories } from '@/shared/store/types'
import { useApiGenerateScivePowerItem, useApiGetThread, useApiUpdateThreadLabels } from '../../api'
import { useStore } from '@/shared/store'
import { EThreadLabels } from '@/shared/store/enum'
import { IThread } from '@/shared/types/threads.interface'
import { useApiAddTodo } from '@/components/screens/todo/api'
import { IUpdateTodo } from '@/shared/types/todo.interface'

interface IProps {
    threads: IThread[]
    isLoading: boolean
    category: TThreadsCategories
    isFirstTread?: boolean
    openThreadId: string | null
    setOpenThreadId: string | null
    handleScroll: ({ nativeEvent }: { nativeEvent: any }) => void
    scrollRef: any
}

const TableList: FC<IProps> = ({ threads, isLoading, handleScroll, scrollRef }) => {
    const { fetchThread } = useApiGetThread()
    const { fetchApiGenerateScivePowerItem } = useApiGenerateScivePowerItem()
    const { addTodo } = useApiAddTodo()

    const [loadingData, setLoadingData] = useState(true)

    const {
        setSelectedThreads,
        selectedThreads,
        removeThread,
        removeTodoSuggestions,
        generateThreadId,
        setGenerateThreadId,
        loadingNextPage,
        updateThreadLabels
    } = useStore()

    const { fetchUpdateThreadLabels } = useApiUpdateThreadLabels()
    const [openThreadId, setOpenThreadId] = useState<string | null>(null)

    const handleUpdateThread = (threadId: string, label: string) => {
        updateThreadLabels(label, threadId)
        fetchUpdateThreadLabels(label as EThreadLabels, threadId)
    }
    const handleRemoveThread = (threadId: string) => {
        // !API
        removeThread(threadId)
    }

    useEffect(() => {
        !isLoading &&
            !threads.length &&
            setTimeout(() => {
                setLoadingData(false)
            }, 10000)
    }, [threads, isLoading, loadingData, loadingNextPage])

    const fetchThreadDetails = (threadId: string) => {
        !generateThreadId
            ? fetchApiGenerateScivePowerItem(threadId)
            : threadId !== generateThreadId
            ? (setGenerateThreadId(null), fetchApiGenerateScivePowerItem(threadId))
            : setGenerateThreadId(null)
    }

    const addTask = (todo: IUpdateTodo) => {
        removeTodoSuggestions(todo.content, 'thread')
        addTodo({ ...todo })
    }

    const sortThreadsByDate = threads.sort(
        (a: any, b: any) =>
            new Date(b.messages[b.messages.length - 1].metadata.created_at).getTime() -
            new Date(a.messages[a.messages.length - 1].metadata.created_at).getTime()
    )
    return (
        <ScrollView
            ref={scrollRef}
            className={cn('flex-col flex-1')}
            onScroll={(e) => handleScroll(e)}
            scrollEventThrottle={100}>
            {!isLoading ? (
                threads.length ? (
                    sortThreadsByDate.map((item, index) => {
                        return (
                            <TableRow
                                isFirstTread={index === 0}
                                draft={false}
                                key={index}
                                id={item.id}
                                thread={item}
                                fetchThreadDetails={() => fetchThreadDetails(item.id)}
                                isSelected={selectedThreads.includes(item.id)}
                                setSelectedThreads={() => setSelectedThreads(item.id)}
                                selectThread={() => fetchThread(item.id)}
                                updateThread={handleUpdateThread}
                                removeThread={() => handleRemoveThread(item.id)}
                                addTask={addTask}
                                setOpenThreadId={setOpenThreadId}
                                showDetail={openThreadId === item.id}
                            />
                        )
                    })
                ) : loadingData ? (
                    <Loader text='Loading threads' />
                ) : (
                    <Typography variant='body'>You don’t have threads here.</Typography>
                )
            ) : (
                <Loader text='Loading threads' />
            )}
            {!!threads.length && loadingNextPage && <Loader text='Loading threads' />}
        </ScrollView>
    )
}

export default TableList
