import { useEffect } from 'react'
import { useAuth } from '@/hooks/useAuth'
import { getToken } from '@/services/auth/auth.helper'
import { AuthService } from '@/services/auth/auth.service'

export const useCheckAuth = (routeName?: string) => {
	const { user, setUser } = useAuth()

	useEffect(() => {
		const checkToken = async () => {
			const token = await getToken()
			if (!token) {
				await AuthService.logout()
				setUser(null)
			}
		}

		let ignore = checkToken()
	}, [])
	
	useEffect(() => {
		const checkToken = async () => {
			const token = await getToken()

			if (!token && user) {
				await AuthService.logout()
				setUser(null)
			}
		}
		
		let ignore = checkToken()
	}, [routeName])
}
