import { useDate } from '@/shared/libs/hooks'
import { ITodo } from '@/shared/types/todo.interface'
import { filterByCategory } from './filters.helper'
import { ICountTodosTab } from '../types'

export const updateItem = (id: number, item: ITodo, todo: ITodo): ITodo => {
    if (item.id === id) {
        return {
            ...todo,
            date: todo.due ? useDate(todo.due) : '',
            due: null, // TODO - clear
            sub_content: '', // TODO - clear
            is_favorite: false, // TODO - clear
            parent_id: null, // TODO - clear
            subtasks: [] // TODO - clear
        }
    }
    return item
}

export const countTodosTab = (arr: ITodo[]): ICountTodosTab => ({
    all: filterByCategory(arr, 'all').length,
    today: filterByCategory(arr, 'today').length,
    tomorrow: filterByCategory(arr, 'tomorrow').length,
    important: filterByCategory(arr, 'important').length,
    completed: filterByCategory(arr, 'completed').length
})

export const sortByCompleted = (arr: any) => arr.sort((a: ITodo, b: ITodo) => +a.is_done - +b.is_done)
