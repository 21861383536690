import { FC, useEffect, useState } from 'react'
import { ScrollView, TouchableHighlight, View } from 'react-native'
import Accordion from 'react-native-collapsible/Accordion'
// import { TSection } from '@/components/screens/inbox/inbox.interface'
import { Icon, Typography } from '@/components/ui'
import cn from 'clsx'
import { TSection } from '../../todo/todo.interface'

interface IFilters {
	sections: TSection[]
}

const Filters: FC<IFilters> = ({ sections }) => {
	const [activeSections, setActiveSections] = useState<number[]>([])

	useEffect(() => {
		const lenghtArray = Array.from(
			{ length: sections.length },
			(_, index) => index
		)

		if (lenghtArray.length !== activeSections.length) {
			setActiveSections(
				Array.from({ length: sections.length }, (_, index) => index)
			)
		}
	}, [sections])

	function renderHeader(section: TSection, _: any, isActive: boolean) {
		return (
			<View
				style={{
					backgroundColor: isActive
						? 'rgba(255,255,255,1)'
						: 'rgba(245,252,255,1)'
				}}
				className='p-base-x2 border-b border-gray-300'
			>
				<View className='items-center flex-row' style={{ gap: 8 }}>
					<Icon
						name='ChevronUp'
						size={24}
						className={cn(isActive && 'transition-all rotate-180')}
					/>
					<Typography variant='body'>{section.title}</Typography>
				</View>
			</View>
		)
	}

	function renderContent(section: TSection) {
		if (section.content) return <View>{section.content}</View>

		return (
			<Typography
				variant='body'
				className='h-[44px] pl-base-x2 flex items-center pr-base-x2'
			>
				Hooray! You don't have a new email to work with.
			</Typography>
		)
	}
	return (
        <ScrollView
            contentInsetAdjustmentBehavior='automatic'
            className='flex-1 pr-base-x2 mb-base-x1 ml-base-x1 overflow-auto'>
            <Accordion
                align='bottom'
                sections={sections}
                activeSections={activeSections}
                expandMultiple={true}
                renderHeader={renderHeader}
                renderContent={renderContent}
                onChange={(sections: number[]) => setActiveSections(sections as number[])}
            />
        </ScrollView>
    )
}

export default Filters
