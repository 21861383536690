import AsyncStorage from '@react-native-async-storage/async-storage'

export const getCourseCompleted = async () => {
	const courseCompleted = JSON.parse(
		(await AsyncStorage.getItem('courseCompleted')) || 'false'
	)
	return courseCompleted || false
}

export const saveCourseCompleted = async (value: boolean) => {
	await AsyncStorage.setItem('courseCompleted', JSON.stringify(value))
}

export const getAgreeTerms = async () => {
	const courseCompleted = JSON.parse(
		(await AsyncStorage.getItem('agreeTerms')) || 'false'
	)
	return courseCompleted || false
}

export const setAgreeTerms = async (value: boolean) => {
	await AsyncStorage.setItem('agreeTerms', JSON.stringify(value))
}

export const getVisibleComposeInfo = async () => {
	const courseCompleted = JSON.parse(
		(await AsyncStorage.getItem('visibleComposeInfo')) || 'false'
	)
	return courseCompleted || false
}

export const saveVisibleComposeInfo = async (value: boolean) => {
	await AsyncStorage.setItem('visibleComposeInfo', JSON.stringify(value))
}



export const readAllGetInStorage = async () => {
    const readAll = await AsyncStorage.getItem('readAll')
    return readAll !== null ? readAll === 'true' ?  true : false : null
}
export const readAllSaveInStorage = async (value: boolean) => {
	await AsyncStorage.setItem('readAll', JSON.stringify(value))
}
export const readAllRemoveFromStorage = async () => {
	await AsyncStorage.removeItem('readAll')
}

export const readDigestItemGetInStorage = async () => {
    const readDigestItems = await AsyncStorage.getItem('readDigestItems')
    return readDigestItems?.split(',').filter(item => !!item.length)
}
export const readDigestItemSaveInStorage = async (value: string) => {
	const readDigestItems = await readDigestItemGetInStorage()
    await AsyncStorage.setItem('readDigestItems', [readDigestItems, value].join(','))
}
export const readDigestItemRemoveFromStorage = async () => {
    await AsyncStorage.removeItem('readDigestItems')
}


export const refreshTimeoutGetInStorage = async () => {
    const refreshTimeout = await AsyncStorage.getItem('refreshTimeout')
    return refreshTimeout
}
export const refreshTimeoutSaveInStorage = async (value: string) => {
    await AsyncStorage.setItem('refreshTimeout', JSON.stringify(value))
}
export const refreshTimeoutRemoveFromStorage = async () => {
    await AsyncStorage.removeItem('refreshTimeout')
}

