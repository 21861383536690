import { FC } from 'react'
import { TextInput } from 'react-native'
import { IInput } from './input.interface'

const Input: FC<IInput> = ({ value, name, onChangeText, placeholder, className }) => {
    return (
        <TextInput
            name={name}
            autoCapitalize={'none'}
            onChangeText={onChangeText}
            value={value}
            underlineColorAndroid='transparent'
            placeholder={placeholder}
            className={className}
            style={{
                // @ts-ignore
                outline: 'none'
            }}
        />
    )
}

export default Input
