import cn from 'clsx'
import { FC, PropsWithChildren } from 'react'
import { Pressable } from 'react-native-web-hover'
import { IButton } from './button.interface'
import { Icon, Typography } from '@/components/ui'
import { View } from 'react-native'

const Button: FC<PropsWithChildren<IButton>> = ({
	className,
	styleOnHover,
	styleTextOnHover,
	styleColorIconOnHover,
	classNameText,
	classNameIcon,
	colorIcon,
	disabled,
	noMargin,
	icon,
	variant,
	style,
	fillIcon,
	strokeWidthIcon,
	sizeIcon,
	children,
	...rest
}) => {
	return (
		<Pressable
			// @ts-ignore
			style={{ userSelect: 'none' }}
			disabled={disabled}
			{...rest}
		>
			{({ hovered }) => (
				<View
					className={cn(
						'flex-row items-center h-full',
						className,
						hovered && !disabled && styleOnHover
					)}
					style={{
						//@ts-ignore
						transition: 'all 0.3s ease-out'
					}}
				>
					{icon && (
						<Icon
							name={icon}
							size={sizeIcon}
							className={classNameIcon}
							strokeWidth={strokeWidthIcon}
							color={
								hovered && !disabled && styleColorIconOnHover
									? styleColorIconOnHover
									: colorIcon
							}
							fill={fillIcon}
						/>
					)}
					{variant ? (
						<Typography
							variant={variant}
							className={cn(
								'text-center',
								classNameText,
								hovered && !disabled && styleTextOnHover,
								{
									'ml-base-x2': !!icon && children && !noMargin
								}
							)}
						>
							{children}
						</Typography>
					) : (
						children
					)}
				</View>
			)}
		</Pressable>
	)
}

export default Button
