import { FC } from 'react'
import { TextInput, View } from 'react-native'
import cn from 'clsx'
import { Button} from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'
import TooltipEl from '../../tooltip/Tooltip'
import { IComposeType } from '@/shared/store/types'

interface IButtonGenerate {
    onGenerate: () => void
    type: IComposeType
}
const ButtonGenerate: FC<IButtonGenerate> = ({ onGenerate, type }) => {
    const { color, border, text } = useColors()
    const { noValidPrompt, setComposePrompt, generatePromptContent } = useStore()

    return (
        <View className={cn('w-full flex-row items-center border-b mb-base-x1 pb-base-x1', border.divider)}>
            <TextInput
                autoCapitalize={'none'}
                onChangeText={(value) => setComposePrompt(value, 'prompt', type)}
                autoFocus={true}
                value={generatePromptContent[type]}
                underlineColorAndroid='transparent'
                placeholder='Ask AI to write answer...'
                className={cn(
                    'text-base-body h-[36px] w-full',
                    text.disabledText,
                    noValidPrompt
                        ? 'px-4 border border-error bg-error/20 text-error'
                        : generatePromptContent[type].length && text.boldText
                )}
                style={{
                    // @ts-ignore
                    outline: 'none'
                }}
            />
            <TooltipEl placement='top' title='Generate'>
                <View>
                    <Button
                        icon='Wand2'
                        sizeIcon={28}
                        onPress={onGenerate}
                        colorIcon={color.black}
                        styleColorIconOnHover='sky'
                        className='p-base-x2 rounded-base-x3'
                    />
                </View>
            </TooltipEl>
        </View>
    )
}

export default ButtonGenerate
