import { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import { IMessage, IMessages } from './messages.interface'
import MessageBubble from '@/components/ui/messages/MessageBubble'
import cn from 'clsx'
import { useSizeSelector } from '@/context/SizeSelectorContext'
import { Typography } from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'
import { useUserProfile } from '@/components/screens/settings/api'
import { formatDateToDayMonth } from '@/shared/libs/hooks/useDate'
``
const Messages: FC<IMessages> = ({ messagesType }) => {
    const { thread, digestThreads, currentThread, chatType } = useStore()

    const { size } = useSizeSelector()
    const { text } = useColors()

    const [me, setMe] = useState<string>('')
    const { userProfile } = useUserProfile()

    useEffect(() => {
        setMe(userProfile?.email || '')
    }, [userProfile, messagesType, chatType])

    const digestIndex = currentThread !== 0 ? currentThread - 1 : 0

    const renderMessages = (messages: IMessage[]) => {
        return messages.map((message: any, index: number) => {
            const isSameSenderAsPrevious =
                index > 0 && message.metadata.from_ === messages[index - 1].metadata.from_
            const isSameSenderAsNext =
                index < messages.length - 1 && message.metadata.from_ === messages[index + 1].metadata.from_

            const formatMessageGroup =
                index > 0 &&
                formatDateToDayMonth(message.metadata.created_at) ===
                    formatDateToDayMonth(messages[index - 1].metadata.created_at || '')

            return (
                <View key={index}>
                    <Typography
                        variant='h4'
                        className={cn(
                            'font-bold text-black-700 text-center mt-base-x2 mb-base-x4',
                            text.boldText
                        )}>
                        {!formatMessageGroup && formatDateToDayMonth(message.metadata.created_at)}
                    </Typography>

                    <MessageBubble
                        message={message}
                        showAvatar={!isSameSenderAsPrevious}
                        showDate={!isSameSenderAsNext}
                        iAmSender={message.metadata.from_.includes(me)}
                    />
                </View>
            )
        })
    }

    return (
        <View
            className={cn(
                'flex-col mr-base-x2 max-w-[600px]',
                size === 'small' && 'max-w-full',
                size === 'large' && 'm-auto'
            )}
            style={{ gap: 8 }}>
            {messagesType === 'digests' &&
                !!digestThreads.length &&
                renderMessages(digestThreads[digestIndex].messages)}
            {messagesType === 'threads' && !!thread && renderMessages(thread.messages)}
        </View>
    )
}

export default Messages
