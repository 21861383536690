import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { FC } from 'react'
import Auth from '@/components/screens/auth/Auth'
import { useAuth } from '@/hooks/useAuth'
import { TypeRootStackParamList } from './navigation.types'
import { routes } from './routes'
import { useColorScheme } from 'nativewind'

const Stack = createNativeStackNavigator<TypeRootStackParamList>()

const PrivateNavigator: FC = () => {
	const { user } = useAuth()
	
	const { colorScheme } = useColorScheme()

	return (
		<Stack.Navigator
			screenOptions={{
				headerShown: false,
				contentStyle: {
					backgroundColor: user
						? colorScheme === 'dark'
							? '#1F2937'
							: 'white'
						: '#F3F4F6',
					overflow: 'visible',
					minWidth: 1208,
					minHeight: 720,
					padding: 0,
					borderRadius: 0,
					paddingBottom: 0
				}
			}}
		>
			{user ? (
				routes.map(route => <Stack.Screen key={route.name} {...route} />)
			) : (
				<Stack.Screen name='Auth' component={Auth} />
			)}
		</Stack.Navigator>
	)
}

export default PrivateNavigator
