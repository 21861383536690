import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useStore } from '@/shared/store'
import { ThreadsService } from '@/services/inbox/threads.api'
import { IMessageForm } from '@/shared/store/types'
import { useUserProfile } from '../../settings/api'
import { cleanHtml, htmlToText } from '@/utils/htmlToText'
import { ISendMessage } from '@/shared/types/threads.interface'

export const useApiSendMessage = () => {
    const [message, setMessage] = useState<ISendMessage>({
        to: [],
        from: '',
        cc: [],
        bcc: [],
        in_reply_to: null,
        subject: '',
        html: '',
        thread_id: ''
    })
    const {
        clearSendMessageForm,
        showCompose,
        thread,
        addMessage,
        chatType,
        digestThreads,
        currentThread,
        clearComposePrompt,
        // addDigestMessage,
    } = useStore()
    const { userProfile } = useUserProfile()

    const mutationResult = useMutation(['send_message'], () => ThreadsService.sendMessage(message), {
        onSuccess(res) {
            if (res && res.data) {
                clearSendMessageForm()
                clearComposePrompt()
                addMessage(res.data)
                // addDigestMessage(res.data)
                // console.log('Message sent successfully', res)
            }
        }
    })
    

    const sendMessage = async (data: IMessageForm) => {
        let threadMessages = thread?.messages[0].metadata
        if (chatType === 'chat_dashboard') {
            threadMessages = digestThreads[currentThread !== 0 ? currentThread - 1 : 0].messages[0].metadata
        }

        if (!!userProfile?.email && threadMessages) {

            const to = showCompose ? [data.to] : [threadMessages.from_]
            const from = showCompose ? userProfile?.email : threadMessages.to[0]
            const cc = showCompose ? [] : (threadMessages.cc as string[])
            const bcc = showCompose ? [] : (threadMessages.bcc as string[])
            const in_reply_to = showCompose ? null : (threadMessages.in_reply_to as string)
            const subject = showCompose ? (data.subject as string) : (threadMessages.subject as string)
            const thread_id = showCompose ? '' : (threadMessages.thread_id as string)
            const html = cleanHtml(data.text)

            // const html = htmlToText(data.text as string)

            const newMessage = {
                to,
                from,
                cc,
                bcc,
                in_reply_to,
                subject,
                html,
                thread_id
            }

            await setMessage({ ...newMessage })
            await mutationResult.mutate()
            return true
        }
        return false
    }

    return {
        sendMessage,
        ...mutationResult
    }
}
