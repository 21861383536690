import { FC } from 'react'
import { View } from 'react-native'
import { Button, ButtonPlain, Typography } from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'

interface IProps {
	text: string
	onClose: () => void
}

const ModalNotification: FC<IProps> = ({ text, onClose }) => {
	const { color, text: textColor, bg } = useColors()

	return (
        <View
            className={cn(
                'absolute bottom-base-x2 left-base-x2 p-base-x2 pl-base-x4 bg-gray-800 rounded-base-x2 max-w-[440px] shadow',
                bg.infoSurface
            )}
            style={{ gap: 4 }}>
            <View className='flex-row justify-between items-start '>
                <Typography variant='calout' className={textColor.boldText}>
                    {text}
                </Typography>
                <Button
                    colorIcon={color.black}
                    icon='X'
                    sizeIcon={16}
                    onPress={onClose}
                    className='flex items-center justify-center w-base-x8 h-base-x8'
                />
            </View>
            <ButtonPlain styleTextOnHover='text-black' className='ml-auto h-base-x8'>
                Undo
            </ButtonPlain>
        </View>
    )
}

export default ModalNotification
