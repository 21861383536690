import { FC } from 'react'
import { Image, View } from 'react-native'
import { IPlag } from './plug.interface'
import cn from 'clsx'
import { Typography } from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'

const Plug: FC<IPlag> = ({ text, size, className }) => {
	const { text: textColor, bg } = useColors()
	const { colorScheme } = useColorScheme()

	return (
		<View
			className={cn('justify-center items-center', className)}
			style={{ gap: 8 }}
		>
			<View
				className={cn(
					'rounded-[22.5px]',
					bg.hoverSurface,
					size === 'small' && 'p-[15px]',
					size === 'large' && 'p-[30px]'
				)}
			>
				<Image
					source={require(
						`@/assets/icons/logo-sidebar${
							colorScheme === 'dark' ? '-dark-full' : ''
						}.svg`
					)}
					className={cn(
						size === 'small' && 'w-[45px] h-[45px]',
						size === 'large' && 'w-[90px] h-[90px]'
					)}
				/>
			</View>
			<Typography
				variant='body'
				className={cn('pt-base-x4 pb-base-x4', textColor.inactiveText)}
			>
				{text}
			</Typography>
		</View>
	)
}

export default Plug
