import { FC, useState } from 'react'
import { View } from 'react-native'
import { Button, Typography} from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { IUpdateTodo } from '@/shared/types/todo.interface'
import { getDate } from '@/shared/libs/hooks'
import { useStore } from '@/shared/store'
import TooltipEl from '../tooltip/Tooltip'

export interface ITodoItem {
    content: string
    addTask?: (todo: IUpdateTodo) => void
}

const TodoItem: FC<ITodoItem> = ({ content, addTask }) => {
    const { todoSuggestions } = useStore()

    const { colorScheme } = useColorScheme()
    const [isFavorite, setIsFavorite] = useState(false)
    const { text, color } = useColors()

    return (
        <View className='pt-base-x1 pb-base-x1 flex-col items-center' style={{ gap: 8 }}>
            <View className='flex-row justify-between items-center w-full'>
                <View className='flex-row items-center max-w-[80%]'>
                    <TooltipEl placement='right' title='Add to To-Do list'>
                        <View>
                            <Button
                                icon='Plus'
                                className='p-base-x2'
                                sizeIcon={32}
                                colorIcon={color.activeSky}
                                onPress={() =>
                                    addTask?.({
                                        id: todoSuggestions?.id,
                                        content,
                                        due: getDate(),
                                        is_favorite: isFavorite
                                    } as unknown as IUpdateTodo)
                                }
                            />
                        </View>
                    </TooltipEl>
                    <Typography variant='body-list' className={cn(text.sky)}>
                        {/* <Typography variant='body-list' className={cn(text.boldText)}> */}
                        {/* <Typography variant='body-list' className={cn(text.boldText, overdue && 'text-red')}> */}
                        {content}
                    </Typography>
                </View>
                <Button
                    icon='Star'
                    colorIcon={isFavorite ? color.star : color.black}
                    fillIcon={isFavorite ? (colorScheme === 'light' ? '#1F2937' : '#38BDF8') : ''}
                    sizeIcon={20}
                    onPress={() => setIsFavorite(!isFavorite)}
                />
            </View>
        </View>
    )
}

export default TodoItem
