import { FC, useEffect } from 'react'
import { View } from 'react-native'
import { Body, Footer, Header, Loader } from '@/components/ui'
import { StyledProps, useColorScheme } from 'nativewind'
import { useMailPosition } from '@/context/MailPositionContext'
import cn from 'clsx'
import { useStore } from '@/shared/store'
import { TCurrentPath } from '@/shared/store/types'

interface IProps {
    currentPath: TCurrentPath | null
    className?: StyledProps<string>
}

const DetailedSummary: FC<IProps> = ({ className, currentPath }) => {
    const { position } = useMailPosition()
    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    const {
        detailedCount,
        detailedState,
        detailedSummary,
        setDetailedCount,
        loadingAsyncTask,
        threadDetailedSummary
    } = useStore()

    useEffect(() => {
        if (detailedCount !== 0) {
            setDetailedCount(detailedCount)
        }
    }, [detailedSummary, detailedCount, currentPath, detailedState])
    
    const messageSummary = currentPath === 'Dashboard'
        ? detailedSummary?.length
            ? detailedSummary
            : undefined
        : currentPath === 'Inbox'
        ? threadDetailedSummary?.length
            ? threadDetailedSummary
            : undefined
        : undefined
    return (
        <View
            className={cn(
                'pt-base-x5 pb-base-x2 pl-base-x2 flex-1 justify-between',
                className
            )}>
            <Header
                title='Message Summary'
                subtitle=''
                counter={{ count: detailedCount, subject: 'pro' }}
                status={detailedState}
            />
            {!loadingAsyncTask ? (
                <Body
                    body={messageSummary}
                    placeholder='Here you will see the AI-generated details of the selected email'
                    status={detailedState}
                />
            ) : (
                <Body>
                    <Loader text='Generate message summary' />
                </Body>
            )}

            {position === 'horizontal' && <Footer />}
        </View>
    )
}

export default DetailedSummary
