import { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Button, Icon, Typography } from '@/components/ui'
import cn from 'clsx'
import { Pressable } from 'react-native-web-hover'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { ITodo } from '@/shared/types/todo.interface'
import TooltipEl from '@/components/ui/tooltip/Tooltip'


interface IProps {
	todo: ITodo
	selected: boolean
	onPress?: () => void
	removeTask?: () => void
	updateTask?: (id: number, todo: ITodo) => void
	addTodo?: (todo: ITodo) => void
}

const TodoItem: FC<IProps> = ({
	todo,
	selected,
	onPress,
	removeTask,
	updateTask,
}) => {
	const { id, date, is_done, content, is_favorite } = todo && todo

	const { colorScheme } = useColorScheme()
	const { bg, text: textColor, color } = useColors()

	const overdue = date === 'Yesterday'


	return (
        <Pressable
            // @ts-ignore
            style={{ userSelect: 'none', outline: 'none' }}
            onPress={onPress}>
            {({ hovered }) => (
                <View
                    className={cn(
                        'pt-base-x1 pb-base-x1 flex-col items-center rounded-base-x2',
                        hovered && bg.hoverIconSurface,
                        selected && bg.hoverSurface,
                        hovered && date === 'Yesterday' && bg.errorLightSurface,
                        date === 'Yesterday' && selected && bg.errorSelectedSurface
                    )}
                    style={{
                        gap: 8,
                        //@ts-ignore
                        transition: 'all 0.2s ease-out'
                    }}>
                    <View className='flex-row justify-between items-center w-full'>
                        <View className='flex-row items-center max-w-[80%]'>
                            <TooltipEl placement='bottom-end' title='Mark completed'>
                                <View>
                                    <Button
                                        icon={is_done ? 'CheckCircle' : 'Circle'}
                                        className='p-base-x2'
                                        sizeIcon={16}
                                        colorIcon={is_done ? color.tooltip : color.black}
                                        onPress={() =>
                                            updateTask &&
                                            updateTask(id as number, {
                                                ...todo,
                                                is_done: !is_done
                                            })
                                        }
                                    />
                                </View>
                            </TooltipEl>
                            <Typography
                                variant='body-list'
                                className={cn(textColor.boldText, overdue && 'text-error')}>
                                {content}
                            </Typography>
                        </View>
                        <View className='flex-row items-center'>
                            <Button
                                icon='Trash2'
                                className='p-base-x2'
                                sizeIcon={20}
                                colorIcon={color.black}
                                onPress={removeTask}
                            />
                            <Button
                                icon={'Link'}
                                className='p-base-x2'
                                sizeIcon={20}
                                colorIcon={color.black}
                                onPress={() => console.log('LINK')}
                            />

                            <Button
                                icon='Star'
                                className='p-base-x2'
                                sizeIcon={20}
                                onPress={() =>
                                    updateTask &&
                                    updateTask(id as number, {
                                        ...todo,
                                        is_favorite: !is_favorite
                                    })
                                }
                                colorIcon={is_favorite ? color.star : color.black}
                                fillIcon={
                                    is_favorite ? (colorScheme === 'light' ? '#1F2937' : '#38BDF8') : ''
                                }
                            />
                        </View>
                    </View>
                    {!!date && (
                        <>
                            <View
                                className={cn(
                                    'pl-base-x3 pr-base-x3 pt-base-x1 pb-base-x1 rounded-base-x16',
                                    bg.inactiveSurface
                                )}>
                                <Typography
                                    variant='label-date'
                                    className={cn(
                                        'text-center text-light-text',
                                        date === 'Today' && 'text-button',
                                        date === 'Yesterday' && 'text-error'
                                    )}>
                                    {date}
                                </Typography>
                            </View>
                        </>
                    )}
                    <Typography variant='label-date' className='text-center text-light-text'>
                        1 of 5
                    </Typography>
                </View>
            )}
        </Pressable>
    )
}

export default TodoItem
