import { FC, useEffect, useRef } from 'react'
import { View } from 'react-native'
import TodoItem from './TodoItem'
import { useApiGetTodos, useRemoveTodo, useUpdateTodo } from '../../api'
import { useStore } from '@/shared/store'
import { ITodo } from '@/shared/types/todo.interface'
import { Loader } from '@/components/ui'

interface IProps {
    todos: ITodo[]
    setSelectedTodo: (value: ITodo | null) => void
    setMouseClick: (value: boolean) => void
}

const TodoList: FC<IProps> = ({ todos, setSelectedTodo, setMouseClick }) => {
    const { isFetching } = useApiGetTodos()
    const { todo: selected, getTodo, selectedTodos } = useStore()

    const { updateTodo } = useUpdateTodo()
    const { removeTodo } = useRemoveTodo()

    const updateTask = (id: number, todo: ITodo) => {
        updateTodo(id, { ...todo })
    }

    return (
        <View className='flex-col mb-base-x4' style={{ gap: 16 }}>
            {!isFetching ? (
                todos.length ? (
                    todos.map((todo) => {
                        const existItem = !!selectedTodos.find((item) => item.id === todo.id)
                        const selectItem = existItem || selected.id === todo.id
                        return (
                            <TodoItem
                                key={todo.id}
                                todo={todo}
                                selected={selectItem}
                                // selected={selected.id === todo.id}
                                updateTask={updateTask}
                                removeTask={() => removeTodo(todo.id as number)}
                                onPress={() => (
                                    getTodo(todo.id as number), setSelectedTodo(todo), setMouseClick(true)
                                )}
                            />
                        )
                    })
                ) : null
            ) : (
                <Loader text='Loading todos' />
            )}
        </View>
    )
}

export default TodoList
