import { FC } from 'react'
import { Pressable } from 'react-native'
import { Icon, Typography } from '@/components/ui'

interface ICheckbox {
	text: string
	checked: boolean
	error?: boolean
	form: 'circle' | 'square'
	onChange: (value: boolean) => void
}

const Checkbox: FC<ICheckbox> = ({
	text,
	checked,
	error,
	form = 'circle',
	onChange
}) => {
	return (
		<Pressable
			className='flex-row items-center'
			onPress={() => onChange(!checked)}
			//@ts-ignore
			style={{ userSelect: 'none' }}
		>
			<Icon
				name={
					form === 'circle'
						? checked
							? 'CheckCircle'
							: 'Circle'
						: checked
						? 'CheckSquare'
						: 'Square'
				}
				size={16}
				color={error ? 'red' : checked ? 'sky-500' : 'black'}
			/>
			<Typography variant='body' className='p-base-x2'>
				{text}
			</Typography>
		</Pressable>
	)
}

export default Checkbox
