import { getAuthUrl } from "@/config/api.config"
import { headers } from "../api/headers"
import { request } from "../api/request.api"
import { IProfileResponse } from '@/shared/types/user.interface'

export const settingsService = {
    async getUserProfile () {
        return request<IProfileResponse>({
            url: getAuthUrl('/me'),
            method: 'GET',
            headers: await headers()
        })
    }
}