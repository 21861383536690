export const useDate = (due: string | null): string => {
    if(!due) return ''
    
    const date = new Date(due)
    const now = new Date()

    const isToday = date.toDateString() === now.toDateString()
    const isTomorrow = date.getDate() === now.getDate() + 1
        && date.getMonth() === now.getMonth()
        && date.getFullYear() === now.getFullYear()
    const isYesterday = date.getDate() === now.getDate() - 1
        && date.getMonth() === now.getMonth()
        && date.getFullYear() === now.getFullYear()

    if (isToday) {
        return 'Today'
    } else if (isTomorrow) {
        return 'Tomorrow'
    } else if (isYesterday) {
        return 'Yesterday'
    }
    const formattedDate = date.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    return formattedDate
}

export const getDate = (tomorrow = false): string => {
    const timestamp = Date.now()
    const date = new Date(timestamp)
    
    // date.setDate(date.getDate() - 1) // Yesterday
    tomorrow && date.setDate(date.getDate() + 1)
    return date.toISOString()
}

export const formattedDate = (date: string): string => {
    const dateObj = new Date(date)
    const today = new Date()

    // Проверяем, является ли дата сегодняшним днем
    const isToday =
        dateObj.getDate() === today.getDate() &&
        dateObj.getMonth() === today.getMonth() &&
        dateObj.getFullYear() === today.getFullYear()

    if (isToday) {
        // Форматируем как время, если это сегодняшний день
        const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true }
        return dateObj.toLocaleString('en-US', options)
    } else {
        // Форматируем как "день месяц", если это не сегодняшний день
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' }
        return dateObj.toLocaleString('en-US', options)
    }
}

export const formatDateToDayMonth = (dateString: string) => {
    const date = new Date(dateString)
    const day = date.getUTCDate()
    // const month = date.toLocaleString('default', { month: 'long' })
    const month = date.toLocaleString('en', { month: 'long' })
    return `${day} ${month}`
}

export function isPastDate(dateString: string) {
    const inputDate = new Date(dateString)
    const currentDate = new Date()

    // Обрезаем до часов для корректного сравнения
    currentDate.setMinutes(0, 0, 0)
    inputDate.setMinutes(0, 0, 0)

    // Проверяем, является ли inputDate раньше текущего времени
    return inputDate < currentDate
}