export const API_VERSION = 'v1'

export const SERVER_URL_V2 = 'https://flow-788974002465.europe-west4.run.app'
export const API_URL_V2 = `${SERVER_URL_V2}/api`

export const getAuthUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/auth${string}`
export const getThreadsUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/threads${string}`
export const getLabelsUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/labels${string}`
export const getMessagesUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/messages${string}`
export const getLlmUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/llm${string}`
export const getLlmAsyncTasksUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/async-tasks${string}`
export const getTodosUrl = (string = '') => `${API_URL_V2}/${API_VERSION}/todos${string}`
