import { FC } from 'react'
import * as icons from 'lucide-react-native'
import { Pressable, View } from 'react-native'
import { IIconProps } from './icon.interface'

const Icon: FC<IIconProps> = ({
	name,
	color = 'rgba(0, 0, 0, 0)',
	size = 20,
	fill,
	strokeWidth = 1.5,
	onPress,
	className
}) => {
	// @ts-ignore
	const LucideIcon = icons[name]

	return (
		<>
			{!!onPress ? (
				<Pressable
					onPress={onPress}
					className={`${className} text-${color} h-base-[${size}px]`}
					// @ts-ignore
					style={{ userSelect: 'none' }}
				>
					<LucideIcon size={`${size}`} />
				</Pressable>
			) : (
				<View
					className={`${className} text-${color} h-base-[${size}px]`}
					style={{
						//@ts-ignore
						transition: 'all 0.3s ease-out'
					}}
				>
					<LucideIcon
						size={`${size}`}
						strokeWidth={strokeWidth}
						fill={fill || 'none'}
					/>
				</View>
			)}
		</>
	)
}

export default Icon
