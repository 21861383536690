import cn from 'clsx'
import Typography from '../../typography/Typography'
import { StyleProp, StyleSheet, View } from 'react-native'
import { FC, useEffect, useRef } from 'react'
import { useColors } from '@/hooks/useColors'
import { Animated } from 'react-native'
import Dots from './Dots'

interface IProps {
    className?: StyleProp<String>
    text?: string
}

const Loader: FC<IProps> = ({ className, text = 'Loading' }) => {
    const { text: textColor } = useColors()

    return (
        <Typography variant='body' className={cn(textColor.black, className)}>
            {text}
            <Dots />
        </Typography>
    )
}

export default Loader