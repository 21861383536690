import AsyncStorage from '@react-native-async-storage/async-storage'
import {
	EnumStorage,
	IAuthResponse,
} from '@/shared/types/auth.interface'
import { request } from '@/services/api/request.api'
import {
	deleteTokenStorage,
	saveTokenStorage,
	saveToStorage
} from '@/services/auth/auth.helper'
import { getAuthUrl } from '@/config/api.config'
import { headers } from '../api/headers'
import { useStore } from '@/shared/store'

export const AuthService = {
	async login() {
		return request<string>({
            url: getAuthUrl('/login'),
            method: 'POST'
        })
	},

	async getUser(token: string) {
		await saveTokenStorage({ token })

		const responseUser = await request<IAuthResponse>({
            url: getAuthUrl('/me'),
            method: 'GET',
            headers: await headers()
        })

		if (responseUser.data) {
			await saveToStorage(responseUser.data)
			window.history.go(0)
		}
	},

	async logout() {
		await deleteTokenStorage()
		await AsyncStorage.removeItem(EnumStorage.USER)
	}
}
