import { StateCreator } from 'zustand'
import { IDigestTags, ILlmDashboardSlice } from '../types'
import { IDigest, IDigestHighlightItem, IDigestHighlights } from '@/shared/types/dashboard.interface'
import { IThread } from '@/shared/types/threads.interface'
import { checkLabels, getUniqueObjects } from '../helpers'
import {
    readAllSaveInStorage,
    refreshTimeoutGetInStorage,
    refreshTimeoutSaveInStorage
} from '@/services/app.helper'

const defaultTags = {
    key: 'all',
    title: 'All'
}
const tags = ['Work', 'University', 'Required actions', 'Other', 'Item Orange']

export const llmDashboardSlice: StateCreator<ILlmDashboardSlice> = (set, get) => ({
    // * States
    digests: [],
    filteredDigests: [],
    readDigestItemIds: [],
    selectedDigestId: null,

    digestCount: 0,
    generateDigests: false,
    fetchDigests: true,
    digestTags: [],
    selectedDigestTag: 'all',
    selectedDigest: null,

    refreshCount: 10,
    refreshState: 'default',

    detailedCount: 5,
    detailedState: 'default',
    detailedSummary: null,

    loadingAsyncTask: false,
    refreshAsyncTask: false,
    asyncTaskResult: null,
    asyncTaskId: null,

    digestThreads: [],
    currentThreadId: null,
    uniqueThreadIds: [],
    currentThread: 1,

    todoSuggestions: null,
    highlights: null,

    threadDetailedSummary: null,
    threadTodoSuggestions: null,
    threadHighlights: null,

    generateThreadId: null,

    currentPath: null,
    allDigestThreadIds: [],
    allDigestItemsThreadIds: [],
    allDigestThreadRead: null,
    readDigestItems: [],
    refreshTimeout: null,

    composePrompt: {
        prompt: '',
        max_words: 0,
        tonality: 0,
        message_id: null
    },
    generatePromptContent: {
        compose: '',
        chat_dashboard: '',
        chat_inbox: '',
        forward: ''
    },
    noValidPrompt: false,

    // * Actions
    setDigests: (digests) => {
        const digestsList: IDigest[] = digests[0].items.map((item) => {
            item.tags = [tags[Math.floor(Math.random() * tags.length)]]
            return item
        })

        const digestTags: IDigestTags[] = []
        digestsList.forEach((digest) => {
            digest.tags.forEach((tag) => {
                digestTags.push({
                    key: tag.toLowerCase().replace(' ', '_'),
                    title: tag
                })
            })
        })

        const unreadDigests = digestsList.filter((digest) => {
            const unreadMessages = digest.messages.filter((message) => {
                const { UNREAD } = checkLabels(message.labels)

                if (UNREAD) {
                    const threadIds = [...get().allDigestThreadIds, message.thread_id]
                    set(() => ({
                        allDigestThreadIds: [...new Set(threadIds)]
                    }))
                }
                return UNREAD
            })

            return !!unreadMessages.length
        })

        const checkState = (content: number, count: number) =>
            count !== 0 ? (content ? 'generated' : 'default') : 'limited'
        const refreshState = checkState(digestsList.length, get().refreshCount)
        const detailedState = checkState(get().detailedSummary?.length || 0, get().detailedCount)

        return set(() => ({
            digests: unreadDigests,
            filteredDigests: unreadDigests,
            digestCount: unreadDigests.length,
            digestTags: get().allDigestThreadRead ? [] : [defaultTags, ...getUniqueObjects(digestTags)],
            refreshState,
            detailedState
        }))
    },

    setDigestThreads: (digestItemId, threads) => {
        const currentDigest = get().digests.find((digest) => digest.id === digestItemId)
        const threadsIds = [...new Set(currentDigest?.messages.flatMap((message) => message.thread_id))]

        const digestThreads = threads.filter((thread) => threadsIds.includes(thread.id))
        return set(() => ({ digestThreads }))
    },
    setDigestThread: (thread) => {
        return set(() => ({ digestThreads: [thread] }))
    },

    setDigest: (digestItem, threads) => {
        const digestItemDetails = get().digests.filter(
            (digest) => digest.id === digestItem.digest_item_details.digest_item_id
        )[0]

        const threadIds = digestItemDetails.messages.map((message) => message.thread_id)
        const uniqueThreadIds = [...new Set(threadIds)].reverse()

        // const digestHighlights = Object.values(digestItem.highlights)
        const digestThreads: IThread[] = threads.length
            ? threads.filter((thread) => uniqueThreadIds.includes(thread.id))
            : get().digestThreads
        // const digestThreads: IThread[] = digestHighlights.map((highlight) => highlight.thread)

        const readDigestItemIds = [...get().readDigestItemIds, digestItem.digest_item_id]

        return set(() => ({
            digestThreads,
            uniqueThreadIds,
            currentThreadId: uniqueThreadIds[0],
            detailedSummary: digestItem.digest_item_details.content,
            todoSuggestions: digestItem.todo_suggestions,
            highlights: digestItem.highlights,
            readDigestItemIds: [...new Set(readDigestItemIds)]
        }))
    },

    setReadDigestItemIds: (digest_item_id) =>
        set(() => ({
            readDigestItemIds: [...new Set([...get().readDigestItemIds, digest_item_id])]
        })),

    setGeneratedThread: (threadItem) => {
        set(() => ({
            threadDetailedSummary:
                threadItem.message_digest.content ||
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, quaerat. Sunt quasi magni esse sequi ipsum nemo quod ea voluptatum ad. Doloribus, facere. Suscipit incidunt odit dolore totam tempora debitis.',
            threadTodoSuggestions: {
                ...threadItem.todo_suggestions,
                id: threadItem.id
            },
            threadHighlights: threadItem.highlights
        }))

        const checkState = (content: number, count: number) =>
            count !== 0 ? (content ? 'generated' : 'default') : 'limited'
        const detailedState = checkState(get().threadDetailedSummary?.length || 0, get().detailedCount)

        return set(() => ({
            detailedState
        }))
    },
    // addDigestMessage: (message) => {
    //     const digestThreads = get().digestThreads.map((thread) => {
    //         if (thread.id === message.thread_id) {
    //             thread.messages.push(message)
    //         }
    //         return thread
    //     })
    //     return set(() => ({ digestThreads }))
    // },

    refreshDigestThread: (data) => {
        const currentThread = data?.find((item) => item.thread.id === get().currentThreadId)
        const messages = currentThread.thread.messages

        const digestThreads = get().digestThreads.map((thread) => {
            if (thread.id === messages[0].metadata.thread_id) {
                thread.messages = messages
            }
            return thread
        })
        return set(() => ({ digestThreads }))
    },
    markAllDigestsAsRead: () => {
        return set(() => ({
            digests: [],
            filteredDigests: [],
            digestCount: 0,
            digestTags: [],
            refreshState: 'default',
            detailedState: 'default'
        }))
    },

    setReadDigestItems: (readDigestItems) => set(() => ({ readDigestItems })),
    markDigestItemAsRead: (digestId) => {
        const digests = get().digests.filter((digest) => {
            if (digest.id === digestId) {
                const thread_ids = digest.messages.map((message) => {
                    return message.thread_id
                })
                set(() => ({
                    // allDigestThreadIds: [...new Set(thread_ids)],
                    allDigestItemsThreadIds: [...new Set(thread_ids)]
                }))
            }
            return digest.id !== digestId
        })

        const digestTags: IDigestTags[] = []
        digests.forEach((digest) => {
            digest.tags.forEach((tag) => {
                digestTags.push({
                    key: tag.toLowerCase().replace(' ', '_'),
                    title: tag
                })
            })
        })

        !digests.length && readAllSaveInStorage(true)

        const filteredDigests =
            get().selectedDigestTag !== 'all'
                ? digests.filter((digest) => {
                      const tags = digest.tags.filter(
                          (tag) => tag.toLowerCase().replace(' ', '_') === get().selectedDigestTag
                      )

                      return !!tags.length
                  })
                : digests

        return set(() => ({
            digests,
            filteredDigests: filteredDigests.length ? filteredDigests : digests,
            selectedDigestTag: filteredDigests.length ? get().selectedDigestTag : 'all',
            digestCount: digests.length,
            digestTags: !!digests.length ? [defaultTags, ...getUniqueObjects(digestTags)] : [],
            allDigestThreadRead: !digests.length,
            refreshState: !digests.length ? 'default' : get().refreshState
        }))
    },
    setAllDigestThreadRead: (allDigestThreadRead) => set(() => ({ allDigestThreadRead })),
    removeTodoSuggestions: (content, type = 'digest') => {
        const todoSuggestions = type === 'digest' ? get().todoSuggestions! : get().threadTodoSuggestions!
        const removedContents = todoSuggestions.content.filter(
            (todoContent: string) => todoContent !== content
        )

        if (type === 'digest' && todoSuggestions) {
            set(() => ({
                todoSuggestions: {
                    id: get().todoSuggestions!.id,
                    created_at: get().todoSuggestions!.created_at,
                    digest_item_id: get().todoSuggestions!.digest_item_id,
                    user_id: get().todoSuggestions!.user_id,
                    content: removedContents
                }
            }))
        }

        if (type === 'thread' && todoSuggestions) {
            set(() => ({
                threadTodoSuggestions: {
                    id: get().threadTodoSuggestions!.id,
                    content: removedContents
                }
            }))
        }
    },

    setSelectedDigestId: (selectedDigestId) => set(() => ({ selectedDigestId })),

    setGenerateDigests: (generateDigests) => set(() => ({ generateDigests })),
    setSelectedDigestTag: (selectedDigestTag) => {
        const filteredDigests =
            selectedDigestTag !== 'all'
                ? get().digests.filter((digest) => {
                      const tags = digest.tags.filter(
                          (tag) => tag.toLowerCase().replace(' ', '_') === selectedDigestTag
                      )
                      return !!tags.length
                  })
                : get().digests

        set(() => ({ filteredDigests, selectedDigestTag }))
    },
    setSelectedDigest: (selectedDigest) => set(() => ({ selectedDigest })),
    setFetchDigests: (fetchDigests) => set(() => ({ fetchDigests })),

    setRefreshCount: (refreshCount) => {
        set(() => ({ refreshCount }))

        return get().refreshCount !== 0
            ? set(() => ({ refreshState: get().digests.length ? 'generated' : 'default' }))
            : set(() => ({ refreshState: 'limited' }))
    },
    setDetailedCount: (detailedCount) => {
        set(() => ({ detailedCount }))

        return get().detailedCount !== 0
            ? set(() => ({ detailedState: get().detailedSummary?.length ? 'generated' : 'default' }))
            : set(() => ({ detailedState: 'limited' }))
    },

    setLoadingAsyncTask: (loadingAsyncTask) => set(() => ({ loadingAsyncTask })),
    setRefreshAsyncTask: (refreshAsyncTask) => set(() => ({ refreshAsyncTask })),
    setAsyncTasks: (asyncTaskResult) => {
        const digests = asyncTaskResult?.items.length ? asyncTaskResult?.items : get().digests

        const digestsList: IDigest[] = digests.map((item) => {
            item.tags = [tags[Math.floor(Math.random() * tags.length)]]
            return item
        })

        const digestTags: IDigestTags[] = []
        digestsList.forEach((digest) => {
            digest.tags.forEach((tag) => {
                digestTags.push({
                    key: tag.toLowerCase().replace(' ', '_'),
                    title: tag
                })
            })
        })

        const checkState = (count: number) =>
            count !== 0 ? (digests.length ? 'generated' : 'default') : 'limited'
        const refreshState = checkState(get().refreshCount)
        const detailedState = checkState(get().detailedCount)

        return set(() => ({
            digests,
            filteredDigests: digests,
            digestCount: digests.length,
            digestTags: get().allDigestThreadRead ? [] : [defaultTags, ...getUniqueObjects(digestTags)],
            refreshState,
            detailedState,
            asyncTaskResult
        }))
    },
    setAsyncTaskId: (asyncTaskId) => set(() => ({ asyncTaskId })),

    setResetCurrentThreadCount: () => set({ currentThread: 1 }),
    setChangeCurrentThread: (arrowState) => {
        if (get().currentThread !== 0 && get().digestThreads.length !== 0) {
            arrowState === 'next'
                ? set(() => ({
                      currentThread:
                          get().currentThread < get().digestThreads.length
                              ? get().currentThread + 1
                              : get().digestThreads.length
                  }))
                : set(() => ({
                      currentThread: get().currentThread > 1 ? get().digestThreads.length - 1 : 1
                  }))
        }

        set(() => ({ currentThreadId: get().uniqueThreadIds[get().currentThread - 1] }))
    },
    setCurrentPath: (currentPath) => set(() => ({ currentPath })),
    setGenerateThreadId: (generateThreadId) =>
        set(() => ({
            generateThreadId,
            threadDetailedSummary: null,
            threadTodoSuggestions: null,
            threadHighlights: null
        })),

    setRefreshTimeout: async (refreshTimeout) => {
        const timeout = await refreshTimeoutGetInStorage()
        const numericValue = timeout?.match(/\d+/)

        if (numericValue) {
            const parsedValue = parseInt(numericValue[0], 10) > 0 ? parseInt(numericValue[0], 10) - 1 : 0

            if (!refreshTimeout && !parsedValue) {
                set(() => ({ refreshTimeout: 0 }))
                refreshTimeoutSaveInStorage('0')
                return
            }

            if (!refreshTimeout && !!parsedValue) {
                set(() => ({ refreshTimeout: parsedValue }))
                refreshTimeoutSaveInStorage(String(parsedValue))
                return
            }
        }

        refreshTimeoutSaveInStorage(String(refreshTimeout))
        return set(() => ({ refreshTimeout }))
    },
    setComposePrompt: (prompt, field, type) => {
        field === 'prompt' &&
            set(() => ({
                generatePromptContent: {
                    compose: type === 'compose' ? (prompt as string) : get().generatePromptContent.compose,
                    chat_dashboard:
                        type === 'chat_dashboard'
                            ? (prompt as string)
                            : get().generatePromptContent.chat_dashboard,
                    chat_inbox:
                        type === 'chat_inbox' ? (prompt as string) : get().generatePromptContent.chat_inbox,
                    forward: type === 'chat_inbox' ? (prompt as string) : get().generatePromptContent.forward
                }
            }))

        set(() => ({
            composePrompt: {
                ...get().composePrompt,
                [field]: prompt,
                message_id: get().currentThreadId
            }
        }))
    },
    setCurrentThreadId: (currentThreadId) => set(() => ({ currentThreadId })),

    clearComposePrompt: () =>
        set(() => ({
            composePrompt: { ...get().composePrompt, prompt: '' },
            generatePromptContent: {
                compose: '',
                chat_dashboard: '',
                chat_inbox: '',
                forward: ''
            }
        })),
    setValidPrompt: (noValidPrompt) => {
        noValidPrompt && set(() => ({ noValidPrompt }))

        !noValidPrompt &&
            setTimeout(() => {
                set(() => ({ noValidPrompt }))
            }, 3000)
    }
})
