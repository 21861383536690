import cn from 'clsx'
import { useColors } from '@/hooks/useColors';
import { useRef, useEffect } from 'react';
import { Animated } from 'react-native'

const Dots = () => {
    const { bg } = useColors()

    const dots = [
        useRef(new Animated.Value(0)).current,
        useRef(new Animated.Value(0)).current,
        useRef(new Animated.Value(0)).current,
    ]

    useEffect(() => {
        const animateDot = (
            dot: Animated.AnimatedValue | Animated.AnimatedValueXY,
            delay: number
        ) => {
            Animated.loop(
                Animated.sequence([
                Animated.timing(dot, {
                    toValue: 1,
                    duration: 300,
                    delay: delay,
                    useNativeDriver: false,
                }),
                Animated.timing(dot, {
                    toValue: 0,
                    duration: 300,
                    useNativeDriver: false,
                }),
                ])
            ).start();
        };

        
        animateDot(dots[0], 0);
        animateDot(dots[1], 300);
        animateDot(dots[2], 600);
    }, [dots]);

    const translateY = (dotAnimation:any) => dotAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, -4],
    });
    return (
        <>
            {
                dots.map((dot, index) => {
                    return (
                        <Animated.View
                            key={index}
                            className={cn(
                                'w-[2.5px] h-[2.5px] mx-[2.5px] rounded-full',
                                bg.black
                            )}
                            style={[
                                {
                                    transform: [{ translateY: translateY(dot) }]
                                }
                            ]}
                        />
                    )
                })
            }
        </>
    )
}

export default Dots