import { DashboardService } from '@/services/dashboard'
import { useStore } from '@/shared/store'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useApiAsyncTasks } from './useApiAsyncTasks.api'

export const useApiGenerateDigestItem = () => {
    const {
        setLoadingAsyncTask,
        setAsyncTaskId,
        selectedDigestId,
        setSelectedDigestId,
    } = useStore()

    const { fetchApiAsyncTasks } = useApiAsyncTasks()
    
    const [enabled, setEnabled] = useState<boolean>(false)

    const queryResult = useQuery(
        ['get_llm_generate_digest_item'],
        () => DashboardService.getGenerateDigestItem(selectedDigestId!),
        {
            enabled,
            async onSuccess(res) {
                console.log('get_llm_generate_digest_item', res)

                if (res?.data && res.data.id) {
                    setAsyncTaskId(res.data.id)
                    await fetchApiAsyncTasks()
                    setLoadingAsyncTask(true)
                }
            }
        }
    )

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled,])

    const fetchApiGenerateDigestItem = (digestId: number) => {
        setSelectedDigestId(digestId)
        setEnabled(true)
    }

    return {
        fetchApiGenerateDigestItem,
        ...queryResult
    }
}
