import { FC, PropsWithChildren } from 'react'
import { IButtonPlain } from './buttonPlain.interface'
import { Button } from '@/components/ui'
import cn from 'clsx'

const ButtonPlain: FC<PropsWithChildren<IButtonPlain>> = ({
    className,
    classNameText,
    onPress,
    children,
    styleTextOnHover,
}) => {
    return (
        <Button
            variant='button-plain'
            classNameText={cn('text-gray-300', classNameText)}
            onPress={onPress}
            // @ts-ignore
            style={{ width: 'fit-content' }}
            styleTextOnHover={styleTextOnHover}
            className={cn('flex items-center justify-center pl-base-x2 pr-base-x2', className)}>
            {children}
        </Button>
    )
}

export default ButtonPlain





