import { FC } from 'react'
import { View } from 'react-native'
import { Button, Typography } from '@/components/ui'
import { AuthService } from '@/services/auth/auth.service'
import { useAuth } from '@/hooks/useAuth'

interface IProps {
	title: string
}
const Header: FC<IProps> = ({ title }) => {
	const { setUser } = useAuth()

	return (
		<View className='flex-row justify-between'>
			<Typography variant='h3'>{title}</Typography>
			<Button
				className='p-base-x1 pr-base-x2 rounded-base-x2'
				icon='LogOut'
				sizeIcon={20}
				onPress={() => AuthService.logout().then(() => setUser(null))}
				classNameIcon='p-base-x2'
				styleOnHover='bg-gray-100'
				variant='body-list'
			>
				Log out
			</Button>
		</View>
	)
}

export default Header
