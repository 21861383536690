import cn from 'clsx'
import { FC, useState } from 'react'
import { Image, Pressable, View } from 'react-native'
import { Button, Icon, RowActions, RowText, Typography } from '@/components/ui'
import { Pressable as PressableHover } from 'react-native-web-hover'
import { ScivePowerContainer } from '../index'
import { useTypedNavigation } from '@/hooks/useTypedNavigation'
import { useTypedRoute } from '@/hooks/useTypedRoute'
import { useMailPosition } from '@/context/MailPositionContext'
import { TPath } from '@/components/screens/inbox/inbox.interface'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { DataTable } from 'react-native-paper'
import { useTextLayoutHandlers } from '@/utils/replaceText'
import { formattedDate } from '@/shared/libs/hooks'
import { EThreadLabels } from '@/shared/store/enum'
import { useStore } from '@/shared/store'
import { IThread } from '@/shared/types/threads.interface'
import { IUpdateTodo } from '@/shared/types/todo.interface'
import TooltipEl from '@/components/ui/tooltip/Tooltip'


interface IProps {
    id: string
    thread: IThread
    draft: boolean
    isSelected: boolean
    selectThread: () => void
    setSelectedThreads: () => void
    updateThread: (threadId: string, label: string) => void
    removeThread: () => void
    isFirstTread: boolean
    fetchThreadDetails?: () => void
    addTask?: (todo: IUpdateTodo) => void
    showDetail: boolean
    setOpenThreadId: (id: string | null) => void
}

const TableRow: FC<IProps> = ({
    id,
    thread,
    selectThread,
    setSelectedThreads,
    isSelected,
    updateThread,
    removeThread,
    isFirstTread,
    draft,
    fetchThreadDetails,
    addTask,
    showDetail,
    setOpenThreadId
}) => {
    const { colorScheme } = useColorScheme()
    const { position } = useMailPosition()
    const { bg, color, text: textColor } = useColors()
    const { params } = useTypedRoute<'Inbox'>()
    const { navigate } = useTypedNavigation()
    const [visibleScivePowerContainer, setVisibleScivePowerContainer] = useState(false)
    const { setChatType, filteredThreadsTabCurrent, currentPath } = useStore()
    const [isHover, setIsHover] = useState(false)

    const isActive = params?.id === id
    const isNew = filteredThreadsTabCurrent === 'new'
    const unread = thread.messages[0].metadata.labels.includes(EThreadLabels.UNREAD)
    const important = thread.messages[0].metadata.labels.includes(EThreadLabels.IMPORTANT)

    const [_, setIsTextOverflowed] = useState(false)
    const { handleContainerLayout, handleTextLayout } = useTextLayoutHandlers(setIsTextOverflowed)

    const fileNames = Array(0).fill('file_name_example')
    const draftLength = Array(0).fill('fille_name_example')

    const tooltipContent = important
        ? 'Click to teach Scive this conversation is not important'
        : 'Click to teach Scive this conversation is important'

    return (
        <PressableHover
            onMouseLeave={() => setIsHover(false)}
            onMouseEnter={() => setIsHover(true)}
            // @ts-ignore
            style={{ zIndex: isFirstTread && isHover ? 1 : -1, transition: 'all 0.3s ease-out' }}
            onPress={() => {
                unread && updateThread(id, EThreadLabels.UNREAD)
                setChatType('chat_inbox')
                selectThread()
                navigate('Inbox', {
                    path: params?.path as TPath,
                    id: id
                })
            }}>
            {({ hovered }) => (
                <View
                    // @ts-ignore
                    className='flex-col'>
                    <DataTable
                        className={cn(
                            hovered && bg.hoverIconSurface,
                            (visibleScivePowerContainer || isActive) && bg.selectedMailInList,
                            (visibleScivePowerContainer || isActive) && hovered && bg.outcomingMessage
                        )}>
                        <DataTable.Row
                            className={cn('relative pt-base-x1 pb-base-x1 flex-row justify-between')}
                            style={{ borderWidth: 0 }}>
                            {!isNew && (
                                <DataTable.Cell style={{ maxWidth: 36, marginRight: 8 }}>
                                    <Button
                                        icon={isSelected ? 'CheckSquare' : 'Square'}
                                        sizeIcon={20}
                                        colorIcon={color.black}
                                        onPress={setSelectedThreads}
                                    />
                                </DataTable.Cell>
                            )}

                            {isNew && hovered && (
                                <DataTable.Cell style={{ maxWidth: 71 }}>
                                    <RowActions
                                        threadId={id}
                                        visibleScive={setVisibleScivePowerContainer}
                                        isVisible={visibleScivePowerContainer}
                                        updateThread={updateThread}
                                        removeThread={removeThread}
                                        category={'draft'}
                                        isNew={isNew}
                                        isFirstTread={isFirstTread}
                                        fetchThreadDetails={fetchThreadDetails}
                                        setOpenThreadId={() => setOpenThreadId(id)}
                                    />
                                </DataTable.Cell>
                            )}
                            <DataTable.Cell style={{ maxWidth: 36, gap: 8 }}>
                                <Pressable onPress={() => updateThread(id, EThreadLabels.IMPORTANT)}>
                                    <TooltipEl placement='top-start' title={tooltipContent}>
                                        <View>
                                            <Image
                                                source={require('@/assets/icons/important.svg')}
                                                tintColor={
                                                    important
                                                        ? '#FDBA74'
                                                        : colorScheme === 'dark'
                                                        ? '#374151'
                                                        : ''
                                                }
                                                style={{ width: 15, height: 14 }}
                                            />
                                        </View>
                                    </TooltipEl>
                                </Pressable>
                            </DataTable.Cell>
                            {!!thread.messages[0].metadata.from_.length && (
                                <DataTable.Cell
                                    onLayout={handleContainerLayout}
                                    style={{
                                        marginRight: 20,
                                        maxWidth: 150
                                    }}>
                                    <Typography
                                        numberOfLines={1}
                                        onLayout={handleTextLayout}
                                        // @ts-ignore
                                        style={{
                                            width: 'fit-content'
                                        }}
                                        ellipsizeMode='tail'
                                        variant={!unread ? 'body' : 'label-date-bold'}
                                        nowrap={true}
                                        className={cn(textColor.black, 'text-base-h4')}>
                                        {thread.messages[0].metadata.from_}
                                    </Typography>
                                </DataTable.Cell>
                            )}
                            {draft === true && (
                                <DataTable.Cell className='max-w-[42px] mr-1'>
                                    <Typography
                                        variant={'body'}
                                        className='text-error font-bold  desktop:text-base-h4 w-[42px]'>
                                        Draft:
                                    </Typography>
                                </DataTable.Cell>
                            )}
                            {draftLength.length > 2 && (
                                <DataTable.Cell className='max-w-[20px]'>
                                    <View className='flex-row ' style={{ gap: 8 }}>
                                        <Typography
                                            variant='body'
                                            className='max-w-[20px] flex-row text-base-body2'>
                                            {draftLength.length - 2}
                                        </Typography>
                                    </View>
                                </DataTable.Cell>
                            )}
                            {draft === true && !thread.messages[0].metadata.from_.length && (
                                <DataTable.Cell className='max-w-[50px] mr-2'>
                                    <Typography
                                        className='w-[50px] h-[20px] flex justify-center items-center bg-gray-100 border-transparent  px-3  rounded-[64px] text-base-body5 text-gray-500'
                                        variant={'body-small'}>
                                        Inbox
                                    </Typography>
                                </DataTable.Cell>
                            )}
                            <DataTable.Cell
                                onLayout={handleContainerLayout}
                                style={{
                                    padding: 0,
                                    marginRight: 8
                                }}>
                                <Typography
                                    numberOfLines={1}
                                    onLayout={handleTextLayout}
                                    // @ts-ignore
                                    style={{
                                        width: 'fit-content'
                                    }}
                                    ellipsizeMode='tail'
                                    variant={!unread ? 'body' : 'label-date-bold'}
                                    nowrap={true}
                                    className={cn(textColor.black, 'text-base-h4')}>
                                    {thread.messages[0].metadata.subject}
                                </Typography>
                                <> - </>
                                <Typography
                                    numberOfLines={1}
                                    onLayout={handleTextLayout}
                                    // @ts-ignore
                                    style={{
                                        width: 'fit-content'
                                    }}
                                    ellipsizeMode='tail'
                                    variant={!unread ? 'body' : 'body'}
                                    nowrap={true}
                                    className={textColor.black}>
                                    {thread.messages[0].metadata.snippet}
                                </Typography>
                            </DataTable.Cell>
                            {!isNew && hovered ? (
                                <DataTable.Cell style={{ maxWidth: 120 }}>
                                    <RowActions
                                        threadId={id}
                                        visibleScive={setVisibleScivePowerContainer}
                                        isVisible={visibleScivePowerContainer}
                                        updateThread={updateThread}
                                        removeThread={removeThread}
                                        category={'draft'}
                                        isNew={false}
                                        isFirstTread={isFirstTread}
                                        fetchThreadDetails={fetchThreadDetails}
                                        setOpenThreadId={() => setOpenThreadId(id)}
                                    />
                                </DataTable.Cell>
                            ) : (
                                <>
                                    <DataTable.Cell style={{ maxWidth: 50 }}>
                                        <RowText
                                            className='pt-base-x1 pb-base-x1'
                                            type='date'
                                            text={
                                                !!thread.messages[thread.messages.length - 1].metadata
                                                    .created_at
                                                    ? formattedDate(
                                                          thread.messages[thread.messages.length - 1].metadata
                                                              .created_at as string
                                                      )
                                                    : undefined
                                            }
                                            read={!unread}
                                            draft={draft}
                                            vertical={position === 'vertical'}
                                        />
                                    </DataTable.Cell>
                                </>
                            )}
                        </DataTable.Row>
                        {!!fileNames.length && (
                            <DataTable.Row>
                                <DataTable.Cell style={{ maxWidth: 10 }}>
                                    <View style={{ backgroundColor: 'red', maxWidth: 10 }}></View>
                                </DataTable.Cell>
                                {!!fileNames.length &&
                                    fileNames.slice(0, 2).map((name, idx) => (
                                        <DataTable.Cell key={idx} className='max-w-[177px]'>
                                            <View
                                                className='flex-row border rounded-full px-3 py-1 border-devider'
                                                style={{ gap: 8 }}>
                                                <View className='w-6 h-6 flex justify-center items-center rounded bg-devider'>
                                                    <Icon name='File' size={16} strokeWidth={2} fill='' />
                                                </View>
                                                <Typography
                                                    numberOfLines={1}
                                                    onLayout={handleTextLayout}
                                                    variant='body'
                                                    className='max-w-[121px] text-base-body2'
                                                    // @ts-ignore
                                                    style={{
                                                        width: 'fit-content'
                                                    }}
                                                    ellipsizeMode='tail'
                                                    nowrap={true}>
                                                    {name}
                                                </Typography>
                                            </View>
                                        </DataTable.Cell>
                                    ))}
                                {fileNames.length > 2 && (
                                    <DataTable.Cell className='max-w-[177px]'>
                                        <View
                                            className='flex-row border rounded-full px-3 py-1 border-devider'
                                            style={{ gap: 8 }}>
                                            <Typography
                                                variant='body'
                                                className='max-w-[121px] text-base-body2'>
                                                +{fileNames.length - 2}
                                            </Typography>
                                        </View>
                                    </DataTable.Cell>
                                )}
                            </DataTable.Row>
                        )}
                    </DataTable>
                    {visibleScivePowerContainer && showDetail && (
                        <ScivePowerContainer currentPath={currentPath} addTask={addTask} />
                    )}
                </View>
            )}
        </PressableHover>
    )
}

export default TableRow
