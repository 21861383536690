import { DashboardService } from '@/services/dashboard'
import { useStore } from '@/shared/store'
import { IComposeType } from '@/shared/store/types'
import { IGenerateComposeResult, IGenerateDigestItemResult, IGenerateDigestResult } from '@/shared/types/dashboard.interface'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import Toast from 'react-native-toast-message'

export const useApiAsyncTasks = () => {
    const {
        setFetchDigests,
        setGenerateDigests,
        setAsyncTasks,
        asyncTaskId,
        setDigest,
        setLoadingAsyncTask,
        setRefreshAsyncTask,
        setGeneratedThread,
        setSendMessageForm,
        chatType,
        threads,
    } = useStore()

    const [enabled, setEnabled] = useState<boolean>(false)

    const queryResult = useQuery(['get_llm_async_tasks'], () => DashboardService.getAsyncTask(asyncTaskId!), {
        enabled,
        onSuccess(res) {
            if (res.data?.status === 'SUCCESS') {
                console.log('get_llm_async_tasks', res)
                setEnabled(false)
                setGenerateDigests(false)

                if (res.data?.name === 'generate_compose_suggestion') {
                    setLoadingAsyncTask(false)
                    if(res.data?.result) {
                        const { content } = res.data?.result as IGenerateComposeResult
                        setSendMessageForm('text', content, chatType as IComposeType)
                    }
                }
                if (res.data?.name === 'generate_scive_power_item') {
                    setLoadingAsyncTask(false)
                    setGeneratedThread(res.data.result as IGenerateDigestItemResult)
                }

                if (res.data?.name === 'generate_digest_item_details') {
                    setLoadingAsyncTask(false)
                    setDigest(res.data.result as IGenerateDigestItemResult, threads)
                }

                if (res.data?.name === 'generate_digest') {
                    setRefreshAsyncTask(false)
                    setFetchDigests(true)
                    setAsyncTasks(res.data.result as IGenerateDigestResult)
                }
            }
            if (res.data?.status === 'PENDING') {
                setEnabled(true)
            }
            if (res.data?.status === 'ERROR') {
                setGenerateDigests(true)
                setEnabled(false)
                setLoadingAsyncTask(false)
                setRefreshAsyncTask(false)
                Toast.show({
                    type: 'error',
                    text1: 'Somethig wrong'
                })
            }
        }
    })

    useEffect(() => {
        if (asyncTaskId && enabled) {
            fetchApiAsyncTasks()
        }
    }, [asyncTaskId])

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])

    const fetchApiAsyncTasks = () => {
        setEnabled(true)
    }

    return {
        fetchApiAsyncTasks,
        ...queryResult
    }
}
