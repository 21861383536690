import { getTodosUrl } from '@/config/api.config'
import { request } from '../api/request.api'
import { headers } from '../api/headers'
import {
    ITodoResponse,
    IUpdateTodo
} from '@/shared/types/todo.interface'

export const TodosService = {
    async getTodos() {
        return request<ITodoResponse>({
            url: getTodosUrl('/'),
            method: 'GET',
            headers: await headers()
        })
    },

    async addTodo(data: IUpdateTodo | null) {
        if (!data) return

        return request<ITodoResponse>({
            url: getTodosUrl('/'),
            method: 'POST',
            headers: await headers(),
            data: { ...data }
        })
    },

    async getTodo(todoId: string | null) {
        if (!todoId) return

        return request<ITodoResponse>({
            url: getTodosUrl(`/${todoId}`),
            method: 'GET',
            headers: await headers()
        })
    },

    async updateTodo(todoId: number | null, data: IUpdateTodo | null) {
        if (!todoId && !data) return

        return request<ITodoResponse>({
            url: getTodosUrl(`/${todoId}`),
            method: 'PUT',
            headers: await headers(),
            data: { ...data }
        })
    },

    async removeTodo(todoId: number | null) {
        if (!todoId) return

        return request<string>({
            url: getTodosUrl(`/${todoId}`),
            method: 'DELETE',
            headers: await headers()
        })
    }
}
