import { FC, MutableRefObject, useEffect, useRef } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { Body, Button, Header, Loader, Typography } from '@/components/ui'
import { StyledProps, useColorScheme } from 'nativewind'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'
import {
    useApiGenerateDigestItem,
    useApiGenerateDigests,
    useApiGetDigestList
} from '@/components/screens/dashboard/api'
import { useStore } from '@/shared/store'
import { CircleCheckBig } from 'lucide-react'
import { useApiUpdateThreadLabels } from '@/components/screens/inbox/api'
import { refreshTimeoutGetInStorage } from '@/services/app.helper'

interface IProps {
    className?: StyledProps<string>
}

const ExecutiveSummary: FC<IProps> = ({ className }) => {
    const { text } = useColors()

    // API
    const { fetchApiGenerateDigests } = useApiGenerateDigests()
    const { isFetching } = useApiGetDigestList()
    const { fetchApiGenerateDigestItem } = useApiGenerateDigestItem()
    const { readAll } = useApiUpdateThreadLabels()

    // State
    const {
        digestTags,
        filteredDigests,
        digestCount,
        refreshCount,
        setRefreshCount,
        refreshState,
        selectedDigestTag,
        setSelectedDigestTag,
        setRefreshAsyncTask,
        refreshAsyncTask,
        allDigestThreadIds,
        refreshTimeout,
        setRefreshTimeout
    } = useStore()

    const fetchDigestList = () => {
        if (refreshCount !== 0) {
            fetchApiGenerateDigests()
            setRefreshAsyncTask(true)
            refreshCount > 0 ? setRefreshCount(refreshCount - 1) : setRefreshCount(0)
        }
    }

    const handleScrollWheel = (event: { deltaY: any }, scrollViewRef: MutableRefObject<null>) => {
        if (scrollViewRef.current) {
            //@ts-ignore
            scrollViewRef.current.scrollTo({
                //@ts-ignore
                x: scrollViewRef.current.getScrollableNode().scrollLeft + event.deltaY,
                animated: true
            })
        }
    }
    const scrollViewRef = useRef(null)

    useEffect(() => {
        setRefreshTimeout(0)
    }, [])

    useEffect(() => {
        if (refreshTimeout === 0) return

        const intervalId = setInterval(() => {
            refreshTimeoutGetInStorage().then((res) => {
                if (typeof res === 'string') {
                    const numericValue = res.match(/\d+/)
                    if (numericValue) {
                        const parsedValue = parseInt(numericValue[0], 10)
                        setRefreshTimeout(parsedValue - 1)
                    }
                }
            })
        }, 1000)

        return () => clearInterval(intervalId)
    }, [refreshTimeout])

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
    }

    const showTags = !isFetching && refreshCount !== 0 && !refreshAsyncTask

    const refreshSubmit = () => {
        if (!refreshTimeout) {
            fetchDigestList()
            setRefreshTimeout(150)
        }
    }

    return (
        <View
            className={cn(
                'pt-base-x5 pb-base-x2 pl-base-x2 justify-between overflow-hidden',

                className
            )}>
            <Header title='Scive Digest AI' subtitle='' counter={{ count: digestCount, subject: 'new' }} />
            {showTags && (
                <View
                    className='flex flex-row pt-4 pb-4 ml-6 mr-6 w-auto overflow-hidden'
                    //@ts-ignore
                    onWheel={(event) => handleScrollWheel(event, scrollViewRef)}>
                    <ScrollView
                        ref={scrollViewRef}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        // @ts-ignore
                        contentContainerStyle={{ gap: 6, flexWrap: 'wrap', width: 'fit-content' }}>
                        {digestTags.map((tag) => {
                            const selected = selectedDigestTag === tag.key
                            return (
                                <TouchableOpacity
                                    key={tag.key}
                                    activeOpacity={1}
                                    className={cn(
                                        'py-0.5 px-2 rounded-lg bg-white',
                                        // 'py-1 px-4 rounded-lg bg-white border-l-8 border-gray-300',
                                        {
                                            'bg-black': selected
                                            // 'bg-black border-l-0': selected
                                        }
                                    )}
                                    onPress={() => setSelectedDigestTag(tag.key)}>
                                    <Typography
                                        variant='body'
                                        className={cn('text-base-body leading-7 ', {
                                            'text-white': selected
                                        })}>
                                        {tag.title}
                                    </Typography>
                                </TouchableOpacity>
                            )
                        })}
                    </ScrollView>
                </View>
            )}
            {!refreshAsyncTask ? (
                !isFetching ? (
                    <Body
                        marginTop={!showTags}
                        body={filteredDigests}
                        selectMessage={fetchApiGenerateDigestItem}
                        placeholder='Hello! Here you will see an Executive Summary of your new emails. Click on the number to view the message each part refers to.'
                        status={refreshState}
                    />
                ) : (
                    <Body>
                        <Loader text='Fetching digests list' />
                    </Body>
                )
            ) : (
                <Body>
                    <Loader text='Refresh digests list' />
                </Body>
            )}
            <View
                className='self-end flex-row items-center gap-x-base-x4 pt-base-x8 pb-3  m-auto'
                style={{ gap: 16 }}>
                <Button
                    className='laptop:min-w-[160px] desktop:min-w-[180px]  py-base-x4 px-base-x2  w-full h-[36px] flex flex-row justify-center items-center border-2 border-sky rounded-base-x3 text-base-body leading-[19px]'
                    classNameText={cn(text.sky, 'flex items-center')}
                    variant='button-plain'
                    styleTextOnHover='text-button-hover'
                    styleOnHover='border-button-hover'
                    noMargin
                    onPress={() => readAll(allDigestThreadIds)}>
                    <CircleCheckBig size={20} stroke='black' style={{ marginRight: 4 }} />
                    Mark all as done
                </Button>
                <Button
                    className={cn(
                        'laptop:min-w-[160px] desktop:min-w-[180px] py-base-x4 px-base-x2  w-full h-[36px] flex flex-row justify-center items-center rounded-base-x3 text-base-body leading-[19px]',
                        refreshTimeout !== 0 ? 'bg-sky-400' : 'bg-button'
                    )}
                    classNameText={cn(refreshCount ? text.white : 'text-red', 'ml-1')}
                    variant='button-plain'
                    icon={refreshTimeout === 0 ? 'Wand2' : undefined}
                    noMargin
                    sizeIcon={20}
                    styleOnHover='bg-button-hover'
                    strokeWidthIcon={2}
                    classNameIcon='p-base-x1'
                    colorIcon={refreshCount ? 'black' : 'red'}
                    onPress={refreshSubmit}>
                    {refreshTimeout !== 0 ? `Timeout ${formatTime(refreshTimeout || 0)}` : `Generate new`}
                </Button>
            </View>
        </View>
    )
}

export default ExecutiveSummary
