import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { useTypedRoute } from '@/hooks/useTypedRoute'
import { ComposePopup, MailHeader, MailList, TableList } from './components'
import { ScrollView, View } from 'react-native'
import { useMailPosition } from '@/context/MailPositionContext'
import { Navbar } from '@/components/ui'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'
import { useApiGetThread, useApiGetThreads } from './api'
import { useStore } from '@/shared/store'
import { throttle } from 'lodash'

const Inbox: FC = () => {
    const scrollRef = useRef<ScrollView | null>(null)
    const { position } = useMailPosition()
    const { params } = useTypedRoute<'Inbox'>()
    const [sections, setSections] = useState<ReactNode>([])

    const { isLoading, fetchThreads, fetchMoreThreads } = useApiGetThreads()
    const { isLoading: isLoadingThread, selectedThreadId } = useApiGetThread()
    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    const {
        countInboxUnread,
        countSpamUnread,
        filteredThreads,
        foundThreads,
        searchThreadValue,
        refetchThreads,
        loadingNextPage,
        setLoadingNextPage,
        nextTokenPage
    } = useStore()

    const handleScroll = ({ nativeEvent }: { nativeEvent: any }) => {
        const { layoutMeasurement, contentOffset, contentSize } = nativeEvent
        if (layoutMeasurement.height + contentOffset.y >= contentSize.height - 200 && !loadingNextPage) {
            if (nextTokenPage) {
                fetchMoreThreads(nextTokenPage as string)
            }
        }
    }
    useEffect(() => {
        refetchThreads && fetchThreads()

        if (params?.path === undefined) {
            setSections(
                <TableList
                    threads={
                        !!foundThreads.length || !!searchThreadValue.length ? foundThreads : filteredThreads
                    }
                    isLoading={isLoading}
                    category={'new'}
                    openThreadId={null}
                    setOpenThreadId={null}
                    handleScroll={handleScroll}
                    scrollRef={scrollRef}
                />
            )
        } else {
            setSections(
                <TableList
                    threads={
                        !!foundThreads.length || !!searchThreadValue.length ? foundThreads : filteredThreads
                    }
                    isLoading={isLoading}
                    category={'new'}
                    openThreadId={null}
                    setOpenThreadId={null}
                    handleScroll={handleScroll}
                    scrollRef={scrollRef}
                />
            )
        }
    }, [params?.path, filteredThreads, foundThreads, searchThreadValue])

    const navbar = [
        { icon: 'BellDot', title: 'New', path: undefined, countUnread: countInboxUnread },
        { icon: 'Mail', title: 'Inbox', path: 'inbox', countUnread: countInboxUnread },
        { icon: 'Send', title: 'Sent', path: 'sent' },
        { icon: 'File', title: 'Drafts', path: 'drafts' },
        {
            icon: 'AlertOctagon',
            title: 'Spam',
            path: 'spam',
            countUnread: countSpamUnread,
            isRedCounter: true
        },
        { icon: 'Trash2', title: 'Trash', path: 'trash' }
    ]
    return (
        <View className='flex-row flex-1' style={{ gap: 4 }}>
            {position === 'horizontal' && !isLoadingThread && !!selectedThreadId ? null : (
                <Navbar navbar={navbar} />
            )}
            <View
                className={cn(
                    'flex-1 relative z-20 px-base-x1 pt-base-x3 pb-base-x6 border-l-[1px]',
                    deviderColor
                )}>
                <MailHeader />
                <MailList sections={sections} isLoading={!isLoadingThread && !!selectedThreadId} />
                <ComposePopup />
            </View>
        </View>
    )
}

export default Inbox
