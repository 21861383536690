import { StateCreator } from 'zustand'
import { ITodosSlice } from '../types'
import { getDate, isPastDate, useDate } from '@/shared/libs/hooks'
import {
    countTodosTab,
    filterByCategory,
    filterTodos,
    filterWithoutCategory,
    sortByCompleted,
    updateItem
} from '../helpers'
import { ETodoDropdownFilters, ITodo } from '@/shared/types/todo.interface'

export const todosSlice: StateCreator<ITodosSlice> = (set, get) => ({
    todos: [],
    todosTab: [],
    foundTodos: [],
    subtasks: [],
    foundTodosTab: [],
    filteredTodos: [],
    filteredTodosTab: [],
    filteredTodosTabCurrent: null,
    filterBy: 'new',
    refetchTodos: true,
    noValidTodoText: false,
    noValidTodoDetail: false,
    selectedTodoId: null,
    addTodoFormType: null,
    countTodosTab: {
        today: 0,
        tomorrow: 0,
        important: 0,
        all: 0,
        completed: 0
    },
    todo: {},
    searchValue: '',
    searchValueTab: '',
    sectionsTodosTab: {
        tomorrow: [],
        today: []
    },
    changeTodoType: null,

    selectedByDropdownTodo: null,
    selectedTodos: [],

    // ACTIONS
    validateTodo: (valid, detail = false) =>
        set(() => ({ [!detail ? 'noValidTodoText' : 'noValidTodoDetail']: valid })),
    getTodo: (id) => {
        const todo = get().todos.filter((todo) => todo.id === id)[0]

        const subtasks =
            todo.subtasks &&
            todo.subtasks.map((item) => ({
                ...item,
                date: useDate(item.due) || ''
            }))

        return set(() => ({
            todo: todo || {},
            subtasks: [...sortByCompleted(subtasks)],
            selectedTodoId: todo.id
        }))
    },

    setTodos: (todos) => {
        const testDue = [
            null,
            '2024-10-09T00:00:00.000Z',
            '2024-10-08T00:00:00.000Z',
            '2024-10-10T00:00:00.000Z',
            '2024-10-09T00:00:00.000Z',
            '2024-10-11T00:00:00.000Z'
        ]
        const updateTodos = todos.map((todo, idx) => ({
            ...todo,
            date: testDue[idx] ? useDate(testDue[idx]) : '',
            // date: todo.due ? useDate(todo.due) : '',
            due: testDue[idx], // TODO - clear
            sub_content: '', // TODO - clear
            is_favorite: false, // TODO - clear
            parent_id: null, // TODO - clear
            subtasks: [] // TODO - clear
        }))

        const filteredTodos = filterTodos[get().filterBy](updateTodos).reverse()
        let todosTab = filterByCategory(updateTodos, get().filteredTodosTabCurrent)

        if (get().filteredTodosTabCurrent === 'all') {
            const tomorrow = filterByCategory(updateTodos, 'tomorrow')
            const today = filterByCategory(updateTodos, 'today')

            todosTab = filterWithoutCategory(updateTodos)

            set(() => ({
                sectionsTodosTab: {
                    tomorrow: [...sortByCompleted(tomorrow)],
                    today: [...sortByCompleted(today)]
                }
            }))
        }

        return set(() => ({
            todos: [...sortByCompleted(updateTodos)].reverse(),
            todosTab: [...sortByCompleted(todosTab)],
            filteredTodos: [...sortByCompleted(filteredTodos)],
            countTodosTab: countTodosTab(updateTodos)
        }))
    },
    addTodo: (todo) => {
        const updateTodo = {
            ...todo,
            date: todo.due ? useDate(todo.due) : '',
            due: null, // TODO - clear
            sub_content: '', // TODO - clear
            is_favorite: false, // TODO - clear
            parent_id: null, // TODO - clear
            subtasks: [] // TODO - clear
        }

        if (!todo.parent_id) {
            let todosTab = filterByCategory([updateTodo, ...get().todos], get().filteredTodosTabCurrent)

            if (get().filteredTodosTabCurrent === 'all') {
                let tomorrow = filterByCategory(get().todos, 'tomorrow')
                let today = filterByCategory(get().todos, 'today')

                if (get().searchTodosTab.length) {
                    tomorrow = get().sectionsTodosTab.tomorrow.filter((item) =>
                        updateItem(updateTodo.id, item, todo)
                    )
                    today = get().sectionsTodosTab.today.filter((item) =>
                        updateItem(updateTodo.id, item, todo)
                    )
                }

                todosTab = filterWithoutCategory([updateTodo, ...get().todos])
                if (updateTodo.date.toLowerCase() === 'tomorrow') {
                    set(() => ({
                        sectionsTodosTab: {
                            ...get().sectionsTodosTab,
                            tomorrow: [updateTodo, ...sortByCompleted(tomorrow)]
                        }
                    }))
                }
                if (updateTodo.date.toLowerCase() === 'today') {
                    set(() => ({
                        sectionsTodosTab: {
                            ...get().sectionsTodosTab,
                            today: [updateTodo, ...sortByCompleted(today)]
                        }
                    }))
                }
            }

            return set(() => ({
                todos: [updateTodo, ...get().todos],
                todosTab: [...sortByCompleted(todosTab)],
                filteredTodos: [updateTodo, ...get().filteredTodos],
                countTodosTab: countTodosTab([updateTodo, ...get().todos])
            }))
        }

        return set(() => ({
            subtasks: [updateTodo, ...get().subtasks]
        }))
    },
    setAddTodoFormType: (type) => set(() => ({ addTodoFormType: type })),

    updateTodo: (id, todo) => {
        const todos = get().todos.map((item) => updateItem(id, item, todo))
        const foundTodos = get().foundTodos.map((item) => updateItem(id, item, todo))
        const foundTodosTab = get().foundTodosTab.map((item) => updateItem(id, item, todo))
        const subtasks = get().subtasks.map((item) => updateItem(id, item, todo))
        const filteredTodos = filterTodos[get().filterBy](todos)

        let todosTab = filterByCategory(todos, get().filteredTodosTabCurrent)

        if (get().filteredTodosTabCurrent === 'all') {
            let tomorrow = filterByCategory(get().todos, 'tomorrow')
            let today = filterByCategory(get().todos, 'today')

            if (get().searchTodosTab.length) {
                tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => updateItem(id, item, todo))
                today = get().sectionsTodosTab.today.filter((item) => updateItem(id, item, todo))
            }

            todosTab = filterWithoutCategory(todos)

            set(() => ({
                sectionsTodosTab: {
                    tomorrow: [...sortByCompleted(tomorrow)],
                    today: [...sortByCompleted(today)]
                }
            }))
        }

        // Filter for Dashboard
        return set(() => ({
            todos: [...sortByCompleted(todos)],
            todosTab: [...sortByCompleted(todosTab)],
            subtasks: [...sortByCompleted(subtasks)],
            filteredTodos: [...sortByCompleted(filteredTodos)],
            foundTodos: [...sortByCompleted(foundTodos)],
            foundTodosTab: [...sortByCompleted(foundTodosTab)],
            countTodosTab: countTodosTab(todos)
        }))
    },

    removeTodo: (id) => {
        const todos = get().todos.filter((item) => item.id !== id)
        const todosTab = get().todosTab.filter((item) => item.id !== id)
        let foundTodosTab = get().todosTab.filter((item) => item.id !== id)
        let subtasks = get().subtasks.filter((item) => item.id !== id)

        if (get().searchTodosTab.length) {
            foundTodosTab = get().foundTodosTab.filter((item) => item.id !== id)
        }
        if (get().filteredTodosTabCurrent === 'all') {
            let tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => item.id !== id)
            let today = get().sectionsTodosTab.today.filter((item) => item.id !== id)

            set(() => ({
                sectionsTodosTab: {
                    tomorrow: [...sortByCompleted(tomorrow)],
                    today: [...sortByCompleted(today)]
                }
            }))
        }

        return set(() => ({
            todos: [...sortByCompleted(todos)],
            todosTab: [...sortByCompleted(todosTab)],
            foundTodosTab: [...sortByCompleted(foundTodosTab)],
            subtasks: [...sortByCompleted(subtasks)],
            countTodosTab: countTodosTab(todos)
        }))
    },

    searchTodos: (input) => {
        set(() => ({ searchValue: input }))
        const foundTodos = get().todos.filter(
            (item) =>
                item.content.toLowerCase().includes(get().searchValue.toLowerCase()) ||
                item.sub_content.toLowerCase().includes(get().searchValue.toLowerCase())
        )
        return set(() => ({
            foundTodos: [...sortByCompleted(foundTodos)]
        }))
    },

    searchTodosTab: (input) => {
        set(() => ({ searchValueTab: input }))
        const search = (item: ITodo) =>
            item.content.toLowerCase().includes(get().searchValue.toLowerCase()) ||
            item.sub_content.toLowerCase().includes(get().searchValue.toLowerCase())

        const foundTodosTab = get().todosTab.filter((item) => search(item))

        if (get().filteredTodosTabCurrent === 'all') {
            const foundTomorrow = get().sectionsTodosTab.tomorrow.filter((item) => search(item))
            const foundToday = get().sectionsTodosTab.today.filter((item) => search(item))

            set(() => ({
                sectionsTodosTab: {
                    tomorrow: [...sortByCompleted(foundTomorrow)],
                    today: [...sortByCompleted(foundToday)]
                }
            }))
        }

        return set(() => ({
            foundTodosTab: [...sortByCompleted(foundTodosTab)]
        }))
    },

    filterTodos: (filter) => {
        set(() => ({ filterBy: filter }))
        const filteredTodos = filterTodos[filter](get().todos)
        return set(() => ({
            filteredTodos: [...sortByCompleted(filteredTodos)]
        }))
    },

    setTodosTabCategory: (category) => {
        set(() => ({ filteredTodosTabCurrent: category || 'today' }))

        let todosTab = filterByCategory(get().todos, get().filteredTodosTabCurrent)
        const tomorrow = filterByCategory(get().todos, 'tomorrow')
        const today = filterByCategory(get().todos, 'today')

        if (get().filteredTodosTabCurrent === 'all') {
            todosTab = filterWithoutCategory(get().todos)
        }
        return set(() => ({
            todosTab: [...sortByCompleted(todosTab)],
            sectionsTodosTab: {
                tomorrow: [...sortByCompleted(tomorrow)],
                today: [...sortByCompleted(today)]
            },
            searchValueTab: ''
        }))
    },

    changeTodos: (type) => {
        set(() => ({ changeTodoType: type }))

        if (type === 'today') {
            const changeDate = (item: ITodo) => {
                ;(item.due = getDate()), (item.date = useDate(getDate()))
                return item
            }
            const todosTab = get().todosTab.filter((item) => changeDate(item))
            const foundTodosTab = get().foundTodosTab.filter((item) => changeDate(item))

            if (get().filteredTodosTabCurrent === 'all') {
                let tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => changeDate(item))
                let today = get().sectionsTodosTab.today.filter((item) => changeDate(item))

                if (get().searchTodosTab.length) {
                    tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => changeDate(item))
                    today = get().sectionsTodosTab.today.filter((item) => changeDate(item))
                }

                set(() => ({
                    sectionsTodosTab: {
                        tomorrow: [...sortByCompleted(tomorrow)],
                        today: [...sortByCompleted(today)]
                    }
                }))
            }

            return set(() => ({
                todosTab: [...sortByCompleted(todosTab)],
                foundTodosTab: [...sortByCompleted(foundTodosTab)]
            }))
        }
        if (type === 'tomorrow') {
            const changeDate = (item: ITodo) => {
                ;(item.due = getDate(true)), (item.date = useDate(getDate(true)))
                return item
            }
            const todosTab = get().todosTab.filter((item) => changeDate(item))
            const foundTodosTab = get().foundTodosTab.filter((item) => changeDate(item))

            if (get().filteredTodosTabCurrent === 'all') {
                let tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => changeDate(item))
                let today = get().sectionsTodosTab.today.filter((item) => changeDate(item))

                if (get().searchTodosTab.length) {
                    tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => changeDate(item))
                    today = get().sectionsTodosTab.today.filter((item) => changeDate(item))
                }

                set(() => ({
                    sectionsTodosTab: {
                        tomorrow: [...sortByCompleted(tomorrow)],
                        today: [...sortByCompleted(today)]
                    }
                }))
            }

            return set(() => ({
                todosTab: [...sortByCompleted(todosTab)],
                foundTodosTab: [...sortByCompleted(foundTodosTab)]
            }))
        }
        if (type === 'calendar') {
            console.log('calendar')
        }
        if (type === 'important') {
            const changeDate = (item: ITodo) => {
                item.is_favorite = true
                return item
            }
            const todosTab = get().todosTab.filter((item) => changeDate(item))
            const foundTodosTab = get().foundTodosTab.filter((item) => changeDate(item))

            if (get().filteredTodosTabCurrent === 'all') {
                let tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => changeDate(item))
                let today = get().sectionsTodosTab.today.filter((item) => changeDate(item))

                if (get().searchTodosTab.length) {
                    tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => changeDate(item))
                    today = get().sectionsTodosTab.today.filter((item) => changeDate(item))
                }

                set(() => ({
                    sectionsTodosTab: {
                        tomorrow: [...sortByCompleted(tomorrow)],
                        today: [...sortByCompleted(today)]
                    }
                }))
            }

            return set(() => ({
                todosTab: [...sortByCompleted(todosTab)],
                foundTodosTab: [...sortByCompleted(foundTodosTab)]
            }))
        }
        if (type === 'no-important') {
            const changeDate = (item: ITodo) => {
                item.is_favorite = false
                return item
            }
            const todosTab = get().todosTab.filter((item) => changeDate(item))
            const foundTodosTab = get().foundTodosTab.filter((item) => changeDate(item))

            if (get().filteredTodosTabCurrent === 'all') {
                let tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => changeDate(item))
                let today = get().sectionsTodosTab.today.filter((item) => changeDate(item))

                if (get().searchTodosTab.length) {
                    tomorrow = get().sectionsTodosTab.tomorrow.filter((item) => changeDate(item))
                    today = get().sectionsTodosTab.today.filter((item) => changeDate(item))
                }

                set(() => ({
                    sectionsTodosTab: {
                        tomorrow: [...sortByCompleted(tomorrow)],
                        today: [...sortByCompleted(today)]
                    }
                }))
            }

            return set(() => ({
                todosTab: [...sortByCompleted(todosTab)],
                foundTodosTab: [...sortByCompleted(foundTodosTab)]
            }))
        }
        if (type === 'remove') {
            if (get().filteredTodosTabCurrent === 'all') {
                set(() => ({
                    sectionsTodosTab: {
                        tomorrow: [],
                        today: []
                    }
                }))
            }

            return set(() => ({
                todosTab: [],
                foundTodosTab: []
            }))
        }
    },

    setRefetchTodos: (refetch = true) => set(() => ({ refetchTodos: refetch })),

    selSectedByDropdownTodos: (filter) => {
        let selectedTodos: ITodo[] = []
        if (filter === ETodoDropdownFilters['all']) {
            selectedTodos = get().todosTab
        }
        if (filter === ETodoDropdownFilters['none']) {
            selectedTodos = []
        }
        if (filter === ETodoDropdownFilters['important']) {
            selectedTodos = get().todosTab.filter((todo) => todo.is_favorite)
        }
        if (filter === ETodoDropdownFilters['not important']) {
            selectedTodos = get().todosTab.filter((todo) => !todo.is_favorite)
        }
        if (filter === ETodoDropdownFilters['expired']) {
            selectedTodos = get().todosTab.filter((todo) => isPastDate(todo.due || ''))
        }

        console.log(selectedTodos)

        set(() => ({
            selectedTodos
        }))
    },

    setSelectedTodos: (todo) => {
        if (!todo) {
            return set(() => ({
                selectedTodos: []
            }))
        }

        const candidate = get().todosTab.find((item) => item.id === todo.id)
        if(!candidate) return 
        
        let selectedTodos: ITodo[] = [...get().selectedTodos]

        let todosIds = [...new Set(selectedTodos.map(item => item.id))]
        
        console.log('todosIds 0', todosIds)
        
        todosIds.includes(todo.id) &&
            (selectedTodos = selectedTodos.filter((item) => !todosIds.includes(item.id)))
        console.log('selectedTodos 1', selectedTodos)
        
        set(() => ({
            selectedTodos: [...selectedTodos, candidate]
        }))
        
    }
})
