import { createContext, FC, ReactNode, useContext, useState } from 'react'

type TPosition = 'horizontal' | 'vertical'
interface MailPositionContextProps {
	position: TPosition
	setPosition: (theme: TPosition) => void
}

const MailPositionContext = createContext<MailPositionContextProps | undefined>(
	undefined
)

const MailPositionProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [position, setPosition] = useState<TPosition>('vertical')
	const [isVertical] = useState<boolean>(position === 'vertical')

	return (
		<MailPositionContext.Provider value={{ position, setPosition }}>
			{children}
		</MailPositionContext.Provider>
	)
}

const useMailPosition = () => {
	const context = useContext(MailPositionContext)
	if (!context) {
		throw new Error(
			'useMailPosition must be used within a MailPositionProvider'
		)
	}
	return context
}

export { MailPositionProvider, useMailPosition }
