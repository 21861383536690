import { StateCreator } from 'zustand'
import { IThreadsSlice } from '../types'
import { EThreadLabels } from '../enum'
import { checkLabels, checkUnread, filterThreads, handlerUpdateThreadLabel, updateThread } from '../helpers'
import { IThread, IUpdateLabelsData } from '@/shared/types/threads.interface'

export const threadsSlice: StateCreator<IThreadsSlice> = (set, get) => ({
    threads: [],
    filteredThreads: [],
    selectedThreads: [],
    thread: null,
    sendMessageForm: {
        to: '',
        subject: '',
        text: ''
    },
    noValidEmail: false,
    showCompose: false,
    refetchThreads: true,
    countInboxUnread: 0,
    countSpamUnread: 0,
    selectedThreadId: null,
    selectedAllThreads: null,
    filteredThreadsTabCurrent: null,
    unreadThreads: [],
    foundThreads: [],
    searchThreadValue: '',
    updateThreadData: {
        message_ids: [],
        thread_ids: [],
        add: [],
        remove: []
    },
    chatType: null,
    chatContent: {
        compose: '',
        chat_dashboard: '',
        chat_inbox: '',
        forward: ''
    },

    loadingChangeThread: false,

    nextTokenPage: null,
    loadingNextPage: false,
    pageSize: 30,
    scollSize: 10,

    isForward: false,
    forwardContent: {
        to: '',
        subject:'',
        from: {
           from: '',
           date: '',
           subject: '',
           to:'',
        },
        massages:[],
    },

    setThreads: (threads) => {
        let allThreads = get().threads.length ? get().threads : threads

        if (get().nextTokenPage && get().loadingNextPage) {
            allThreads = [...get().threads, ...threads]

            set(() => ({ loadingNextPage: false, pageSize: allThreads.length }))
        }

        const uniqueThreadIds = [...new Set(allThreads.map((thread) => thread.id))]
        const threadIds: string[] = []
        
        const uniqueThreads = allThreads.filter(thread => {
            if(uniqueThreadIds.includes(thread.id) && !threadIds.includes(thread.id)) {
                threadIds.push(thread.id)
                return true
            }
            return false
        })
        const filteredThreads = filterThreads(uniqueThreads, get().filteredThreadsTabCurrent)
        // Check Unread Threads Count
        const countInboxUnread = checkUnread(uniqueThreads).counts.inbox
        const countSpamUnread = checkUnread(uniqueThreads).counts.spam

        return set(() => ({
            threads: uniqueThreads,
            filteredThreads,
            countInboxUnread,
            countSpamUnread,
            pageSize: get().pageSize
        }))
    },

    setThread: (threadId) => {
        const currentThread = get().threads.filter((item) => item.id === threadId)[0]
        set(() => ({ thread: currentThread, loadingChangeThread: false }))
    },
    clearThread: () => set(() => ({ thread: null })),
    setLoadingNextPage: (loadingNextPage) => set(() => ({ loadingNextPage })),
    setUpdateThreadData: (updateThreadData) => set(() => ({ updateThreadData })),

    addMessage: (message) => {
        const currentThread = get().threads.find((thread) => thread.id === message.metadata.thread_id)
        currentThread?.messages.push(message)
        return set(() => ({ thread: currentThread }))
    },
    clearThreadLabels: () =>
        set(() => ({
            updateThreadData: {
                message_ids: [],
                thread_ids: [],
                add: [],
                remove: []
            }
        })),
    updateThreadLabels: (label, threadId) => {
        let updateThreadData: IUpdateLabelsData = {
            message_ids: [],
            thread_ids: [],
            add: [],
            remove: []
        }
        const threadLabels = get().updateThreadData

        if (threadId) {
            const thread = get().filteredThreads.find((thread) => thread.id === threadId)
            if (!thread) return

            updateThreadData = handlerUpdateThreadLabel(thread, threadLabels, threadId, label)
            return set(() => ({ updateThreadData }))
        }
        get().selectedThreads.map((sThread) => {
            const thread = get().filteredThreads.find((thread) => thread.id === sThread)
            if (!thread) return
            const { message_ids, thread_ids, add, remove } = handlerUpdateThreadLabel(
                thread,
                get().updateThreadData,
                sThread,
                label
            )

            return set(() => ({
                updateThreadData: {
                    message_ids: [...new Set([...threadLabels.message_ids, ...message_ids])],
                    thread_ids: [...threadLabels.thread_ids, ...thread_ids],
                    add: [...new Set(add)],
                    remove: [...new Set(remove)]
                }
            }))
        })
    },

    setSelectAllThreads: () => {
        // Select All Threads
        const selectedAllThreads =
            get().filteredThreads.length === get().selectedThreads.length
                ? 'all'
                : get().selectedThreads.length
                ? 'some'
                : null

        const selectAllThreads = get().filteredThreads.map((thread) => thread.id)
        const selectAllFoundThreads = get().foundThreads.map((thread) => thread.id)

        set(() => ({ unreadThreads: [] }))
        get().filteredThreads.forEach((thread) => {
            selectAllThreads.includes(thread.id) &&
            thread.messages[0].metadata.labels.includes(EThreadLabels.UNREAD)
                ? set(() => ({ unreadThreads: [...get().unreadThreads, thread.id] }))
                : set(() => ({ unreadThreads: [...get().unreadThreads] }))
        })

        if (selectedAllThreads === 'some') {
            return set(() => ({
                selectedThreads: [],
                selectedAllThreads: null,
                unreadThreads: []
            }))
        }
        if (!selectedAllThreads) {
            return set(() => ({
                selectedThreads: !!get().searchThreadValue.length
                    ? [...selectAllFoundThreads]
                    : [...selectAllThreads],
                selectedAllThreads: 'all'
            }))
        }
        return set(() => ({
            selectedThreads: [],
            selectedAllThreads: null,
            unreadThreads: []
        }))
    },
    setSelectedThreads: (threadId) => {
        const dublicate = get().selectedThreads.includes(threadId as string)
        const noDublicatedThreadId = get().selectedThreads.filter((id) => id !== threadId)

        get().filteredThreads.forEach((thread) => {
            if (thread.id === threadId) {
                !dublicate
                    ? thread.messages[0].metadata.labels.includes(EThreadLabels.UNREAD) &&
                      set(() => ({ unreadThreads: [...get().unreadThreads, thread.id] }))
                    : set(() => ({
                          unreadThreads: [
                              ...get().unreadThreads.filter((uThreadId) => uThreadId !== thread.id)
                          ]
                      }))
            }
        })

        set(() => ({
            selectedThreads: dublicate ? noDublicatedThreadId : [threadId as string, ...get().selectedThreads]
        }))

        const selectedAllThreads =
            get().filteredThreads.length === get().selectedThreads.length ||
            (!!get().foundThreads.length && get().foundThreads.length) === get().selectedThreads.length
                ? 'all'
                : get().selectedThreads.length
                ? 'some'
                : null

        return set(() => ({ selectedAllThreads }))
    },
    updateSelectedAllThreads: (label) => {
        const currentSent = get().filteredThreadsTabCurrent === 'sent'

        const updatedSelectedThreads = get().selectedThreads.map((sThread) => {
            const thread = get().filteredThreads.find((thread) => thread.id === sThread)

            if (thread) {
                const labels = thread.messages[0].metadata.labels
                let updatedLabels: string[] = thread.messages[0].metadata.labels

                const { INBOX, SENT, SPAM, TRASH, DRAFT, UNREAD } = checkLabels(labels)

                // Set Read/Unread selected Threads
                if (label === EThreadLabels.UNREAD) {
                    UNREAD &&
                        (updatedLabels = labels.filter((threadLabel) => threadLabel !== EThreadLabels.UNREAD))

                    thread.messages.map(
                        (item) =>
                            (item.metadata.labels = !!get().unreadThreads.length
                                ? [...updatedLabels]
                                : [...updatedLabels, label])
                    )
                    set(() => ({
                        thread: thread.id === get().selectedThreadId ? thread : get().thread
                    }))
                }

                // Set Read/Unread selected Threads
                if (label === EThreadLabels.SPAM || label === EThreadLabels.TRASH) {
                    INBOX &&
                        (updatedLabels = labels.filter((threadLabel) => threadLabel !== EThreadLabels.INBOX))
                    thread.messages.map((item) => (item.metadata.labels = [...updatedLabels, label]))

                    set(() => ({
                        selectedThreads: [],
                        selectedAllThreads: null,
                        thread: thread.id === get().selectedThreadId ? null : get().thread
                        // chatType: thread.id === get().selectedThreadId ? null : get().chatType
                    }))
                }

                if (label === EThreadLabels.INBOX) {
                    updatedLabels = labels.filter(
                        (threadLabel) =>
                            threadLabel !== (SPAM ? EThreadLabels.SPAM : TRASH && EThreadLabels.TRASH)
                    )

                    thread.messages.map(
                        (item) =>
                            (item.metadata.labels = [
                                ...updatedLabels,
                                !currentSent
                                    ? SENT
                                        ? EThreadLabels.SENT
                                        : DRAFT
                                        ? EThreadLabels.DRAFT
                                        : label
                                    : label
                            ])
                    )

                    set(() => ({
                        selectedThreads: [],
                        selectedAllThreads: null,
                        thread: thread.id === get().selectedThreadId ? null : get().thread
                        // chatType: thread.id === get().selectedThreadId ? null : get().chatType
                    }))
                }
            }
            return thread
        })

        // Update Read Icon
        set(() => ({ unreadThreads: [] }))
        get().filteredThreads.forEach((fThread) => {
            get().selectedThreads.includes(fThread.id) &&
                fThread.messages[0].metadata.labels.includes(EThreadLabels.UNREAD) &&
                set(() => ({ unreadThreads: [...get().unreadThreads, fThread.id] }))
        })

        const updatedThreads = updateThread(get().threads, updatedSelectedThreads as IThread[])
        const updatedFilteredThreads = updateThread(
            get().filteredThreads,
            updatedSelectedThreads as IThread[]
        )
        const updatedSearchThreads = updateThread(get().foundThreads, updatedSelectedThreads as IThread[])

        // Update Filtered Thread List
        const filteredThreads = filterThreads(updatedFilteredThreads, get().filteredThreadsTabCurrent)
        const foundThreads = filterThreads(updatedSearchThreads, get().filteredThreadsTabCurrent)

        // Check Unread Threads Count
        const countInboxUnread = checkUnread(updatedThreads).counts.inbox
        const countSpamUnread = checkUnread(updatedThreads).counts.spam

        return set(() => ({
            threads: [...updatedThreads],
            filteredThreads: [...filteredThreads],
            foundThreads: [...foundThreads],
            countInboxUnread: countInboxUnread,
            countSpamUnread: countSpamUnread
        }))
    },
    updateDropdownSelectedThreads: (label) => {
        const updatedSelectedThreads = get().selectedThreads.map((sThread) => {
            const thread = get().filteredThreads.find((thread) => thread.id === sThread)
            if (thread) {
                const labels = thread.messages[0].metadata.labels
                let updatedLabels: string[] = thread.messages[0].metadata.labels

                const { INBOX, SENT, SPAM, TRASH, DRAFT, UNREAD, IMPORTANT } = checkLabels(labels)

                // Set Read/Unread selected Threads
                if (label === 'read' || label === 'unread') {
                    UNREAD &&
                        (updatedLabels = labels.filter((threadLabel) => threadLabel !== EThreadLabels.UNREAD))

                    label === 'read' &&
                        thread.messages.map((item) => (item.metadata.labels = [...updatedLabels]))
                    label === 'unread' &&
                        thread.messages.map(
                            (item) => (item.metadata.labels = [...updatedLabels, EThreadLabels.UNREAD])
                        )

                    set(() => ({
                        thread: thread.id === get().selectedThreadId ? thread : get().thread
                    }))
                }

                // Set important/not important selected Threads
                if (label === 'important' || label === 'not important') {
                    IMPORTANT &&
                        (updatedLabels = labels.filter(
                            (threadLabel) => threadLabel !== EThreadLabels.IMPORTANT
                        ))

                    label === 'not important' &&
                        thread.messages.map((item) => (item.metadata.labels = [...updatedLabels]))
                    label === 'important' &&
                        thread.messages.map(
                            (item) => (item.metadata.labels = [...updatedLabels, EThreadLabels.IMPORTANT])
                        )

                    set(() => ({
                        thread: thread.id === get().selectedThreadId ? thread : get().thread
                    }))
                }

                // Set all not spam selected Threads
                if (label === 'spam' || label === 'all not spam' || label === 'not spam') {
                    if (SPAM) {
                        updatedLabels = thread.messages[0].metadata.labels.filter(
                            (threadLabel) => threadLabel !== EThreadLabels.SPAM
                        )
                    }

                    if (label === 'all not spam' || label === 'not spam') {
                        updatedLabels = thread.messages[0].metadata.labels.filter(
                            (threadLabel) => threadLabel !== EThreadLabels.SPAM
                        )
                        thread.messages.map(
                            (item) =>
                                (item.metadata.labels =
                                    SENT || DRAFT
                                        ? [...updatedLabels]
                                        : [...updatedLabels, EThreadLabels.INBOX])
                        )
                    }
                    if (label === 'spam') {
                        TRASH &&
                            (updatedLabels = labels.filter(
                                (threadLabel) => threadLabel !== EThreadLabels.TRASH
                            ))
                        INBOX &&
                            (updatedLabels = labels.filter(
                                (threadLabel) => threadLabel !== EThreadLabels.INBOX
                            ))
                        thread.messages.map(
                            (item) => (item.metadata.labels = [...updatedLabels, EThreadLabels.SPAM])
                        )
                    }
                    set(() => ({
                        selectedThreads: [],
                        selectedAllThreads: null,
                        thread: thread.id === get().selectedThreadId ? null : get().thread
                        // chatType: thread.id === get().selectedThreadId ? null : get().chatType
                    }))
                }
            }

            return thread
        })

        // Update Read Icon
        set(() => ({ unreadThreads: [] }))
        get().filteredThreads.forEach((fThread) => {
            get().selectedThreads.includes(fThread.id) &&
                fThread.messages[0].metadata.labels.includes(EThreadLabels.UNREAD) &&
                set(() => ({ unreadThreads: [...get().unreadThreads, fThread.id] }))
        })

        const updatedThreads = updateThread(get().threads, updatedSelectedThreads as IThread[])
        const updatedFilteredThreads = updateThread(
            get().filteredThreads,
            updatedSelectedThreads as IThread[]
        )
        const updatedSearchThreads = updateThread(get().foundThreads, updatedSelectedThreads as IThread[])

        // Update Filtered Thread List
        const filteredThreads = filterThreads(updatedFilteredThreads, get().filteredThreadsTabCurrent)
        const foundThreads = filterThreads(updatedSearchThreads, get().filteredThreadsTabCurrent)

        // Check Unread Threads Count
        const countInboxUnread = checkUnread(updatedThreads).counts.inbox
        const countSpamUnread = checkUnread(updatedThreads).counts.spam

        return set(() => ({
            threads: [...updatedThreads],
            filteredThreads: [...filteredThreads],
            foundThreads: [...foundThreads],
            countInboxUnread,
            countSpamUnread
        }))
    },
    updateThread: (threadId, label) => {
        const currentThread = get().filteredThreads.find((thread) => thread.id === threadId)

        if (!currentThread) return

        const labels = currentThread.messages[0].metadata.labels
        let updatedLabels: string[] = currentThread.messages[0].metadata.labels

        const { INBOX, SENT, SPAM, TRASH, DRAFT, UNREAD, IMPORTANT, NEW } = checkLabels(labels)

        // Set Read/Unread selected Threads
        if (label === EThreadLabels.UNREAD) {
            updatedLabels = currentThread.messages[0].metadata.labels.filter(
                (threadLabel) => threadLabel !== EThreadLabels.UNREAD
            )
            NEW && (updatedLabels = updatedLabels.filter((item) => item !== EThreadLabels.NEW))

            UNREAD
                ? currentThread.messages.map((item) => (item.metadata.labels = [...updatedLabels]))
                : currentThread.messages.map((item) => (item.metadata.labels = [...updatedLabels, label]))

            // Update Read Icon
            currentThread.id === threadId && labels.includes(EThreadLabels.UNREAD)
                ? set(() => ({ unreadThreads: [...get().unreadThreads, currentThread.id] }))
                : set(() => ({
                      unreadThreads: [
                          ...get().unreadThreads.filter((uThreadId) => uThreadId !== currentThread.id)
                      ]
                  }))

            set(() => ({
                thread: threadId === get().selectedThreadId ? currentThread : get().thread
            }))
        }
        if (label === EThreadLabels.IMPORTANT) {
            updatedLabels = labels.filter((threadLabel) => threadLabel !== EThreadLabels.IMPORTANT)
            IMPORTANT
                ? currentThread.messages.map((item) => (item.metadata.labels = [...updatedLabels]))
                : currentThread.messages.map((item) => (item.metadata.labels = [...updatedLabels, label]))

            set(() => ({
                thread: threadId === get().selectedThreadId ? currentThread : get().thread
            }))
        }

        // Set Read/Unread selected Threads
        if (label === EThreadLabels.SPAM || label === EThreadLabels.TRASH) {
            INBOX && (updatedLabels = labels.filter((threadLabel) => threadLabel !== EThreadLabels.INBOX))

            currentThread.messages.map((item) => (item.metadata.labels = [...updatedLabels, label]))

            set(() => ({
                thread: threadId === get().selectedThreadId ? null : get().thread
                // chatType: threadId === get().selectedThreadId ? null : get().chatType
            }))
        }

        if (label === EThreadLabels.INBOX) {
            updatedLabels = labels.filter(
                (threadLabel) => threadLabel !== (SPAM ? EThreadLabels.SPAM : TRASH && EThreadLabels.TRASH)
            )

            currentThread.messages.map(
                (item) =>
                    (item.metadata.labels = [
                        ...updatedLabels,
                        SENT ? EThreadLabels.SENT : DRAFT ? EThreadLabels.DRAFT : label
                    ])
            )
            set(() => ({
                thread: threadId === get().selectedThreadId ? null : get().thread
                // chatType: threadId === get().selectedThreadId ? null : get().chatType
            }))
        }

        // Update Filtered Thread List
        const updateThreads = (threads: IThread[]) =>
            threads.map((thread) => (thread.id === threadId ? currentThread : thread))

        const updatedThreads = updateThreads(get().threads)
        const updatedFilteredThreads = updateThreads(get().filteredThreads)
        const updatedSearchThreads = updateThreads(get().foundThreads)

        // Update Found Thread List
        const filteredThreads = filterThreads(updatedFilteredThreads, get().filteredThreadsTabCurrent)
        const foundThreads = filterThreads(updatedSearchThreads, get().filteredThreadsTabCurrent)

        // Check Unread Threads Count
        const countInboxUnread = checkUnread(updatedThreads).counts.inbox
        const countSpamUnread = checkUnread(updatedThreads).counts.spam

        return set(() => ({
            threads: [...updatedThreads],
            filteredThreads: [...filteredThreads],
            foundThreads: [...foundThreads],
            countInboxUnread,
            countSpamUnread
        }))
    },
    removeSelectedThreads: () => {
        const withoutThreads = (threads: IThread[]) =>
            threads.filter((thread) => {
                const filteredThreads = get().selectedThreads.find((threadId) => threadId === thread.id)
                if (!!filteredThreads?.length) return
                return thread
            })

        const withoutRemovedThreads = withoutThreads(get().threads)
        const withoutRemovedSelectedThreads = withoutThreads(get().filteredThreads)
        const withoutRemovedfoundThreads = withoutThreads(get().filteredThreads)

        // Check Unread Threads Count
        const countInboxUnread = checkUnread(withoutRemovedThreads).counts.inbox
        const countSpamUnread = checkUnread(withoutRemovedThreads).counts.spam

        return set(() => ({
            threads: [...withoutRemovedThreads],
            filteredThreads: [...withoutRemovedSelectedThreads],
            foundThreads: [...withoutRemovedfoundThreads],
            selectedThreads: [],
            selectedAllThreads: null,
            countInboxUnread,
            countSpamUnread
        }))
    },
    removeAllThreads: () => {
        if (get().filteredThreadsTabCurrent !== 'spam' && get().filteredThreadsTabCurrent !== 'trash') return

        const withoutThreads = (threads: IThread[]) =>
            threads.filter(
                (item) =>
                    !item.messages[0].metadata.labels.includes(
                        EThreadLabels[get().filteredThreadsTabCurrent?.toUpperCase() as EThreadLabels]
                    )
            )

        const withoutRemovedThreads = withoutThreads(get().threads)
        const withoutRemovedFilteredThreads = withoutThreads(get().filteredThreads)
        const withoutRemovedfoundThreads = withoutThreads(get().foundThreads)

        // Check Unread Threads Count
        const countInboxUnread = checkUnread(withoutRemovedThreads).counts.inbox
        const countSpamUnread = checkUnread(withoutRemovedThreads).counts.spam

        return set(() => ({
            threads: [...withoutRemovedThreads],
            filteredThreads: [...withoutRemovedFilteredThreads],
            foundThreads: [...withoutRemovedfoundThreads],
            countInboxUnread,
            countSpamUnread
        }))
    },
    removeThread: (id) => {
        const threads = get().threads.filter((item) => item.id !== id)
        const filteredThreads = get().filteredThreads.filter((item) => item.id !== id)
        const foundThreads = get().foundThreads.filter((item) => item.id !== id)

        // Check Unread Threads Count
        const countInboxUnread = checkUnread(threads).counts.inbox
        const countSpamUnread = checkUnread(threads).counts.spam

        return set(() => ({
            thread: get().selectedThreadId === id ? null : get().thread,
            // chatType: get().selectedThreadId === id ? null : get().chatType,
            threads,
            filteredThreads,
            foundThreads,
            countInboxUnread,
            countSpamUnread,
            selectedThreadId: get().selectedThreadId === id ? get().selectedThreadId : undefined
        }))
    },
    validateField: (fieldValue, fieldName) => {
        if (fieldName === 'email') {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

            if (!emailRegex.test(fieldValue)) {
                return set(() => ({
                    noValidEmail: true
                }))
            }
        }

        return set(() => ({
            noValidEmail: false
        }))
    },
    clearValidateField: (fieldName) => {
        setTimeout(() => {
            if (!fieldName) {
                return set(() => ({
                    noValidEmail: false
                }))
            }
            if (fieldName === 'email') {
                return set(() => ({ noValidEmail: false }))
            }
        }, 3000)
    },
    setChatType: (chatType) => set(() => ({ chatType })),
    setSendMessageForm: (name, value, type) => {
        if (type === get().chatType) {
            name === 'text' &&
                set(() => ({
                    chatContent: {
                        compose: type === 'compose' ? value : get().chatContent.compose,
                        chat_dashboard: type === 'chat_dashboard' ? value : get().chatContent.chat_dashboard,
                        chat_inbox: type === 'chat_inbox' ? value : get().chatContent.chat_inbox,
                        forward: type === 'forward' ? value : get().chatContent.forward
                    }
                }))

            return set(() => ({
                noValidEmail: false,
                sendMessageForm: {
                    ...get().sendMessageForm,
                    [name]: value
                }
            }))
        }
    },

    clearSendMessageForm: () =>
        set(() => ({
            sendMessageForm: {
                ...get().sendMessageForm,
                to: '',
                subject: '',
                text: ''
            },
            chatContent: {
                compose: '',
                chat_dashboard: '',
                chat_inbox: '',
                forward: ''
            }
        })),
    setRefetchThreads: (refetch = true) => set(() => ({ refetchThreads: refetch })),
    setShowCompose: (showCompose = false) => set(() => ({ showCompose })),

    setSelectedThreadId: (selectedThreadId) => set(() => ({ selectedThreadId })),
    setThreadsTabCategory: (category) => {
        set(() => ({ filteredThreadsTabCurrent: category || 'new' }))

        const filteredThreads = filterThreads(get().threads, category)

        return set(() => ({
            filteredThreads,
            selectedThreads: [],
            selectedAllThreads: null,
            foundThreads: [],
            searchThreadValue: ''
        }))
    },
    searchThreads: (input) => {
        set(() => ({ searchThreadValue: input }))
        const foundThreads = get().filteredThreads.filter((thread) => {
            const messages = thread.messages.filter(
                (message) =>
                    message?.metadata.snippet
                        ?.toLocaleString()
                        .toLowerCase()
                        .includes(get().searchThreadValue.toLowerCase())
            )

            const inFiltered = thread.messages[0].metadata.labels.includes(
                EThreadLabels[get().filteredThreadsTabCurrent?.toUpperCase() as EThreadLabels]
            )
            return !!messages.length && inFiltered ? thread : false
        })

        return set(() => ({
            foundThreads: [...foundThreads]
        }))
    },
    setLoadingChangeThread: (loadingChangeThread) => set(() => ({ loadingChangeThread })),

    setNextTokenPage: (nextTokenPage) => set(() => ({ nextTokenPage })),

    setIsForward: (isForward) => set(() => ({ isForward }))
})
