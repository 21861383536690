import { useStore } from '@/shared/store'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useApiAsyncTasks } from '../../dashboard/api'
import { ThreadsService } from '@/services/inbox/threads.api'

export const useApiGenerateScivePowerItem = () => {
    const { setLoadingAsyncTask, setAsyncTaskId, generateThreadId, setGenerateThreadId } = useStore()

    const { fetchApiAsyncTasks } = useApiAsyncTasks()

    const [enabled, setEnabled] = useState<boolean>(false)
    const [threadId, setThreadId] = useState<string | undefined>(undefined)

    const queryResult = useQuery(
        ['get_llm_generate_scive_power_item'],
        () => ThreadsService.getGenerateScivePowerItem(generateThreadId!),
        {
            enabled,
            async onSuccess(res) {
                console.log('get_llm_generate_scive_power_item', res)

                if (res?.data && res.data.id) {
                    setAsyncTaskId(res.data.id)
                    await fetchApiAsyncTasks()
                    setLoadingAsyncTask(true)
                }
            }
        }
    )

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])

    const fetchApiGenerateScivePowerItem = (digestId: string) => {
        setGenerateThreadId(digestId)
        setEnabled(true)
    }

    return {
        fetchApiGenerateScivePowerItem,
        ...queryResult
    }
}
