import { FC, useState } from 'react'
import { Slider } from '@react-native-assets/slider'
import { View } from 'react-native'
import { Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'

interface IProps {
    title: {
        max: string
        min: string
    }
    onChange: (value: number) => void
    value: number
}
const SliderControl: FC<IProps> = ({ title, onChange, value }) => {
    const { text } = useColors()
    const { colorScheme } = useColorScheme()

    return (
        <View className='flex-col w-[132px] justify-center'>
            <View className='justify-between flex-row items-center'>
                <Typography variant='label-date' className={cn(text.inactiveText)}>
                    {title.min}
                </Typography>
                <Typography variant='label-date' className={cn(text.inactiveText)}>
                    {title.max}
                </Typography>
            </View>
            <Slider
                style={{ width: 123, height: 16, margin: 'auto' }}
                value={value}
                minimumValue={0}
                maximumValue={3}
                step={1}
                minimumTrackTintColor={colorScheme === 'light' ? '#374151' : '#38BDF8'}
                maximumTrackTintColor={colorScheme === 'light' ? '#E5E7EB' : '#111827'}
                thumbTintColor={colorScheme === 'light' ? '#374151' : '#38BDF8'}
                trackHeight={2}
                thumbSize={12}
                onValueChange={onChange}
                thumbStyle={{
                    // @ts-ignore
                    outlineStyle: 'solid',
                    outlineWidth: 3,
                    outlineColor: colorScheme === 'light' ? '#FFF' : '#1F2937'
                }}
                CustomMark={(props) => (
                    <View
                        className={cn(
                            'w-[6px] h-[6px] rounded-base-x2',
                            colorScheme === 'light' ? 'bg-gray-200' : 'bg-gray-900',
                            props.value <= value && (colorScheme === 'light' ? 'bg-gray-800' : 'bg-sky')
                        )}>
                        {props.active}
                    </View>
                )}
            />
        </View>
    )
}

export default SliderControl
