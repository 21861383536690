import { FC } from 'react'
import { Image, View } from 'react-native'
import { Typography } from '@/components/ui'
import { useUserProfile } from '../../../api'

const Profile: FC = () => {
	const { userProfile } = useUserProfile() 

	return (
        <View className='w-[420px] flex-row items-center bg-gray-100 rounded-base-x4 pt-base-x1 pb-base-x1 pr-base-x2 justify-between'>
            <View className='flex-row items-center'>
                <Image source={require('@/assets/avatar.png')} className='w-base-x12 h-base-x12 m-base-x2' />
                <View>
                    <Typography variant='h4' className='pt-base-x1 pb-base-x1'>
                        {userProfile?.name}
                    </Typography>
                    <Typography variant='subheadline'>{userProfile?.email}</Typography>
                </View>
            </View>
            {/*<Button icon='ChevronRight' sizeIcon={24} />*/}
        </View>
    )
}

export default Profile
