import AsyncStorage from '@react-native-async-storage/async-storage'
import { IUser } from '@/shared/types/user.interface';
import {
	EnumStorage,
	IToken
} from '@/shared/types/auth.interface'

export const getToken = async () => {
	const token = await AsyncStorage.getItem(EnumStorage.TOKEN);
	return token?.replaceAll('"', '') || null
}

export const saveTokenStorage = async (data: IToken) => {
	await AsyncStorage.setItem(EnumStorage.TOKEN, JSON.stringify(data.token))
}

export const deleteTokenStorage = async () => {
	await AsyncStorage.removeItem(EnumStorage.TOKEN)
}

export const getUserFromStorage = async () => {
	try {
		return JSON.parse((await AsyncStorage.getItem(EnumStorage.USER)) || '{}')
	} catch (e) {
		return null
	}
}

export const saveToStorage = async (data: IUser) => {
    try {
        await AsyncStorage.setItem(EnumStorage.USER, JSON.stringify(data))
    } catch (e) {}
}
