import { createContext, FC, ReactNode, useContext, useState } from 'react'

interface ModalContextProps {
	visible: Boolean
	setVisible: (value: Boolean) => void
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined)

const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [visible, setVisible] = useState<Boolean>(false)

	return (
		<ModalContext.Provider value={{ visible, setVisible }}>
			{children}
		</ModalContext.Provider>
	)
}

const useModal = () => {
	const context = useContext(ModalContext)
	if (!context) {
		throw new Error('useModal must be used within a ModalProvider')
	}
	return context
}

export { ModalProvider, useModal }
