import { FC, useState } from 'react'
import { View } from 'react-native'
import { Body, Button, Header, InputSearch, Loader, Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { useStore } from '@/shared/store'
import { useApiGetTodos, useUpdateTodo } from '@/components/screens/todo/api'
import TodoItem from './TodoItem'
import { ITodo } from '@/shared/types/todo.interface'
import AddTaskToDo from '../AddTask'
interface IProps {
    className?: string
}

const Todo: FC<IProps> = ({ className }) => {
    const [showAddTaskModal, setShowAddTaskModal] = useState(false)
    const { bg, text, color } = useColors()

    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    const { isFetching } = useApiGetTodos()
    const { updateTodo } = useUpdateTodo()

    const { searchTodos, searchValue, foundTodos, filterTodos, filterBy, filteredTodos, setAddTodoFormType } =
        useStore((state) => state)

    const updateTask = (id: number, todo: ITodo) => {
        updateTodo(id, { ...todo })
    }

    const toggleVisibleList = () => {
        setShowAddTaskModal(!showAddTaskModal)
        setAddTodoFormType('dashboard_task')
    }

    return (
        <View
            className={cn(
                'pt-base-x5 pb-base-x2 flex-1 justify-between pl-base-x4 pr-base-x2 w-full overflow-hidden',
                className,
                deviderColor
            )}
            style={{ gap: 16 }}>
            <View className='pt-base-x1 pb-base-x1 pr-base-x4  flex-row justify-between '>
                <Header title='To-Do list' subtitle='' />
                <Button
                    className={cn('pt-base-x1 pb-base-x1 pr-base-x3 pl-base-x3 ', bg.white)}
                    classNameText={cn(text.inactiveText, filterBy === 'new' && `${text.black} font-semibold`)}
                    variant='subheadline'
                    onPress={() => filterTodos('new')}>
                    New
                </Button>
                <Button
                    className={cn('pt-base-x1 pb-base-x1 pr-base-x3 pl-base-x3  ', bg.white)}
                    classNameText={cn(text.inactiveText, filterBy === 'due' && `${text.black} font-semibold`)}
                    variant='subheadline'
                    onPress={() => filterTodos('due')}>
                    By date
                </Button>
                <Button
                    icon='Star'
                    colorIcon={filterBy === 'favorite' ? color.star : color.black}
                    fillIcon={
                        filterBy === 'favorite' ? (colorScheme === 'light' ? '#1F2937' : '#38BDF8') : ''
                    }
                    sizeIcon={20}
                    onPress={() => filterTodos('favorite')}
                />
            </View>
            <InputSearch
                className='mr-base-x2  '
                placeholder='Search by your tasks'
                onChange={(value) => searchTodos(value)}
                value={searchValue}
            />
            <View>
                <Button
                    style={{
                        //@ts-ignore
                        transition: 'all 0.3s ease-out'
                    }}
                    className={`w-full mr-4 rounded-t-lg rounded-b-lg border-transparent py-4 px-2 ${
                        showAddTaskModal ? `${bg.hoverIconSurface} rounded-b-none` : ''
                    }`}
                    styleOnHover={`${bg.hoverIconSurface} ${showAddTaskModal ? bg.hoverIconSurface : ''}`}
                    colorIcon='primary'
                    sizeIcon={24}
                    variant='subheadline'
                    classNameText={text.black}
                    onPress={toggleVisibleList}
                    icon='PlusCircle'>
                    Add a task
                </Button>

                <AddTaskToDo
                    className='-mt-[25px]'
                    type='dashboard_task'
                    showAddTaskModal={showAddTaskModal}
                    setShowAddTaskModal={setShowAddTaskModal}
                />
            </View>

            <Body
                placeholder='This is our little daily tasks section. You can add tasks here from selected emails or create them yourself.'
                className='pl-base-x2 m-0'>
                <View className='flex-col'>
                    {!isFetching ? (
                        !foundTodos.length && !searchValue.length ? (
                            filteredTodos.length ? (
                                filteredTodos.map((todo, idx) => (
                                    <TodoItem key={idx} todo={todo} updateTask={updateTask} />
                                ))
                            ) : (
                                <Typography variant='body' className=''>
                                    No tasks found.
                                </Typography>
                            )
                        ) : (
                            foundTodos.map((todo, idx) => (
                                <TodoItem key={idx} todo={todo} updateTask={updateTask} />
                            ))
                        )
                    ) : (
                        <Loader text='Loading tasks' />
                    )}
                </View>
            </Body>
        </View>
    )
}

export default Todo
