import { FC } from 'react'
import { Typography } from '@/components/ui'
import { Image, TouchableOpacity } from 'react-native'

interface IProps {
	onSubmit: () => void
}
const LoginButton: FC<IProps> = ({ onSubmit }) => {
	return (
		<TouchableOpacity
			onPress={onSubmit}
			className='rounded-base-x6 bg-white cursor-pointer flex-row items-center max-w-[350px] justify-center p-base-x2 pr-base-x4 shadow-md'
		>
			<Image
				source={require('@/assets/icons/google.png')}
				className='w-[48px] h-[48px] m-base-x2'
			/>
			<Typography variant='button-big'>Continue with Google</Typography>
		</TouchableOpacity>
	)
}

export default LoginButton
