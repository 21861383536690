import { DashboardService } from '@/services/dashboard'
import { useStore } from '@/shared/store'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useApiAsyncTasks } from '../../dashboard/api'
import { maxWords, tonality } from '@/shared/types/dashboard.interface'

export const useApiGenerateComposeSuggestions = () => {
    const { setAsyncTaskId, composePrompt, selectedThreadId, showCompose } = useStore()
    const { fetchApiAsyncTasks } = useApiAsyncTasks()
    const [enabled, setEnabled] = useState<boolean>(false)


    const queryResult = useQuery(
        ['get_llm_generate_compose_suggestion'],
        () =>
            DashboardService.getGenerateComposeSuggestion({
                ...composePrompt,
                message_id: !showCompose ? (composePrompt.message_id as string) || (selectedThreadId as string) : null,
                max_words: maxWords[composePrompt.max_words],
                tonality: tonality[composePrompt.tonality as number]
            }),
        {
            enabled,
            async onSuccess(res) {
                console.log('get_llm_generate_compose_suggestion', res)
                if (res.data && res.data.id) {
                    setAsyncTaskId(res.data.id)
                    await fetchApiAsyncTasks()
                }
            }
        }
    )

    useEffect(() => {
        enabled && setEnabled(false)
    }, [enabled])

    const fetchApiGenerateComposeSuggestion = () => {
        setEnabled(true)
    }

    return {
        fetchApiGenerateComposeSuggestion,
        ...queryResult
    }
}
