export const htmlToText = (value: string) => {
	return value.replace(/<\/?[^>]+(>|$)/g, '')
}

export const convertTextToHtml = (text: string) => {
    return text
        .split('\n\n')
        .map((paragraph) => `<p>${paragraph.replace(/\n/g, '<br>')}</p>`)
        .join('')
}

export const cleanHtml = (html: string) => {
    html = html.replace(/<p><br><\/p>/g, '')
    html = html.replace(/<p>\s*<\/p>/g, '')
    html = html.replace(/(<br\s*\/?>\s*){2,}/g, '<br>')
    html = html.replace(/<p>\s*/g, '<p>').replace(/\s*<\/p>/g, '</p>')

    return html
}