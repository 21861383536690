import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { DashboardService } from '@/services/dashboard'
import { IGenerateDigestItemResult } from '@/shared/types/dashboard.interface'
import { useStore } from '@/shared/store'
import { useApiAsyncTasks } from './useApiAsyncTasks.api'

export const useApiGetDigestItemDetails = () => {

    const [enabled, setEnabled] = useState<boolean>(false)

    const queryResult = useQuery(['get_digest_item_details'], () => DashboardService.getDigestItemDetails(), {
        enabled,
        async onSuccess(res) {
            console.log('get_digest_item', res?.data)
            //  if (res?.data && res.data.id) {
            //      setAsyncTaskId(res.data.id)
            //      await fetchApiAsyncTasks()
            //      setLoadingAsyncTask(true)
            //  }
        }
    })

    useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])

    const fetchGetDigestItemDetails= () => {
        setEnabled(true)
    }

    return {
        fetchGetDigestItemDetails,
        ...queryResult
    }
}
