import { FC } from 'react'
import { View } from 'react-native'
import { IPlanCard, TPlans } from '../../../settings.interface'
import { Button, Icon, Typography } from '@/components/ui'
import cn from 'clsx'
import { useSizeSelector } from '@/context/SizeSelectorContext'

interface IProps extends IPlanCard {
	currentPlan: TPlans
	disabled: boolean
	setCurrentPlan: (plan: TPlans) => void
}

const Card: FC<IProps> = ({
	type,
	setCurrentPlan,
	disabled,
	currentPlan,
	price,
	tooltip,
	points
}) => {
	const { size } = useSizeSelector()

	return (
		<View
			className={cn(
				'p-base-x8 rounded-[36px] flex-col items-center',
				size === 'large' ? 'w-[312px] h-[440px]' : 'w-[280px] h-[400px]',
				type === 'Standart' && 'bg-gray-50',
				type === 'Plus' && 'bg-sky',
				type === 'Plus' && disabled && 'bg-sky-100',
				type === 'Pro' && 'bg-black'
			)}
			style={{ gap: 36 }}
		>
			<View className='flex-col items-center' style={{ gap: 16 }}>
				<View className='flex-row items-center' style={{ gap: 8 }}>
					<Typography
						variant='h3'
						className={cn(
							type === 'Standart' && 'text-gray-400',
							type === 'Plus' && 'text-gray-900',
							disabled && 'text-gray-400',
							type === 'Pro' && 'text-sky'
						)}
					>
						{type}
					</Typography>
					{tooltip && (
						<Typography
							variant='calout'
							className={cn(
								'text-white pt-base-x1 pb-base-x1 pr-base-x3 pl-base-x3 bg-gray-800 rounded-base-x16',
								type === 'Plus' && disabled && 'bg-gray-300'
							)}
						>
							{tooltip}
						</Typography>
					)}
				</View>
				<Typography
					variant='h1'
					className={cn(
						'text-black',
						type === 'Pro' && 'text-white',
						disabled && 'text-gray-300'
					)}
				>
					{price}
				</Typography>
			</View>
			<View className='flex-col flex-1' style={{ gap: 16 }}>
				{type === 'Pro' && (
					<View className='flex items-center h-[84px]'>
						<Icon name='Gem' size={48} color='white' />
					</View>
				)}
				{points.map(point => (
					<View
						className='flex-row items-center'
						style={{ gap: 16 }}
						key={point}
					>
						<Icon
							name='Check'
							size={20}
							color={
								type === 'Plus' && !disabled
									? 'white'
									: disabled
									? 'gray-300'
									: 'sky'
							}
						/>
						<Typography
							variant='body-list'
							className={cn(
								type === 'Pro' && 'text-white',
								type === 'Standart' && disabled && 'text-gray-300',
								type === 'Plus' && disabled && 'text-gray-400'
							)}
						>
							{point}
						</Typography>
					</View>
				))}
			</View>
			{!disabled && (
				<Button
					onPress={() => setCurrentPlan(type)}
					classNameText={cn(
						type === 'Standart' && 'text-gray-300',
						type === 'Plus' && 'text-black',
						type === 'Pro' && 'text-sky'
					)}
					styleTextOnHover='text-white'
					disabled={currentPlan === type}
					variant='button-big'
				>
					{currentPlan === type ? 'Active' : 'Get started'}
				</Button>
			)}
		</View>
	)
}

export default Card
