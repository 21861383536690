import { FC } from 'react'
import { View } from 'react-native'
import { AddTodoList, DetailedSummary, Highlights } from '@/components/ui'
import cn from 'clsx'
import { useMailPosition } from '@/context/MailPositionContext'
import { useColors } from '@/hooks/useColors'
import { TCurrentPath } from '@/shared/store/types'
import { IUpdateTodo } from '@/shared/types/todo.interface'

interface IProps {
    currentPath: TCurrentPath | null
    addTask?: (todo: IUpdateTodo) => void
}

const ScivePowerContainer: FC<IProps> = ({ currentPath, addTask }) => {
    const { bg } = useColors()
    const { position } = useMailPosition()

    return (
        <View
            className={cn(
                'flex-col border-x-8 border-b-8 border-sky-50 z-[-1]',
                bg.white,
                position === 'horizontal' && 'flex-row'
            )}>
            <DetailedSummary
                currentPath={currentPath || 'Inbox'}
                className={cn(
                    ' border-t border-r border-b-0  border-sky ',
                    position === 'vertical' && 'border-r-0'
                )}
            />
            <View className={cn('flex-row overflow-hidden ', position === 'vertical' && ' max-h-[248px]')}>
                <AddTodoList
                    currentPath={currentPath || 'Inbox'}
                    className={cn(
                        ' flex-1 border-t border-r border-sky',
                        position === 'horizontal' && 'max-w-[360px]'
                    )}
                    addTask={addTask}
                />
                <Highlights
                    className='flex-1 border-t border-t-sky w-[248px]'
                    currentPath={currentPath || 'Inbox'}
                />
            </View>
        </View>
    )
}

export default ScivePowerContainer
