import { FC, PropsWithChildren, useEffect } from 'react'
import { Modal, View } from 'react-native'
import { useModal } from '@/context/ModalContext'
import { StyledProps } from 'nativewind'
import cn from 'clsx'

interface TProps {
	visible: boolean
	style?: StyledProps<string>
}

const ModalButtons: FC<PropsWithChildren<TProps>> = ({
	visible,
	style,
	children
}) => {
	const { setVisible } = useModal()

	useEffect(() => {
		setVisible(visible)
	}, [visible])

	return (
		<Modal animationType='fade' transparent={true} visible={visible}>
			<View
				className={cn('flex-1 absolute top-[50%] z-[100001]', style)}
				style={{ transform: [{ translateX: '-50%' }, { translateY: '-50%' }] }}
			>
				{children}
			</View>
		</Modal>
	)
}

export default ModalButtons
