import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { ThreadsService } from '@/services/inbox/threads.api'
import { useStore } from '@/shared/store'
import { useEffect, useRef, useState } from 'react'
import { IThread } from '@/shared/types/threads.interface'

export const useApiGetThreads = () => {
    const [enabled, setEnabled] = useState<boolean>(false)
    const [nextPageToken, setNextPageToken] = useState<string | undefined>(undefined)
    const {
        threads,
        setThreads,
        setRefetchThreads,
        selectedThreadId,
        refetchThreads,
        thread,
        setThread,
        setNextTokenPage,
        loadingNextPage,
        pageSize,
        scollSize,
        chatType,
        setDigestThreads,
        selectedDigestId,
        nextTokenPage,
        setLoadingNextPage
    } = useStore()

    const queryResult = useQuery(
        ['get_threads'],
        () =>
            ThreadsService.getThreads(
                !!nextTokenPage && loadingNextPage ? scollSize : pageSize,
                nextPageToken
            ),
        {
            enabled: false,
            onSuccess(res) {
                if (!res) return
                setNextTokenPage(res.next_page_token)
                
                setRefetchThreads(false)
                chatType === 'chat_dashboard' &&
                    selectedDigestId &&
                    setDigestThreads(selectedDigestId, res.data as IThread[])
                setThreads((res.data as IThread[]) || [])
                selectedThreadId === thread?.id && setThread(selectedThreadId as string)

                setLoadingNextPage(false)
                // setEnabled(false)
            },
            onError() {
                setLoadingNextPage(false)
            },
            onSettled() {
                setLoadingNextPage(false)
            }
        }
    )

    useEffect(() => {
        // setInterval(() => {
        //     fetchThreads()
        // }, 20000)
        refetchThreads && fetchThreads()
    }, [threads, enabled])

    // useEffect(() => {
    //     enabled && setEnabled(false)
    // }, [enabled])

    const fetchThreads = () => {
        queryResult.refetch()
    }
    const fetchMoreThreads = (next_page_token?: string) => {
        if (loadingNextPage) return
        if (!next_page_token) return

        setEnabled(true)
        setLoadingNextPage(true)
        setNextPageToken(next_page_token)
        queryResult.refetch()

        // if (next_page_token) {
        //     if (loadingNextPage) return

        //     setEnabled(true)
        //     setNextPageToken(next_page_token)
        // }
    }

    return {
        threads,
        fetchThreads,
        fetchMoreThreads,
        ...queryResult
    }
}
