import { useQuery } from '@tanstack/react-query'
import { ThreadsService } from '@/services/inbox/threads.api'
import { useStore } from '@/shared/store'
import { useEffect, useState } from 'react'
import { EFilterOptions, EThreadLabels } from '@/shared/store/enum'
import { readAllSaveInStorage, readDigestItemSaveInStorage } from '@/services/app.helper'

export const useApiUpdateThreadLabels = () => {
    const [enabled, setEnabled] = useState<boolean>(false)
    const [readItem, setReadItem] = useState<boolean>(false)
    const {
        updateThreadData,
        setUpdateThreadData,
        updateThread,
        updateSelectedAllThreads,
        updateDropdownSelectedThreads,
        clearThreadLabels,
        markAllDigestsAsRead,
        setAllDigestThreadRead,
        allDigestItemsThreadIds,
        filteredThreads
    } = useStore()

    const queryResult = useQuery(
        ['get_update_thread_labels'],
        () => ThreadsService.getThreadUpdateLabels(updateThreadData),
        {
            enabled,
            onSuccess(res) {
                console.log('get_update_thread_labels', res)
				clearThreadLabels()
    		}
        }
    )

    useEffect(() => {
        if (
            updateThreadData.message_ids.length ||
            updateThreadData.thread_ids.length ||
            updateThreadData.add.length ||
            updateThreadData.remove.length
        ) {
            enabled && setEnabled(false)
        }
    }, [enabled, updateThreadData])

    useEffect(() => {
        if (readItem) {
            handleReadDigestItem(allDigestItemsThreadIds)
            setReadItem(true)
        }
    }, [readItem, allDigestItemsThreadIds])

    const fetchUpdateThreadLabels = (label: EThreadLabels | EFilterOptions, threadId?: string) => {
        threadId && !!EThreadLabels[label as EThreadLabels] && updateThread(threadId, label as EThreadLabels)
        !threadId &&
             !!EThreadLabels[label as EThreadLabels] &&
             updateSelectedAllThreads(label as EThreadLabels)
        !threadId &&
             !!EFilterOptions[label as EFilterOptions] &&
             updateDropdownSelectedThreads(label?.toLowerCase() as EFilterOptions)

        setEnabled(true)
    }
    const readAll = (threadIds: string[]) => {
        setUpdateThreadData({
            message_ids: [],
            thread_ids: [...threadIds],
            add: [],
            remove: [EThreadLabels.UNREAD]
        })
        markAllDigestsAsRead()
        setAllDigestThreadRead(true)
        
        Promise.all([readAllSaveInStorage(true)])
        setEnabled(true)
    }

    const readDigestItem = (digestId: number, threadIds?: string[]) => {
        setReadItem(true)
        Promise.all([readDigestItemSaveInStorage(String(digestId))])
    }
    const handleReadDigestItem = (threadIds: string[]) => {
        setUpdateThreadData({
            message_ids: [],
            thread_ids: [...threadIds],
            add: [],
            remove: [EThreadLabels.UNREAD]
        })

        setEnabled(true)
    }
    // const readDigestItem = (digestId: number, threadIds: string[]) => {
    //     setUpdateThreadData({
    //         message_ids: [],
    //         thread_ids: [...threadIds],
    //         add: [],
    //         remove: [EThreadLabels.UNREAD]
    //     })
    //     // markDigestItemAsRead(digestId)

    //     Promise.all([readDigestItemSaveInStorage(String(digestId))])
    //     // setEnabled(true)
    // }

    return {
        labels: updateThreadData,
        fetchUpdateThreadLabels,
        readDigestItem,
        readAll,
        ...queryResult
    }
}
