import { getLlmAsyncTasksUrl, getLlmUrl, getMessagesUrl } from '@/config/api.config'
import { request } from '../api/request.api'
import { headers } from '../api/headers'
import {
    IDigestHighlightsResponse,
    IDigestResponse,
    IIGenerateDigestResponse,
    ITodoSuggestionsResponse
} from '@/shared/types/dashboard.interface'
import { IComposePrompt } from '@/shared/store/types'

export const DashboardService = {
    async getGenerateDigests() {
        return request<IIGenerateDigestResponse>({
            url: getLlmUrl(`/digests/generate-digest`),
            method: 'POST',
            headers: await headers()
        })
    },
    async getGenerateDigestItem(digest_item_id: number | string | null) {
        if (!digest_item_id) return

        return request<IIGenerateDigestResponse>({
            url: getLlmUrl(`/digests/generate-digest-item-details`),
            method: 'POST',
            data: { digest_item_id },
            headers: await headers()
        })
    },

    async getGenerateComposeSuggestion(prompt: IComposePrompt) {
        return request<IIGenerateDigestResponse>({
            url: getLlmUrl(`/digests/generate-compose-suggestion`),
            method: 'POST',
            headers: await headers(),
            data: { ...prompt }
        })
    },
    async getAsyncTask(async_task_id: number) {
        return request<IIGenerateDigestResponse>({
            url: getLlmAsyncTasksUrl(`/${async_task_id}`),
            method: 'GET',
            headers: await headers()
        })
    },

    async getDigestList() {
        return request<IDigestResponse>({
            url: getLlmUrl(`/digests?page_size=1`),
            method: 'GET',
            headers: await headers()
        })
    },

    async getDigestItems() {
        return request<IDigestResponse>({
            url: getLlmUrl(`/digest-items`),
            method: 'GET',
            headers: await headers()
        })
    },
    async getDigestItem(digest_item_id: number) {
        return request<IDigestResponse>({
            url: getLlmUrl(`/digest-items/${digest_item_id}`),
            method: 'GET',
            headers: await headers()
        })
    },

    async getDigestItemDetails() {
        return request<IDigestResponse>({
            url: getLlmUrl(`/digest-item-details`),
            method: 'GET',
            headers: await headers()
        })
    },
    async getHighlightItem(highlight_id?: string) {
        if (!highlight_id) return
        return request<IDigestResponse>({
            url: getLlmUrl(`/highlights/${highlight_id}`),
            method: 'GET',
            headers: await headers()
        })
    },
    async getHighlights(thread_id?: string) {
        return request<IDigestHighlightsResponse>({
            url: getLlmUrl(`/highlights`),
            method: 'GET',
            headers: await headers(),
            data: { thread_ids: thread_id ? [thread_id] : [] }
        })
    },
    async getTodoSuggestions(thread_id?: string) {
        return request<ITodoSuggestionsResponse>({
            url: getLlmUrl(`/todo-suggestions`),
            method: 'GET',
            headers: await headers(),
            data: { thread_ids: thread_id ? [thread_id] : [], message_ids: [] }
        })
    }
}
