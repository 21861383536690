import { FC } from 'react'
import { View } from 'react-native'
import { ButtonPlain, Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'

interface IProps {
	className?: string
	classNameText?: string
	classNameButton?: string
	text: string
	buttonText: string
	onClick: () => void
}
const Notification: FC<IProps> = ({
    text,
    buttonText,
    onClick,
    className,
    classNameText,
    classNameButton
}) => {
    const { text: textColor, bg } = useColors()
    return (
        <View
            className={cn(
                'pt-base-x1 pb-base-x2 justify-center flex-row items-center mb-2',
                bg.infoBackground,
                className
            )}
            style={{ gap: 63 }}>
            <Typography variant='body' className={cn('p-base-x2', textColor.boldText, classNameText)}>
                {text}
            </Typography>
            <ButtonPlain styleTextOnHover='text-black'  classNameText={classNameButton} onPress={onClick}>
                {buttonText}
            </ButtonPlain>
        </View>
    )
}

export default Notification
