import { getLabelsUrl, getLlmUrl, getMessagesUrl, getThreadsUrl } from "@/config/api.config"
import { request } from "../api/request.api"
import { headers } from "../api/headers"
import { IGetThreadResponse, IUpdateLabelsData, ISendMessage, ISendMessageResponse, ISendGenerateMessage, ISendGenerateMessageResponse } from "@/shared/types/threads.interface"
import { IIGenerateDigestResponse } from "@/shared/types/dashboard.interface"


export const ThreadsService = {
    async getGenerateScivePowerItem(thread_id: string | null) {
        if (!thread_id) return

        return request<IIGenerateDigestResponse>({
            url: getLlmUrl(`/digests/generate-scive-power-item`),
            method: 'POST',
            data: { thread_id },
            headers: await headers()
        })
    },

    async getThreads(page_size: number, page_token?: string) {
        return request<IGetThreadResponse>({
            url: getThreadsUrl(`/?page_size=${page_size}${page_token ? `&page_token=${page_token}` : ''}`),
            method: 'GET',
            headers: await headers()
        })
    },
    async getThread(id: number | string) {
        return request<IGetThreadResponse>({
            url: getThreadsUrl(`/${id}`),
            method: 'GET',
            headers: await headers()
        })
    },
    async getThreadUpdateLabels(data: IUpdateLabelsData) {
        return request<string>({
            url: getLabelsUrl('/update-labels'),
            method: 'PUT',
            headers: await headers(),
            data
        })
    },

    async sendMessage(message: ISendMessage) {
        return request<ISendMessageResponse>({
            url: getMessagesUrl('/send'),
            method: 'POST',
            data: message,
            headers: await headers()
        })
    },

    async sendGenerateMessage(message: ISendGenerateMessage) {
        return request<ISendGenerateMessageResponse>({
            url: getMessagesUrl('/user_prompt'),
            method: 'POST',
            data: message,
            headers: await headers()
        })
    }
}