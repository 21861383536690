import { useMutation } from "@tanstack/react-query"
import { TodosService } from "@/services/togos";
import { useState } from "react";
import { ITodo, IUpdateTodo } from "@/shared/types/todo.interface";
import { useStore } from "@/shared/store";

export const useApiAddTodo = () => {
    const [newTodo, setNewTodo] = useState<IUpdateTodo | null>(null)

    const { addTodo: addTodoStore } = useStore((state) => state)

    const mutationResult = useMutation(['add_todos'], () => TodosService.addTodo(newTodo), {
        onSuccess(res) {
			console.log("ADD_TODO: ", res)
            if(res) return  addTodoStore(res.data as ITodo)
            addTodoStore({} as ITodo)
            return
        }
    })

    const addTodo = async (data: IUpdateTodo) => {
        await setNewTodo(data)
        await mutationResult.mutate()
    }

    return {
        addTodo,
        ...mutationResult
    }
}