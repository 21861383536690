import { FC, useState } from 'react'
import { Image, View } from 'react-native'
import { Button, Dropdown, Typography } from '@/components/ui'
import cn from 'clsx'
import { useMailPosition } from '@/context/MailPositionContext'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { useStore } from '@/shared/store'
import { EThreadLabels } from '@/shared/store/enum'
import { useTextLayoutHandlers } from '@/utils/replaceText'
import { formattedDate } from '@/shared/libs/hooks'
import { useApiUpdateThreadLabels } from '../../../api'
import { IThread } from '@/shared/types/threads.interface'
import TooltipEl from '@/components/ui/tooltip/Tooltip'
import { DataTable } from 'react-native-paper'

interface IProps {
    thread: IThread
}
interface IActionsValue {
    'report spam': EThreadLabels
    'mark as read': EThreadLabels

    delete: string
}

const Header: FC<IProps> = ({ thread }) => {
    const { position } = useMailPosition()
    const { colorScheme } = useColorScheme()
    const { text, color, border, bg } = useColors()
    const [dropdownVisible, setDropdownVisible] = useState(false)

    const [_, setIsTextOverflowed] = useState(false)
    const { handleContainerLayout, handleTextLayout, containerWidth , textWidth} = useTextLayoutHandlers(setIsTextOverflowed)

    const { removeThread, updateThreadLabels } = useStore((state) => state)
    const { fetchUpdateThreadLabels } = useApiUpdateThreadLabels()

    const handleUpdateThread = async (threadId: string, label: string) => {
        await updateThreadLabels(label, threadId)
        await fetchUpdateThreadLabels(label as EThreadLabels, threadId)
    }

    const handleMenuChat = (value: keyof IActionsValue | string) => {
        const actionsValue: IActionsValue = {
            'report spam': EThreadLabels.SPAM,
            'mark as read': EThreadLabels.UNREAD,
            delete: 'delete'
        }
        if (thread) {
            if (actionsValue[value.toLowerCase() as keyof IActionsValue] !== 'delete') {
                handleUpdateThread(thread.id, actionsValue[value.toLowerCase() as keyof IActionsValue])
            } else {
                removeThread(thread.id)
            }
        }
    }

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible)
    }
    const closeDropdown = () => {
        setDropdownVisible(false)
    }

    const message = thread.messages[0].metadata
    const isImportant = thread ? message.labels.includes(EThreadLabels.IMPORTANT) : false
    const subject = thread ? message.subject : ''
    const from_ = thread ? message.from_ : ''
    const created_at = thread ? !message.created_at : ''
    const titleLong = thread.messages[0].metadata.from_
    const titleName = titleLong.replace(/^"(.*?)".*$/, '$1')
    const titleEmail = titleLong.replace(/^.*<([^>]+)>$/, '<$1>')

    const tooltipContent = isImportant
        ? 'Click to teach Scive this conversation is not important'
        : 'Click to teach Scive this conversation is important'
    


    return (
        <View className={cn(' relative z-50')}>
            <DataTable>
                <DataTable.Row  className={cn('flex-row items-center border-b',  border.dividerLight)}>
                    <DataTable.Cell
                        onLayout={handleContainerLayout}
                        // @ts-ignore
                           style={{
                            position: 'relative',
                            overflow: 'hidden',
                             }}>
                        <TooltipEl title={titleName} placement={'top'}>
                            <View className='absolute flex-1 w-full h-full z-10'></View>
                        </TooltipEl>
                        <Typography
                            numberOfLines={1}
                            className={cn('w-full relative')}
                            onLayout={handleTextLayout}
                            // @ts-ignore
                            style={{
                                overflow: 'hidden',
                                // @ts-ignore
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                
                                
                            }}
                            variant={'body'}
                            ellipsizeMode='tail'
                            nowrap={true}>
                            {titleName}
                        </Typography>
                    </DataTable.Cell>
                    <DataTable.Cell
                        onLayout={handleContainerLayout}
                        style={{
                            marginHorizontal: 8,
                            overflow: 'hidden',
                            width: '100%',
                            position: 'relative'
                        }}>
                        <TooltipEl title={titleEmail} placement={'top'}>
                            <View className='absolute flex-1 w-full h-full z-10'></View>
                        </TooltipEl>
                        <Typography
                            numberOfLines={1}
                            className='text-light-text justify-start relative'
                            
                            variant={'body'}
                            onLayout={handleTextLayout}
                            ellipsizeMode='tail'
                            nowrap={true}>
                            {titleEmail}
                        </Typography>
                    </DataTable.Cell>
                    <DataTable.Cell style={{ maxWidth: 16, overflow: 'hidden' }}>
                        <View></View>
                    </DataTable.Cell>
                    <DataTable.Cell style={{ maxWidth: 50 }}>
                        <Typography variant='label-date' className=' text-right flex-1  justify-end'>
                            {formattedDate(thread.messages[thread.messages.length-1].metadata.created_at as string)}
                        </Typography>
                    </DataTable.Cell>
                </DataTable.Row>
            </DataTable>
            {/* second line */}
            <DataTable>
                <DataTable.Row>
                    <DataTable.Cell>
                        <Typography
                            variant='h3'
                            numberOfLines={1}
                            className={cn('w-full', text.boldText)}
                            onLayout={handleTextLayout}
                            // @ts-ignore
                            style={{
                                overflow: 'hidden',
                                width: 'fit-content',
                                marginRight: 8
                            }}
                            ellipsizeMode='tail'
                            nowrap={true}>
                            {subject}
                        </Typography>
                    </DataTable.Cell>
                    <DataTable.Cell style={{ maxWidth: 36, marginRight: 8 }}>
                        <TooltipEl title={tooltipContent} placement='top'>
                            <View className='justify-end'>
                                <Button
                                    className='w-base-x9 h-base-x9 rounded-base-x16 flex justify-center'
                                    styleOnHover={bg.hoverIconSurface}
                                    onPress={() =>
                                        handleUpdateThread(thread.id as string, EThreadLabels.IMPORTANT)
                                    }>
                                    <Image
                                        source={require('@/assets/icons/important.svg')}
                                        tintColor={
                                            isImportant ? '#FDBA74' : colorScheme === 'dark' ? '#374151' : ''
                                        }
                                        className='h-[14px] w-[15px]'
                                    />
                                </Button>
                            </View>
                        </TooltipEl>
                    </DataTable.Cell>
                    <DataTable.Cell style={{ maxWidth: 36 }}>
                        <View className='flex-1 flex-row justify-end '>
                            <Dropdown
                                isVisible={dropdownVisible}
                                setValue={handleMenuChat}
                                onClose={closeDropdown}
                                options={[
                                    { icon: 'Reply', text: 'Forward', reverseIcon: true },
                                    { icon: 'ReplyAll', text: 'Forward all', reverseIcon: true },
                                    { icon: 'AlertOctagon', text: 'Report spam' },
                                    { icon: 'MailOpen', text: 'Mark as read' },
                                    { icon: 'Trash2', text: 'Delete' }
                                ]}
                                button={
                                    <TooltipEl title='More options' placement='top'>
                                        <View>
                                            <Button
                                                onPress={toggleDropdown}
                                                icon='MoreVertical'
                                                className='p-base-x2 rounded-base-x16 relative'
                                                styleOnHover={bg.hoverIconSurface}
                                                colorIcon={color.black}
                                            />
                                        </View>
                                    </TooltipEl>
                                }
                            />
                        </View>
                    </DataTable.Cell>
                </DataTable.Row>
            </DataTable>
        </View>
    )
}

export default Header

//     return (
//         <View className={cn(' relative z-50 border-b', border.dividerLight)}>
//             <View
//                 className={cn('flex-grow border-b pt-base-x2 pb-base-x2 items-center', border.dividerLight)}>
//                 <View
//                     className='flex-row  w-full items-center'
//                     onLayout={handleContainerLayout}
//                     style={{ gap: 8, overflow: 'hidden' }}>
//                     <TooltipEl title={titleName} placement='top'>
//                         <View>
//                             <Typography
//                                 variant={'body'}
//                                 ellipsizeMode='tail'
//                                 nowrap={true}
//                                 className=''
//                                 onLayout={handleContainerLayout}
//                                 style={{ gap: 8, overflow: 'hidden' }}>
//                                 {titleName}
//                             </Typography>
//                         </View>
//                     </TooltipEl>
//                     <TooltipEl title={titleEmail} placement='top'>
//                         <View>
//                             <Typography
//                                 className='text-light-text'
//                                 variant={'body'}
//                                 onLayout={handleContainerLayout}
//                                 ellipsizeMode='tail'
//                                 nowrap={true}
//                                 style={{ gap: 8, overflow: 'hidden' }}>
//                                 {titleEmail}
//                             </Typography>
//                         </View>
//                     </TooltipEl>
//                     <View className='w-[16px] bg-error '></View>
//                     <View className='text-right flex-1  justify-end mr-2'>
//                         <Typography variant='label-date' className=' text-right flex-1  justify-end'>
//                             {formattedDate(thread.messages[0].metadata.created_at as string)}
//                         </Typography>
//                     </View>
//                 </View>

//                 {position === 'horizontal' && (
//                     <View className='pt-base-x2 pb-base-x2 flex-row' style={{ gap: 16 }}>
//                         <Typography variant='button-default' className={text.boldText}>
//                             {from_}
//                         </Typography>
//                         <Typography variant='calout' className='text-light-text'>
//                             {from_}
//                         </Typography>
//                     </View>
//                 )}
//             </View>
//             {/* second line */}

//             <View className='flex-row pt-base-x2 pb-base-x2 items-center' style={{ gap: 8 }}>
//                 <Typography
//                     variant='h3'
//                     numberOfLines={1}
//                     className={cn('w-full', text.boldText)}
//                     onLayout={handleTextLayout}
//                     // @ts-ignore
//                     style={{
//                         overflow: 'hidden',
//                         width: 'fit-content'
//                     }}
//                     ellipsizeMode='tail'
//                     nowrap={true}>
//                     {subject}
//                 </Typography>
//                 <TooltipEl title={tooltipContent} placement='top'>
//                     <View className='mr-6'>
//                         <Button
//                             className='w-base-x9 h-base-x9 rounded-base-x16 flex items-center justify-center'
//                             styleOnHover={bg.hoverIconSurface}
//                             onPress={() => handleUpdateThread(thread.id as string, EThreadLabels.IMPORTANT)}>
//                             <Image
//                                 source={require('@/assets/icons/important.svg')}
//                                 tintColor={isImportant ? '#FDBA74' : colorScheme === 'dark' ? '#374151' : ''}
//                                 className='h-[14px] w-[15px]'
//                             />
//                         </Button>
//                     </View>
//                 </TooltipEl>
//                 <View className='flex-1 flex-row justify-end ml-5' style={{ gap: 16 }}>
//                     <Dropdown
//                         isVisible={dropdownVisible}
//                         setValue={handleMenuChat}
//                         onClose={closeDropdown}
//                         options={[
//                             { icon: 'Reply', text: 'Forward', reverseIcon: true },
//                             { icon: 'ReplyAll', text: 'Forward all', reverseIcon: true },
//                             { icon: 'AlertOctagon', text: 'Report spam' },
//                             { icon: 'MailOpen', text: 'Mark as read' },
//                             { icon: 'Trash2', text: 'Delete' }
//                         ]}
//                         button={
//                             <TooltipEl title='More options' placement='top'>
//                                 <View>
//                                     <Button
//                                         onPress={toggleDropdown}
//                                         icon='MoreVertical'
//                                         className='p-base-x2 rounded-base-x16 relative'
//                                         styleOnHover={bg.hoverIconSurface}
//                                         colorIcon={color.black}
//                                     />
//                                 </View>
//                             </TooltipEl>
//                         }
//                     />
//                 </View>
//             </View>
//         </View>
//     )
// }

// export default Header
