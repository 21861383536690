import { FC, useEffect, useState } from 'react'
import {
    AddTodoList,
    Compose,
    DetailedSummary,
    ExecutiveSummary,
    Highlights,
    Layout,
    Loader
} from '@/components/ui'
import { StyleSheet, View } from 'react-native'
import { MailChat, ModalContainer, Todo } from './components'
import { BlurView } from 'expo-blur'
import cn from 'clsx'
import {
    getAgreeTerms,
    getCourseCompleted,
    readDigestItemGetInStorage,
    saveCourseCompleted
} from '@/services/app.helper'
import { useColorScheme } from 'nativewind'
import { IUpdateTodo } from '@/shared/types/todo.interface'
import { useApiAddTodo } from '../todo/api'
import { useStore } from '@/shared/store'
import { useApiGenerateDigests } from './api'
import { useColors } from '@/hooks/useColors'
import { useApiGetThreads } from '../inbox/api'

const Dashboard: FC = () => {
    useApiGenerateDigests()
    const { fetchThreads } = useApiGetThreads()

    const { bg } = useColors()

    const [stepModal, setStepModal] = useState<number>(0)
    const [courseCompleted, setCourseCompleted] = useState<boolean | null>(null)
    const [agree, setAgree] = useState(false)

    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    const { addTodo } = useApiAddTodo()
    const {
        digestThreads,
        removeTodoSuggestions,
        currentPath,
        setReadDigestItems,
        currentThreadId,
        threads,
    } = useStore()

    useEffect(() => {
        readDigestItemGetInStorage().then((res) => {
            res && setReadDigestItems(res)
        })
    }, [readDigestItemGetInStorage])

    useEffect(() => {
        const fetchData = async () => {
            const completed = await getCourseCompleted()
            setCourseCompleted(completed)
        }

        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            await saveCourseCompleted(true)
            setCourseCompleted(true)
        }

        if (stepModal === 5 && agree) {
            fetchData()
        }
    }, [agree])

    useEffect(() => {
        const fetchDataAgree = async () => {
            const isAgree = await getAgreeTerms()
            setAgree(isAgree)
        }

        fetchDataAgree()
        const fetchData = async () => {
            await saveCourseCompleted(true)
            setCourseCompleted(true)
        }

        if (stepModal === 5 && agree) {
            fetchData()
        }
    }, [stepModal])

    useEffect(() => {
        !threads.length && fetchThreads()
        // currentThreadId && setInterval(() => fetchThreads(), 20000)
    }, [currentThreadId, threads])

    const addTask = (todo: IUpdateTodo) => {
        removeTodoSuggestions(todo.content, 'digest')
        addTodo({ ...todo })
    }

    return (
        <>
            <Layout className='flex-row flex-1 justify-between grid grid-cols-3' style={styles.row}>
                <View
                    className={cn(
                        'border-r-[1px] flex-1 min-w-[460px] desktop:max-w-[640px] grid grid-row-2',
                        stepModal === 1 && 'z-[1000] fixed',
                        deviderColor,
                        bg.inactiveSurface
                    )}
                    style={styles.column}>
                    <ExecutiveSummary className='min-h-[509px] max-h-[601px] h-full' />
                    <DetailedSummary
                        className='h-full min-h-[334px] max-h-[334px] '
                        currentPath={currentPath || 'Dashboard'}
                    />
                </View>
                <View
                    className={cn(
                        'border-x-[1px] flex-1 min-w-[536px] desktop:min-w-[656px]',
                        stepModal === 2 && 'z-[1000] fixed',
                        deviderColor,
                        // loadingAsyncTask && 'p-12'
                    )}
                    style={styles.column}>
                    {/* {!loadingAsyncTask ? (
                        <> */}
                            <MailChat />
                            {!!digestThreads.length && (
                                <Compose className='max-h-[300px]' type='chat_dashboard' />
                            )}
                        {/* </>
                    ) : (
                        <Loader text='Generate threads' />
                    )} */}
                </View>
                <View
                    className={cn(
                        'border-l-[1px] flex-1 min-w-[320px] desktop:max-w-[480px] grid grid-row-3',
                        stepModal === 3 && 'z-[1000] fixed',
                        deviderColor
                    )}>
                    <Todo className='h-full max-h-[481px] min-h-[481px]' />
                    <AddTodoList
                        className='h-full max-h-[120px]'
                        addTask={addTask}
                        currentPath={currentPath || 'Dashboard'}
                    />
                    <Highlights
                        className='min-h-[300x] max-h-[300px]'
                        currentPath={currentPath || 'Dashboard'}
                    />
                </View>
                {courseCompleted === false && (
                    <>
                        <BlurView
                            intensity={40}
                            tint='dark'
                            className='absolute top-0 left-0 right-0 bottom-0 m-[-8px] mb-[-24px]'
                        />
                        <ModalContainer setStep={setStepModal} step={stepModal} />
                    </>
                )}
            </Layout>
        </>
    )
}

const styles = StyleSheet.create({
    column: {
        gap: 8
    },
    row: {
        gap: 4
    }
})

export default Dashboard
