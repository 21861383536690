import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import cn from 'clsx'
import { Button, Icon, Input, InputSearch, Typography } from '@/components/ui'
import { Pressable } from 'react-native-web-hover'
import ReactQuill from 'react-quill'
import { htmlToText } from '@/utils/htmlToText'
import EmojiSelector, { Categories } from 'react-native-emoji-selector'
import './editor/editor.css'
import * as DocumentPicker from 'expo-document-picker'
import TooltipEl from '../../tooltip/Tooltip'

interface TProps {
	visible: Boolean
}

interface TToolbar {
	value: string
	quillRefTemplate: MutableRefObject<any>
}

const Toolbar = ({ quillRefTemplate, value }: TToolbar) => {
	const [visibleEmoji, setVisibleEmoji] = useState(false)
	const [visibleFormatingOptions, setVisibleFormatingOptions] = useState(false)
	const [prevValue, setPrevValue] = useState(htmlToText(value))
	const icons = ReactQuill.Quill.import('ui/icons')

	useEffect(() => {
		setPrevValue(htmlToText(value))
		if (htmlToText(value) !== prevValue) {
			setVisibleFormatingOptions(false)
		}
	}, [value])

	useEffect(() => {
		if (visibleFormatingOptions) setVisibleEmoji(false)
	}, [visibleFormatingOptions])

	useEffect(() => {
		if (visibleEmoji) setVisibleFormatingOptions(false)
	}, [visibleEmoji])

	const pickFile = async () => {
		const result = await DocumentPicker.getDocumentAsync({
			type: '*/*'
		})

		if (result.assets) {
			const file = new FormData()
			file.append('file', result.assets)

			// отправить file на бэк
		}
	}

	icons[
		'bold'
	] = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none"><path d="M10 10C11.0609 10 12.0783 9.57857 12.8284 8.82843C13.5786 8.07828 14 7.06087 14 6C14 4.93913 13.5786 3.92172 12.8284 3.17157C12.0783 2.42143 11.0609 2 10 2H2V10M2 10H11C12.0609 10 13.0783 10.4214 13.8284 11.1716C14.5786 11.9217 15 12.9391 15 14C15 15.0609 14.5786 16.0783 13.8284 16.8284C13.0783 17.5786 12.0609 18 11 18H2V10Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>`
	icons[
		'italic'
	] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M19 4H10M14 20H5M15 4L9 20" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`
	icons[
		'underline'
	] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M6 4V10C6 11.5913 6.63214 13.1174 7.75736 14.2426C8.88258 15.3679 10.4087 16 12 16C13.5913 16 15.1174 15.3679 16.2426 14.2426C17.3679 13.1174 18 11.5913 18 10V4M4 20H20" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`

	return (
		<View id='toolbar-template'>
			<button
				onClick={() => setVisibleFormatingOptions(!visibleFormatingOptions)}
			>
				<Icon name='SpellCheck2' size={24} />
			</button>
			<View
				className={cn(
					'absolute bottom-[40px] flex-row left-0 bg-white rounded-base-x2 z-50 p-base-x1 shadow',
					!visibleFormatingOptions && 'hidden'
				)}
				style={{ gap: 8 }}
			>
				<select className='ql-size' defaultValue='normal'>
					<option value='small'>Small</option>
					<option value='normal'>Normal</option>
					<option value='large'>Large</option>
					<option value='huge'>Huge</option>
				</select>
				<button className='ql-bold'></button>
				<button className='ql-italic'></button>
				<button className='ql-underline'></button>
			</View>
			<button onClick={() => setVisibleEmoji(!visibleEmoji)}>
				<Icon name='SmilePlus' size={24} />
			</button>
			<button onClick={pickFile}>
				<Icon name='Paperclip' size={24} />
			</button>
			<button>
				<Icon name='GraduationCap' size={24} />
			</button>
			<View
				className={cn(
					'absolute bottom-[40px] left-[40px] w-[232px] h-[280px] bg-white overflow-hidden rounded-base-x2 p-base-x2 pt-base-x3 z-50 shadow-md',
					!visibleEmoji && 'hidden'
				)}
			>
				<EmojiSelector
					category={Categories.symbols}
					onEmojiSelected={emoji => {
						if (quillRefTemplate.current) {
							const cursorPosition = quillRefTemplate.current
								.getEditor()
								.getLength()
							quillRefTemplate.current
								.getEditor()
								.insertText(cursorPosition, emoji)
							quillRefTemplate.current
								.getEditor()
								.setSelection(cursorPosition + 1)
						}
					}}
				/>
			</View>
		</View>
	)
}

const ModalTemplate: FC<TProps> = ({ visible }) => {
	const [templates, setTemplates] = useState([
		{
			id: 1,
			name: 'На спам',
			selected: false,
			text: '',
			subject: ['example@mail.com']
		},
		{
			id: 2,
			name: 'Директору',
			selected: false,
			text: '',
			subject: ['example@mail.com', 'example@mail.com']
		},
		{
			id: 3,
			name: 'Личные',
			selected: false,
			text: '',
			subject: ['example@mail.com']
		}
	])
	const [valueSearch, setValueSearch] = useState('')
	const [valueSubject, setValueSubject] = useState('')
	const [selectedSubjects, setSelectedSubjects] = useState([
		{
			email: 'example@mail.com'
		},
		{
			email: 'example@mail.com'
		},
		{
			email: 'example@mail.com'
		}
	])
	const [valueTitle, setValueTitle] = useState('')
	const [activeTemplate, setActiveTemplate] = useState(0)
	const [valueText, setValueText] = useState('')
	const quillRefTemplate = useRef<ReactQuill | null>(null)

	useEffect(() => {
		scrollToolbarIntoView()
	}, [valueText])

	const scrollToolbarIntoView = () => {
		if (quillRefTemplate.current) {
			const quillEditor = quillRefTemplate.current?.getEditor()
			quillEditor?.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const handleSelectTemplate = (templateId: number) => {
		setTemplates(prevTemplates =>
			prevTemplates.map(template =>
				template.id === templateId
					? { ...template, selected: !template.selected }
					: template
			)
		)
	}

	const filteredTemplates: any[] = templates.filter(template =>
		template.name.toLowerCase().includes(valueSearch.toLowerCase())
	)

	const addTemplate = () => {}

	return (
		<View
			className={cn(
				'absolute bottom-[40px] left-[125px] w-[400px] h-[420px] justify-between flex-col bg-white overflow-hidden rounded-base-x4 p-base-x2 pt-base-x4 pb-base-x4 z-50 shadow',
				!visible && 'hidden'
			)}
			style={{ gap: 16 }}
		>
			{!activeTemplate ? (
				<>
					<View className='flex-col' style={{ gap: 16 }}>
						{templates.length > 0 && (
							<InputSearch
								placeholder='Search by your tasks'
								onChange={value => setValueSearch(value)}
								value={valueSearch}
							/>
						)}
						<Button
							className='p-base-x2'
							colorIcon='sky'
							styleColorIconOnHover='sky-500'
							variant='h4'
							onPress={addTemplate}
							icon='PlusCircle'
						>
							New template
						</Button>
						{filteredTemplates.length > 0 && (
							<View className='flex-1'>
								<Typography
									variant='subheadline'
									className='text-gray-500 pl-base-x2'
								>
									My templates
								</Typography>
								{filteredTemplates?.map((template, index) => {
									// const [isEdit, setIsEdit] = useState(false)
									let isEdit = false
									return (
                                        <Pressable
                                            key={index}
                                            // @ts-ignore
                                            style={{ transition: 'all 0.3s ease-out' }}
                                            onPress={() => setActiveTemplate(template.id)}>
                                            {({ hovered }) => (
                                                <View
                                                    className={cn(
                                                        'p-base-x2 flex-row items-center justify-between rounded-base-x2',
                                                        hovered && 'bg-gray-100'
                                                    )}
                                                    style={{ gap: 8 }}>
                                                    <View
                                                        className='flex-row items-center'
                                                        style={{ gap: 8 }}>
                                                        <Icon
                                                            name={isEdit ? 'PenLine' : 'FileText'}
                                                            size={24}
                                                            color={
                                                                isEdit || template.selected ? 'sky' : 'black'
                                                            }
                                                        />
                                                        <Typography variant='h4'>{template.name}</Typography>
                                                    </View>
                                                    <View
                                                        className='flex-row items-center'
                                                        style={{ gap: 8 }}>
                                                        <TooltipEl placement='top' title='More options'>
                                                            <View>
                                                                <Button
                                                                    className='p-base-x2'
                                                                    icon='MoreVertical'
                                                                    colorIcon='black'
                                                                />
                                                            </View>
                                                        </TooltipEl>
                                                        <Button
                                                            className='p-base-x2'
                                                            icon={
                                                                template.selected ? 'CheckCircle2' : 'Circle'
                                                            }
                                                            colorIcon={template.selected ? 'sky' : 'black'}
                                                            onPress={() => handleSelectTemplate(template.id)}
                                                        />
                                                    </View>
                                                </View>
                                            )}
                                        </Pressable>
                                    )
								})}
							</View>
						)}
					</View>
					<Button
						className='p-base-x2 ml-auto'
						icon='SendHorizontal'
						colorIcon='black'
					/>
				</>
			) : (
				<View className='flex-col justify-between' style={{ gap: 8 }}>
					<Input
						placeholder='Title...'
						onChangeText={setValueTitle}
						value={valueTitle}
						className={cn(
							'text-gray-300 bg-white text-base-h4 font-semibold h-[36px] w-full outline-none p-base-x2 pl-base-x4 pr-base-x4',
							valueTitle && 'text-gray-900'
						)}
					/>
					<View
						className='min-h-[36px] p-base-x2 bg-gray-100 rounded-base-x4'
						style={{ gap: 8 }}
					>
						<View
							className='justify-between flex-row items-center'
							style={{ gap: 8 }}
						>
							<Typography variant='subheadline' className='text-gray-300'>
								Subject:
							</Typography>
							<Input
								value={valueSubject}
								onChangeText={setValueSubject}
								className={cn(
									'text-gray-300 bg-none text-base-body2 w-full outline-none pt-[2px] pb-[2px] pl-base-x2 pr-base-x2 min-w-[140px]',
									valueSubject && 'text-gray-900'
								)}
							/>
							<View className='flex-row'>
								<Button variant='caption' className='pl-base-x2 pr-base-x2'>
									Cc
								</Button>
								<Button variant='caption' className='pl-base-x2 pr-base-x2'>
									Bcc
								</Button>
							</View>
						</View>
						{selectedSubjects.length > 0 && (
							<View className='flex-row flex-wrap' style={{ gap: 1 }}>
								{selectedSubjects.map((subject, index) => (
									<View
										key={index}
										className='rounded-base-x16 flex-row items-center bg-white pl-base-x2'
										style={{ gap: 8, width: 'fit-content' }}
									>
										<Typography variant='caption'>{subject.email}</Typography>
										<Button
											icon='X'
											sizeIcon={16}
											className='w-base-x6 h-base-x6 flex items-center justify-center'
										/>
									</View>
								))}
							</View>
						)}
					</View>
					<View className='flex-col flex-1 min-h-full' style={{ gap: 16 }}>
						<ReactQuill
							ref={quillRefTemplate}
							value={valueText}
							style={{ paddingLeft: 8, paddingRight: 8 }}
							onChange={setValueText}
							modules={{
								toolbar: '#toolbar-template'
							}}
							formats={['underline', 'size', 'bold', 'italic']}
							placeholder='Make your own template...'
						/>
						<View className='justify-between flex-row items-center'>
							<Toolbar quillRefTemplate={quillRefTemplate} value={valueText} />
							<View className='flex-row' style={{ gap: 8 }}>
								<Button
									className='p-base-x2'
									icon='Save'
									sizeIcon={32}
									onPress={addTemplate}
								/>
								<Button
									className='p-base-x2'
									icon='SendHorizontal'
									sizeIcon={32}
								/>
							</View>
						</View>
					</View>
				</View>
			)}
		</View>
	)
}

export default ModalTemplate
