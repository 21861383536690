import { FC } from 'react'
import { View } from 'react-native'
import { Body, Header, Loader, Typography } from '@/components/ui'
import TodoList from './TodoList'
import { StyledProps, useColorScheme } from 'nativewind'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { IUpdateTodo } from '@/shared/types/todo.interface'
import { useStore } from '@/shared/store'
import { TCurrentPath } from '@/shared/store/types'

interface IProps {
    currentPath: TCurrentPath
    className?: StyledProps<string>
    addTask?: (todo: IUpdateTodo) => void
}
const AddTodoList: FC<IProps> = ({ className, addTask, currentPath }) => {
    const { todoSuggestions, loadingAsyncTask, threadTodoSuggestions } = useStore()
    const { text } = useColors()

    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'
    const existTodos = currentPath !== null
        ? currentPath === 'Dashboard'
            ? !todoSuggestions
            : currentPath === 'Inbox' && !threadTodoSuggestions
        : true
    const todos = currentPath === 'Dashboard' ? todoSuggestions : currentPath === 'Inbox' ? threadTodoSuggestions : null

    return (
        <View
            className={cn(
                'pb-base-x3 pl-base-x1 justify-between',
                currentPath === 'Inbox' && 'pt-base-x5 pb-base-x2 pl-base-x4',
                className
            )}>
            {currentPath === 'Inbox' && <Header title='Add to To-Do list' subtitle='' />}
            <Body
                className={cn(
                    'pl-base-x2 pr-base-x2 pb-0',
                    currentPath === 'Inbox' && 'pl-base-x4 mt-base-x6 mb-base-x4'
                )}
                marginTop={false}>
                {!loadingAsyncTask ? (
                    existTodos ? (
                        <Typography variant='body' className={cn(text.inactiveText)}>
                            Here you can add important things from executive summary to your tasks
                        </Typography>
                    ) : (
                        <TodoList todos={todos} addTask={addTask} />
                    )
                ) : (
                    <Loader text='Generate todo suggestions' />
                )}
            </Body>
        </View>
    )
}

export default AddTodoList
