import { FC, useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import Header from './Header'
import TodoItem from '../common/TodoItem'
import { Button, Loader, Typography } from '@/components/ui'
import Filters from './Filters'
import { TSection } from '../../todo.interface'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'
import { useApiGetTodos, useRemoveTodo, useUpdateTodo } from '../../api'
import { useStore } from '@/shared/store'
import { ETodoDropdownFilters, ITodo } from '@/shared/types/todo.interface'
import TodoList from '../common/TodoList'
import AddTaskToDo from '@/components/screens/dashboard/components/AddTask'

interface IProps {}
const TodoBody: FC<IProps> = ({}) => {

    const [mouseClick, setMouseClick] = useState<boolean>(false)
    const [selectedTodo, setSelectedTodo] = useState<boolean>(false)
    // const [selectedTodo, setSelectedTodo] = useState<ITodo | null>(null)
    const [showAddTaskModal, setShowAddTaskModal] = useState(false)
    const { bg, text } = useColors()

    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'
    const { setAddTodoFormType } = useStore((state) => state)

    const { todosTab, isFetching } = useApiGetTodos()

    const {
        todo: selected,
        getTodo,
        sectionsTodosTab,
        filteredTodosTabCurrent,
        foundTodosTab,
        searchValueTab,
        setSelectedTodos,
        selectedTodos
    } = useStore()

    const { updateTodo } = useUpdateTodo()
    const { removeTodo } = useRemoveTodo()

    const updateTask = (id: number, todo: ITodo) => {
        updateTodo(id, { ...todo })
    }

    const toggleVisibleList = () => {
        setShowAddTaskModal(!showAddTaskModal)
        setAddTodoFormType('task')
    }

    const handleKeyPress = (event: any) => {
        
    }

    useEffect(() => {
        selectedTodo && setSelectedTodo(false)
    }, [selectedTodo])

    // useEffect(() => {
    //     const handleKeyDown = (event: any) => {
    //         const { ctrlKey, metaKey, key } = event.nativeEvent
    //         if (key === 'Escape') {
    //             setSelectedTodos(null)
    //             setSelectedTodo(false)
    //         }

    //         if (ctrlKey || metaKey) {
    //             setSelectedTodo(true)
    //         }
    //     }
    //     const handleKeyoUp = (event: any) => {
    //             setSelectedTodo(false)
    //     }
    //     // document.addEventListener('keydown', handleKeyDown)
    //     // document.addEventListener('keyup', handleKeyoUp)

    //     // console.log('selectedTodo', selectedTodo)

    //     return () => {
    //         document.removeEventListener('keydown', handleKeyDown)
    //         document.removeEventListener('keyup', handleKeyoUp)
    //     }
    // }, [selectedTodo, mouseClick, selectedTodos])

    const sections = [
        {
            title: 'Tomorrow',
            content: (
                <TodoList
                    todos={sectionsTodosTab.tomorrow}
                    setSelectedTodo={setSelectedTodos}
                    setMouseClick={setMouseClick}
                />
            )
        },
        {
            title: 'Today',
            content: (
                <TodoList
                    todos={sectionsTodosTab.today}
                    setSelectedTodo={setSelectedTodos}
                    setMouseClick={setMouseClick}
                />
            )
        }
    ]

    return (
        <View
            className={cn('flex-1 px-base-x2 pt-base-x3 pb-base-x6flex-col border-x-[1px]', deviderColor)}
            style={{ gap: 8 }}>
            <Header />
            {filteredTodosTabCurrent !== 'completed' && (
                <View>
                    <Button
                        style={{
                            //@ts-ignore
                            transition: 'all 0.3s ease-out'
                        }}
                        className={`w-full h-base-x12 mr-4 rounded-t-lg rounded-b-lg border-transparent py-4 px-2 ${
                            showAddTaskModal ? `${bg.hoverIconSurface} rounded-b-none` : ''
                        }`}
                        styleOnHover={`${bg.hoverIconSurface} ${showAddTaskModal ? bg.hoverIconSurface : ''}`}
                        classNameText={text.black}
                        colorIcon='sky'
                        variant='subheadline'
                        onPress={toggleVisibleList}
                        icon='PlusCircle'>
                        Add a task
                    </Button>
                    <AddTaskToDo
                        className='-mt-[25px]'
                        type='task'
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                    />
                </View>
            )}
            <ScrollView contentInsetAdjustmentBehavior='automatic' className='flex-1 pr-base-x2'>
                <View className='flex-col' style={{ gap: 8 }}>
                    {!isFetching ? (
                        !foundTodosTab.length && !searchValueTab.length ? (
                            todosTab.length ? (
                                todosTab.map((todo, idx) => {
                                    const existItem = !!selectedTodos.find((item) => item.id === todo.id)
                                    const selectItem = existItem || selected.id === todo.id
                                    return (
                                        <TodoItem
                                            key={idx}
                                            todo={todo}
                                            selected={selectItem}
                                            removeTask={() => removeTodo(todo.id as number)}
                                            updateTask={updateTask}
                                            onPress={() => {
                                                // console.log('selectedTodo', selectedTodo)
                                                getTodo(todo.id as number),
                                                // setMouseClick(true),
                                                setSelectedTodo(false)
                                                selectedTodo && setSelectedTodos(todo)
                                            }}
                                        />
                                    )
                                })
                            ) : (
                                <Typography variant='body'>You have no any todos.</Typography>
                            )
                        ) : (
                            foundTodosTab.map((todo, idx) => {
                                const existItem = !!selectedTodos.find((item) => item.id === todo.id)
                                const selectItem = existItem || selected.id === todo.id
                                return (
                                    <TodoItem
                                        key={idx}
                                        todo={todo}
                                        selected={selectItem}
                                        removeTask={() => removeTodo(todo.id as number)}
                                        updateTask={updateTask}
                                        onPress={() => (
                                            getTodo(todo.id as number),
                                            selectedTodo && setSelectedTodos(todo),
                                            setMouseClick(true)

                                        )}
                                    />
                                )
                            })
                        )
                    ) : (
                        <Loader text='Loading tasks' />
                    )}
                </View>
                {filteredTodosTabCurrent === 'all' &&
                (sectionsTodosTab.tomorrow || sectionsTodosTab.today) ? (
                    <Filters sections={sections as TSection[]} />
                ) : null}
            </ScrollView>
        </View>
    )
}

export default TodoBody
