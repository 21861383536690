import { FC } from 'react'
import { useWindowDimensions, View } from 'react-native'
import { IMessageBubble } from './messages.interface'
import { Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { formattedDate } from '@/shared/libs/hooks'
import RenderHTML from 'react-native-render-html'

const MessageBubble: FC<IMessageBubble> = ({ message, showAvatar, showDate, iAmSender }) => {
    const { bg, text: textColor } = useColors()
    const { metadata, html, plain } = message
    const { width } = useWindowDimensions() 

    return (
        <View className={cn('flex-col items-start', iAmSender && 'ml-auto flex-col')} style={{ gap: 8 }}>
            {showAvatar && (
                <View
                    className={cn(
                        'rounded-base-x16 bg-gray-400 items-center justify-center w-[44px] h-[44px]',
                        iAmSender && 'ml-auto'
                    )}>
                    <Typography variant='h2' className='text-white'>
                        A
                    </Typography>
                </View>
            )}
            <View className={cn('flex-col overflow-hidden max-w-[724px]', iAmSender && 'items-end')}>
                <Typography
                    variant='body'
                    className={cn(
                        'p-base-x2 rounded-base-x4',
                        textColor.boldText,
                        iAmSender ? bg.outcomingMessage : bg.inactiveSurface
                    )}>
                    {html && (
                        <RenderHTML
                            source={{ html: html! }}
                            contentWidth={width}
                            ignoredDomTags={['meta', 'link']}
                            aria-label='HTML content'
                        />
                    )}
                    {!html && plain && plain}
                    {/* {!(!html && plain && plain) && metadata.snippet} */}
                </Typography>

                {showDate && (
                    <Typography
                        variant='body'
                        className={cn('pl-base-x2 pr-base-x2', textColor.disabledText)}>
                        {metadata.created_at && formattedDate(metadata.created_at)}
                    </Typography>
                )}
            </View>
        </View>
    )
}

export default MessageBubble
