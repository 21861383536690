import { FC, ReactNode, useEffect, useState } from 'react'
import { Image, StyleProp, View, ViewStyle } from 'react-native'
import { Button, Typography } from '@/components/ui'
import { StyledProps } from 'nativewind'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useTypedNavigation } from '@/hooks/useTypedNavigation'
import { IDigest } from '@/shared/types/dashboard.interface'
import { TSummaryState } from '@/shared/store/types'
import { useStore } from '@/shared/store'
import { useSizeSelector } from '@/context/SizeSelectorContext'
import { Pressable } from 'react-native'
import { useApiGetThread, useApiUpdateThreadLabels } from '@/components/screens/inbox/api'

interface IProps {
    body?: IDigest[] | string
    placeholder?: string
    children?: ReactNode
    className?: StyledProps<string>
    style?: StyleProp<ViewStyle>
    status?: TSummaryState
    selectMessage?: (msgId: number) => void
    marginTop?: boolean | string
}

const Body: FC<IProps> = ({
    body,
    placeholder,
    children,
    className,
    style,
    status,
    selectMessage,
    marginTop = true
}) => {
    const { readDigestItem } = useApiUpdateThreadLabels()
    const { fetchFirstDigestThread } = useApiGetThread()
    
    
    
    const [isHoveredId, setIsHoveredId] = useState<string | null>(null)
    const { navigate } = useTypedNavigation()
    const { bg, text: textColor } = useColors()
    const limitedText = 'Oops, the number of detailed report generation is over. Need more?'

    const { size } = useSizeSelector()

    const {
        setChatType,
        selectedDigestId,
        readDigestItemIds,
        allDigestThreadIds,
        readDigestItems,
        setReadDigestItemIds,
        markDigestItemAsRead,
        setResetCurrentThreadCount,
    } = useStore()


    const selecteDigestItem = (id: number) => {
        if (selectMessage) {
            selectMessage(id)
            setChatType('chat_dashboard')
            fetchFirstDigestThread(id)
            setReadDigestItemIds(id)
            setResetCurrentThreadCount()
        }
    }

    return (
        <View
            className={cn(
                'flex-1 pl-base-x6 pr-base-x2 pb-base-x4 mr-base-x2',
                className,
                (children || body?.length) && className,
                marginTop ? 'mt-base-x4' : typeof marginTop === 'string' ? marginTop : ''
            )}
            //@ts-ignore
            style={[{ overflowY: 'auto' }, style]}>
            {children}
            {status === 'default' && !body?.length && (
                <View
                    //@ts-ignore
                    style={{ userSelect: 'none' }}>
                    <Typography variant='body' className={textColor.inactiveText}>
                        {placeholder}
                    </Typography>
                </View>
            )}
            {status === 'generated' && !!body?.length && typeof body !== 'string'
                ? body.map((item, idx) => {
                      let part1 = body.slice(0, Math.floor(body.length / 3))
                      let part2 = body.slice(Math.floor(body.length / 3), Math.floor((2 * body.length) / 3))
                      let part3 = body.slice(Math.floor((2 * body.length) / 3))

                      const isReadDigest = readDigestItemIds.includes(item.id) && selectedDigestId !== item.id
                      const isActiveDigest = item.id === selectedDigestId
                      const isHoverDigest = isHoveredId === String(item.id)
                      const isHoverReadDigest = isReadDigest && isHoveredId === String(item.id)

                      if (readDigestItems.includes(String(item.id))) return

                      return (
                          <View
                              key={idx}
                              className='flex flex-row relative overflow-hidden'
                              // @ts-ignore
                              style={{ width: 'fit-content' }}>
                              <Typography
                                  variant='body'
                                  key={item.id}
                                  //@ts-ignore
                                  onMouseLeave={() => setIsHoveredId(null)}
                                  onMouseEnter={() => setIsHoveredId(String(item.id))}
                                  onPress={() => selecteDigestItem(item.id)}
                                  className={cn(
                                      'bg-white rounded-lg px-2 py-1 mb-2 font-semibold relative overflow-hidden',
                                      isHoverDigest && `bg-white ${textColor.inactiveText}`,
                                      isReadDigest && 'bg-gray-200 text-gray-400 font-normal',
                                      isHoverReadDigest && 'bg-gray-200 text-gray-400 font-semibold',
                                      isActiveDigest && 'bg-black text-white',

                                      part3.includes(item) &&
                                          `text-base-body2 leading-5 ${
                                              size === 'large'
                                                  ? 'desktop:text-base-body'
                                                  : 'desktop:text-base-body2'
                                          }`,
                                      part2.includes(item) &&
                                          `text-base-body-digest leading-7 ${
                                              size === 'large'
                                                  ? 'desktop:text-base-body-digest1'
                                                  : 'desktop:text-base-body-digest'
                                          }`,
                                      part1.includes(item) &&
                                          `text-base-body-digest1 leading-[30px] ${
                                              size === 'large'
                                                  ? 'desktop:text-base-h3'
                                                  : 'desktop:text-base-body-digest1'
                                          }`
                                  )}>
                                  {' '}
                                  {item.content}
                                  {isHoverDigest && (
                                      <Pressable
                                          onPress={() => (
                                              readDigestItem(item.id, allDigestThreadIds),
                                              markDigestItemAsRead(item.id)
                                          )}
                                          //   onPress={() => (readDigestItem(item.id, allDigestThreadIds), markDigestItemAsRead(digestId))}
                                          className={cn(
                                              'bg-sky w-[32px] h-full absolute left-0 top-0 flex justify-center items-center'
                                          )}>
                                          <Image
                                              source={require('@/assets/icons/check.svg')}
                                              tintColor={'#fff'}
                                              className='z-20 h-[28px] w-[28px]'
                                          />
                                      </Pressable>
                                  )}
                              </Typography>
                          </View>
                      )
                  })
                : typeof body === 'string' && <Typography variant='body'>{body}</Typography>}
            {status === 'limited' && (
                <Typography
                    variant='body'
                    className={cn('text-base-body', textColor.boldText, textColor.inactiveText)}>
                    {limitedText}
                    <Button
                        variant='body'
                        className={cn(
                            'pl-2 underline underline-offset-4',
                            textColor.boldText,
                            textColor.black
                        )}
                        onPress={() =>
                            navigate('Settings', {
                                path: 'plan'
                            })
                        }>
                        Check out our plans.
                    </Button>
                </Typography>
            )}
        </View>
    )
}

export default Body
