import { FC, useState } from 'react'
import { Text, View } from 'react-native'
import { TPlans } from '../../../settings.interface'
import {
	ButtonPlain,
	Checkbox,
	Icon,
	Input,
	Modal,
	Typography
} from '@/components/ui'
import cn from 'clsx'
import Toast from 'react-native-toast-message'
import { useTypedNavigation } from '@/hooks/useTypedNavigation'


const CurrentPlanInfo: FC = () => {
	const [currentPlan, setCurrentPlan] = useState<TPlans>('Standart')
	const table = []
	const { navigate } = useTypedNavigation()
	const [stepModal, setStepModal] = useState(0)
	const [valueTemplate, setValueTemplate] = useState('')
	const [checkboxesSurvey, setCheckboxesSurvey] = useState({
		technicalIssues: false,
		cancelingTemporarily: false,
		tooExpensive: false,
		dashboardTooComplex: false
	})

	const onPress = () => {
		if (currentPlan === 'Standart') {
			if (table.length === 0) {
				Toast.show({
					type: 'info',
					text1: 'Add a payment method'
				})
			} else {
				navigate('Settings', {
					path: 'plan'
				})
			}
		} else {
			setStepModal(1)
		}
	}

	const cancelSubsription = () => {
		setStepModal(0)
	}
	return (
        <>
            <View
                className={cn(
                    'p-base-x4 flex-col rounded-base-x4 h-[200px] items-center',
                    currentPlan === 'Standart' && 'bg-gray-50',
                    currentPlan === 'Pro' && 'bg-black',
                    currentPlan === 'Plus' && 'bg-sky'
                )}
                style={{ gap: 8 }}>
                <Typography
                    variant='body'
                    className={cn(
                        'text-gray-400 pt-base-x2 pb-base-x2',
                        currentPlan === 'Pro' && 'text-gray-500',
                        currentPlan === 'Plus' && 'text-black'
                    )}>
                    Your current plan
                </Typography>
                <View className='flex-row items-center pt-base-x2 pb-base-x2 ' style={{ gap: 8 }}>
                    {currentPlan === 'Pro' ? <Icon name='Gem' size={24} color='white' /> : null}
                    <Typography
                        variant='h3'
                        className={cn(
                            currentPlan === 'Pro' && 'text-white',
                            currentPlan === 'Standart' && 'text-gray-500',
                            currentPlan === 'Plus' && 'text-black'
                        )}>
                        {currentPlan}
                    </Typography>
                </View>
                <Typography
                    variant='caption'
                    className={cn('text-gray-500 flex-1', currentPlan === 'Plus' && 'text-black')}>
                    {currentPlan === 'Standart' ? null : 'Renews on Oct.2023'}
                </Typography>
                <ButtonPlain
                    styleTextOnHover='text-sky-500'
                    className={cn(
                        'border border-sky w-[168px] h-[32px] rounded-base-x2',
                        currentPlan === 'Plus' && 'border-black'
                    )}
                    classNameText={cn('text-sky ', currentPlan === 'Plus' && 'text-black')}
                    onPress={() => onPress()}>
                    {currentPlan === 'Standart' ? 'Upgrade' : 'Cancel subsription'}
                </ButtonPlain>
            </View>
            <Modal
                title='Do you really want to cancel your current subscription?'
                buttonReset='Never mind'
                buttonSubmit='Yes, cancel'
                onClickButtonReset={() => setStepModal(0)}
                onClickButtonSubmit={() => setStepModal(2)}
                visible={stepModal === 1}
                onClose={() => setStepModal(0)}>
                <Typography variant='body' className='p-base-x2'>
                    With Scive power it’s easier to handle with all your incoming emails
                </Typography>
            </Modal>
            <Modal
                title='Sorry to see you go'
                buttonReset='Never mind'
                buttonSubmit='Submit'
                onClickButtonReset={() => setStepModal(0)}
                onClickButtonSubmit={() => setStepModal(3)}
                visible={stepModal === 2}
                onClose={() => setStepModal(0)}>
                <View>
                    <Typography variant='body' className='p-base-x2'>
                        Please be honest about why you are canceling your current plan. It’s the only way we
                        can improve.
                    </Typography>
                    <View className='pl-base-x4 pt-base-x4 flex-col' style={{ gap: 16 }}>
                        <View className='flex-col' style={{ gap: 16 }}>
                            <Checkbox
                                text='Dashboard is too complex'
                                checked={checkboxesSurvey.dashboardTooComplex}
                                onChange={(value) =>
                                    setCheckboxesSurvey({
                                        ...checkboxesSurvey,
                                        dashboardTooComplex: value
                                    })
                                }
                                form={'circle'}
                            />
                            <Checkbox
                                text='Too expensive for my needs'
                                checked={checkboxesSurvey.tooExpensive}
                                onChange={(value) =>
                                    setCheckboxesSurvey({
                                        ...checkboxesSurvey,
                                        tooExpensive: value
                                    })
                                }
                                form={'circle'}
                            />
                            <Checkbox
                                text='I’m canceling temporarily'
                                checked={checkboxesSurvey.cancelingTemporarily}
                                onChange={(value) =>
                                    setCheckboxesSurvey({
                                        ...checkboxesSurvey,
                                        cancelingTemporarily: value
                                    })
                                }
                                form={'circle'}
                            />
                            <Checkbox
                                text='I’m having technical issues'
                                checked={checkboxesSurvey.technicalIssues}
                                onChange={(value) =>
                                    setCheckboxesSurvey({
                                        ...checkboxesSurvey,
                                        technicalIssues: value
                                    })
                                }
                                form={'circle'}
                            />
                        </View>
                        <Input
                            placeholder='Let me explain to you...'
                            onChangeText={(value) => setValueTemplate(value)}
                            value={valueTemplate}
                            className={cn(
                                'text-gray-300 bg-white text-base-body2 h-[40px] w-full outline-none pr-base-x2',
                                valueTemplate && 'text-gray-900'
                            )}
                        />
                    </View>
                </View>
            </Modal>
            <Modal
                title='We are scheduled to cancel your subscription'
                buttonSubmit='Got it'
                onClickButtonSubmit={cancelSubsription}
                visible={stepModal === 3}
                onClose={() => setStepModal(0)}>
                <Typography variant='caption' className='p-base-x2 text-center text-gray-500 h-[120px]'>
                    Your subscription will be canceled at end of your billing period on{' '}
                    <Text className='text-black text-[13px]'>Dec 17, 2024</Text>
                    <View className='flex mb-base-x2' />
                    You can always renew your subscription. We are waiting for you back!
                </Typography>
            </Modal>
        </>
    )
}

export default CurrentPlanInfo
