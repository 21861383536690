import { FC, PropsWithChildren, ReactElement } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { Box } from '@mui/system'
import { makeStyles } from '@mui/styles'

interface ITooltip {
    title: any
    children: ReactElement
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
}

const useStyles = makeStyles({
    customTooltip: {
        backgroundColor: '#1F2937 !important',
        color: '#fff !important',
        fontSize: 14,
        borderRadius: '8px',
        padding: '4px 8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
    }
})

const TooltipEl: FC<PropsWithChildren<ITooltip>> = ({ title, children, placement }) => {
    const classes = useStyles()
    return (
        <Tooltip title={title} placement={placement} classes={{ tooltip: classes.customTooltip }}>
            <Box>{children}</Box>
        </Tooltip>
    )
}

export default TooltipEl
