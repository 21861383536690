import { View, Pressable, Image } from 'react-native'
import { Dispatch, FC } from 'react'
import Icon from '../icon/Icon'
import { useColorScheme } from 'nativewind'
import { useColors } from '@/hooks/useColors'
import { EThreadLabels } from '@/shared/store/enum'
import { useStore } from '@/shared/store'
import TooltipEl from '@/components/ui/tooltip/Tooltip'
import { TThreadsCategories } from '@/shared/store/types'
import { CircleCheckBig } from 'lucide-react'

interface IProps {
    threadId: string
    visibleScive: Dispatch<React.SetStateAction<boolean>>
    isVisible: boolean
    removeThread: () => void
    updateThread: (threadId: string, label: string) => void
    category: TThreadsCategories
    isNew: boolean
    isFirstTread?: boolean
    fetchThreadDetails?: () => void
    setOpenThreadId: () => void
}

const RowActions: FC<IProps> = ({
    threadId,
    isNew,
    visibleScive,
    isVisible,
    removeThread,
    updateThread,
    fetchThreadDetails,
    setOpenThreadId
}) => {
    const { colorScheme } = useColorScheme()
    const { color } = useColors()
    const { filteredThreadsTabCurrent, filteredThreads } = useStore()

    const unreadBase = filteredThreads.filter(
        (thread) => thread.id === threadId && thread.messages[0].metadata.labels.includes('UNREAD')
    )
    const mailCheck = unreadBase.length ? 'Mark as done' : 'Marks as undone'

    return (
        <View className='right-0 pr-base-x1 z-20 flex-row items-center' style={{ gap: 8 }}>
            <Pressable onPress={() => updateThread(threadId, EThreadLabels.UNREAD)}>
                {!isNew ? (
                    <TooltipEl title={mailCheck} placement={'top'}>
                        <View>
                            <Icon
                                name={!!unreadBase.length ? 'Mail' : 'MailOpen'}
                                size={20}
                                color={color.black}
                                className='p-base-x1'
                            />
                        </View>
                    </TooltipEl>
                ) : (
                    <TooltipEl title='Mark as done' placement={'top'}>
                        <View>
                            <CircleCheckBig size={20} stroke='black' />
                        </View>
                    </TooltipEl>
                )}
            </Pressable>
            <Pressable
                className='p-base-x1'
                onPress={() => (visibleScive(!isVisible), setOpenThreadId(), fetchThreadDetails?.())}>
                <TooltipEl placement='top' title='Scive power'>
                    <View>
                        <Image
                            source={require(`@/assets/icons/scive-${colorScheme}.svg`)}
                            className='h-base-x5 w-base-x5'
                        />
                    </View>
                </TooltipEl>
            </Pressable>

            {!isNew && (
                <>
                    <Pressable onPress={() => updateThread(threadId, EThreadLabels.SPAM)}>
                        <TooltipEl placement='top' title='Report spam'>
                            <View>
                                <Icon name={'XOctagon'} size={20} color={color.black} className='p-base-x1' />
                            </View>
                        </TooltipEl>
                    </Pressable>
                    <Pressable
                        onPress={
                            filteredThreadsTabCurrent === 'trash' || filteredThreadsTabCurrent === 'spam'
                                ? removeThread
                                : () => updateThread(threadId, EThreadLabels.TRASH)
                        }>
                        <TooltipEl placement='top' title='Delete'>
                            <View>
                                <Icon name={'Trash2'} size={20} color={color.black} className='p-base-x1' />
                            </View>
                        </TooltipEl>
                    </Pressable>
                </>
            )}
        </View>
    )
}

export default RowActions
